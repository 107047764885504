var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reset-password remember"},[_c('b-row',{staticClass:"text-center border-rounded",attrs:{"align-v":"center","align-h":"center"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"h5 font-weight-bolder"},[_vm._v("Redefinição de senha")])]),_c('b-col',{staticClass:"px-md-5",attrs:{"md":"6","sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Dígite sua nova senha *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control form-control-lg",class:{'border border-danger' : _vm.error.password != 0 },attrs:{"type":"password","id":"password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.error.password)+" ")]),_c('div',{},[_vm._v(" "+_vm._s(this.strengthPassword)+" "),_c('div',{staticClass:"d-flex flex-row bd-highlight my-1"},[_c('span',{staticClass:"progress_password mr-2 rounded-pill bd-highlight",class:{
                    'bg-danger' : _vm.passwordStrength.score <= 1,
                    'bg-warning' : _vm.passwordStrength.score == 2,
                    'bg-info' : _vm.passwordStrength.score == 3,
                    'bg-success' : _vm.passwordStrength.score == 4
                    }}),_c('span',{staticClass:"progress_password mr-2 rounded-pill bd-highlight",class:{
                    'bg-secondary' : _vm.passwordStrength.score <= 1,
                    'bg-warning' : _vm.passwordStrength.score == 2,
                    'bg-info' : _vm.passwordStrength.score == 3,
                    'bg-success' : _vm.passwordStrength.score == 4
                    }}),_c('span',{staticClass:"progress_password mr-2 rounded-pill bd-highlight",class:{
                    'bg-secondary' : _vm.passwordStrength.score <= 2,
                    'bg-info' : _vm.passwordStrength.score == 3,
                    'bg-success' : _vm.passwordStrength.score == 4
                    }}),_c('span',{staticClass:"progress_password rounded-pill bd-highlight",class:{
                    'bg-secondary' : _vm.passwordStrength.score <= 3,
                    'bg-success' : _vm.passwordStrength.score == 4
                    }})])])]),_c('div',[_c('label',{attrs:{"for":"password_confirm"}},[_vm._v("Confirmar a senha*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password_confirmation),expression:"form.password_confirmation"}],staticClass:"form-control form-control-lg",class:{'border border-danger' : _vm.error.password_confirmation != 0 },attrs:{"type":"password","id":"password_confirm"},domProps:{"value":(_vm.form.password_confirmation)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password_confirmation", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.error.password_confirmation)+" ")])])]),_c('b-col',{attrs:{"cols":"12 mt-1","align-self":"end"}},[_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.show,"opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{attrs:{"bg-mprimary":"","disabled":_vm.disabled},on:{"click":_vm.SendReset}},[_vm._v(" ENVIAR "),_c('svg',{staticClass:"bi bi-arrow-right ml-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"2rem","height":"2rem","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"}})])])],1)],1),_c('b-col',{attrs:{"cols":"12 mt-3"}},[_c('a',{attrs:{"href":"http://localhost:8081/auth"}},[_vm._v(" Cancelar ")])]),_c('b-col',{attrs:{"cols":"12 border-top"}},[_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("@/assets/img/Logotipo..png"),"alt":"Logotipo","width":"140"}})])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import {baseApiUrl,showError, userKey, notification, baseUrl} from '@/global'
import chatGroup from './modules/chatGroup'
import callRoom from './modules/callRoom'
import ChatSupport from './modules/ChatSupport'
import availability from './modules/availability'
import cookies from './modules/cookies'
import profile from './modules/Purchase/profile'
// import cookies from './modules/freelancer'
/**
 * DICA REFLACTOR MESSAGE 
 * cria um modules para lida com isso e depois só chama-lo
 */
//import notifications from './modules/notifications'
//import Audio from '../assets/notification.mp3'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isMenuVisible: false,
        isSupportVisible:false,
        user: null,
        freelancer: null,
        candidate:null,
        // profile: null,
        chatUser:null,
        //fazer mutations
        chat:[],
        //notificações
        items:[],
        //payment
        infoCard : {
            pay:'',
            image:'',
            token:'',
        },
        chatSupport:[],
        userAdm:null,
        idTicket:'Aguardando atendimento',
        onlineItems:[],
        controleRouter:'',
        systemService:[],
    },
    mutations: {
        //route
        setControleRouter(state,router){
            state.controleRouter = router
        },
        //payment
        setPay(state,pay){
            state.infoCard.pay = pay
        },
        setCardImage(state,image){
            state.infoCard.image = image
        },
        setCardToken(state,token){
            state.infoCard.token = token
        },
        // setando mensagens
        setChat(state, chat){
            state.chat = chat
        },
        // pegando usuários online
        setChatUser(state, chatUser){
            state.chatUser = chatUser
            state.onlineItems = state.chatUser.map(i=>i.name)
           
        },
        setUserAdm(state, userAdm){
            state.userAdm = userAdm
        },
        // pegando saida de usarios do chat
        JoiningUser(state, user){
            state.chatUser.push(user)
        },
        JoiningOnline(state,user){
            state.onlineItems.push(user.name)
        },
        JoiningUserAdm(state, userAdm){
            state.userAdm.push(userAdm)
        },
        leavingUser(state, user){
            state.chatUser = state.chatUser.filter(u => {
                return u.id !== user.id
            })
        },
        leavingUserOnline(state,user){
            state.onlineItems = state.onlineItems.filter(u=>{
                return u !== user
            })
        },
        leavingUserAdm(state, userAdm){
            state.userAdm = state.userAdm.filter(u => {
                return u.id !== userAdm.id
            })
        },
        //Adicionando mensagem recem envia ao chat
        addMessage(state, chat){
            state.chat.push(chat)
        },
        toggleMenu(state, isVisible) {
            if(!state.user) {
                state.isMenuVisible = false
                return
            }

            if(isVisible === undefined) {
                state.isMenuVisible = !state.isMenuVisible
            } else {
                state.isMenuVisible = isVisible
            }
        },
        setUser(state, user){
            state.user = user
            if(user){
                axios.defaults.headers.common['Authorization'] = `bearer ${user.token}`
                state.isMenuVisible = true
            } else {
                delete axios.defaults.headers.common['Authorization']
                state.isMenuVisible = false
            }
        
        
        },
        // Freelancer
        setFree(state, freelancer){
            state.freelancer = freelancer
        },
        updateFreeAvailability(state, payload){
            state.freelancer.free_availability = payload
        },
        // Freelancer
        setCandidate(state, candidate){
            state.candidate = candidate
        },
        // setProfile(state, profile){
        //     state.profile = profile
        // },
        setNotifications(state, notifications){
            let audio = new Audio(notification)
            audio.play()
            state.items = notifications
        },
        markRead(state, id){
            let index = state.items.filter(notication => notication.id == id)
            state.items.splice(index, 1)
        },
        markAllRead(state){
            console.log(state.items)
            state.items = []
        },
        addNotification(state, notification){
            let audio = new Audio(notification)
            audio.play()
            state.items.unshift(notification)
        },
        // pegando chat suporte
        setChatSuport(state, chatSupport){
            state.chatSupport = chatSupport
        },
        //adicionar mensage ticket 
        addSupportMessage(state,chatSupport){
            state.chatSupport.push(chatSupport)
        },
        // Visible do suporte leavingUser
        setVisible(state, isSupportVisible){
            state.isSupportVisible = isSupportVisible
        },
        limpaSupportMessage(state){
            state.chatSupport.splice(0,state.chatSupport.length)
        },
        //atualizando id tickets
        updateTicket(state, idTicket){
            state.idTicket = idTicket
        },
        setSystemServices(state, services){
            state.systemService = services
        }
    },
    getters:{
        usetTypeFree(state){
            if(state.user && state.freelancer)
                return state.user.type == 'freelancers'
            return false
        },
        avatarView(state){
            if(state.user && state.freelancer){
                return state.user.type == 'freelancers' ? state.freelancer.avatar : state.candidate.avatar
            }
            return 'img/default-avatar.jpg'
        }
    },
    actions: {
        // Não esta sendo utilizado
        storeMessage(context,id, params){
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            let headers = {
                "Accept": "application/x-www-form-urlencoded"
            }
            return axios.post(`${baseApiUrl}/chat/${id}`, params, headers)
            .then(res => console.log(res.data.chat))
            .catch(()=> console.log('error'))
        },
        getSystemServices(context){
            const url = `${baseApiUrl}/services`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(json =>{
                if(!json.error){
                    context.commit('setSystemServices', json.data.services)
                }
            }).catch(error=>showError(error))
        },
        loadNotifications(context){
            const url = `${baseApiUrl}/user/notification`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(json =>{
                if(json.error != ""){
                    if(json.data.notifications){
                        //this.notification = json.data.notifications
                        context.commit('setNotifications', json.data.notifications)
                    }
                }
            })
        },
        maskAsRead(context,params){
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            const url = `${baseApiUrl}/user/notification-read`
            let headers = {
                "Accept": "application/x-www-form-urlencoded"
            }
            axios.put(url, params).then(()=> context.commit('markRead', params.id))           
        },
        markAllAsRead(context){
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            const url = `${baseApiUrl}/user/notification-all-read`
            axios.put(url).then(() => context.commit('markAllRead'))
        },
        //Pegando tickets ligado ao usuario Admin
        getSupportChat(context){
            const url = `${baseApiUrl}/tickets-user`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res =>{
                if(!res.data.error){
                    context.commit('setChatSuport', res.data)
                    context.commit('setVisible', true)
                }
            })
        },
        // Reload apos terminor de reunião setCall
        finallyTickets(context){
            context.commit('setVisible', false)
            context.commit('limpaSupportMessage')
            context.commit('updateTicket', 'Aguardando atendimento')
            setInterval(()=>{window.location.href = `${baseUrl}`}, 9000)          
        },
        // Atualizando disponibilidade do usuário
        updateAvailability(context, params){
            let newParams = jsonOriginal.map((i) => {
                if(i.id == updateJson.id){
                    return updateJson;
                }; 
                return i
                
            })
        }

    },
    modules: {
        chatGroup,
        callRoom,
        ChatSupport,
        availability,
        cookies,
        profile
    }
})
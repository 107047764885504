<template>
    <div>
        <div v-if="this.purchases != ''">
            <transition name="slide" type="animation">
                <b-table class="mt-3" show  hover striped bordered :items="purchases" :fields="fields" :busy="isBusy">
                    <template #cell(action)="row">
                        <!--
                        <router-link :to="{path: `/purchases/PaymentOne/${row.item.reference}`, params: {reference: row.item.reference}}">
                           <i class="fa fa-eye"></i> ver
                        </router-link>
                        <b-nav-item :to="{path:'/purchases/completed'}" active-class="active" variant="success">
                            <i class="fa fa-eye"></i>
                        </b-nav-item> 
                        -->
                        <a variant="danger" :href=row.item.payment_link class="mr-2" target="_blank">
                            <i class="fa fa-eye"></i>
                        </a>
                    </template>
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                        </div>
                    </template>
                </b-table>
            </transition>
        </div>
        <div v-else class="notset text-center">
            <NotSet 
                frase="Você não tem nenhuma compra em aberto."
            />
        </div>
    </div>
</template>
<script>
import NotSet from '../containers/NotSet'
import Shedule from '../containers/Shedule'
import {baseApiUrl,showError, userKey} from '@/global'
import {mapState} from 'vuex'
import axios from 'axios'
export default {
    name:'PaymentOpen',
    components:{Shedule, NotSet},
    data (){
        return{
            isBusy:false,
            purchases:null,
            loading:false,
            fields:[
                { key: 'reference', label: 'Ident.', sortable:true},
                { key: 'status', label: 'Status', 
                    formatter: value =>{
                        let statusA = [
                            'Aguardando pagamento',
                            'Em análise',
                            'Paga',
                            'Disponível',
                            'Em disputa',
                            'Devolvida',
                            'Cancelada',
                            'Debitado',
                            'Retenção temporária',
                        ]
                        return statusA[value-1]
                    },
                sortable:true},
                { key: 'payment_methods', label: 'Forma de pagamento', 
                    formatter: value =>{
                        let statusA = [
                            'Cartão de crédito',
                            'Boleto',
                            'Débito online (TEF)',
                            'Saldo PagSeguro',
                            'Oi Paggo',
                            'Depósito em conta',
                        ]
                        return statusA[value-1]
                    },
                sortable:true},
                { key: 'created_at', 
                    label: 'Criado em',
                    formatter: value =>{
                        let getdata = new Date(value)
                        let setday = (getdata.getDate()) < 10 ? "0"+(getdata.getDate()) : (getdata.getDate())
                        let setMonth = ((getdata.getMonth() + 1))< 10 ? "0"+((getdata.getMonth() + 1)) : ((getdata.getMonth() + 1)) 
                        let data = ( setday + "/" + setMonth + "/" + getdata.getFullYear() )
                        return data
                    },
                   
                    sortable:true,
                },
                { key: 'action', label: 'Ações'}        
            ]
        }
    },
    methods: {
        get(){
            this.isBusy = true
            const url =  `${baseApiUrl}/order`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res =>{
                if(res.data){
                    this.purchases = res.data
                    this.loading = true
                }
            }).catch(showError).finally(()=>this.isBusy = false)
        }
    },
    created() {
        this.get()
    }
}
</script>
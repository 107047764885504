<template>
    <div class="avaliation">
        <div class="text-center">
            <h1 class="h4 font-weight-bold">Avalie o {{freelancer}}</h1>
        </div>
        <b-row  class="border-radius mt-3">
            <b-col md="6" sm="12">
                <label>Nota</label>
                <b-form-rating
                    :disabled="this.loading === true"
                    no-border
                    v-model=dataTestimonials.rate
                    :color="dataTestimonials.rate > 3 ? '#ffd700' : '#000'"
                    :class="{
                        'nulo': dataTestimonials.rate == null,
                        'too-bad' : dataTestimonials.rate == 1,
                        'bad' : dataTestimonials.rate == 2,
                        'good' : dataTestimonials.rate == 3,
                        'excellent' : dataTestimonials.rate == 4,
                        'above-average' : dataTestimonials.rate == 5
                        }"
                ></b-form-rating>
                <span class="text-muted" v-if="dataTestimonials.rate === null">De uma nota para o atendimento</span>
                <span style="color:#ff0000" v-if="dataTestimonials.rate === 1">Muito ruim</span>
                <span style="color:#000" v-if="dataTestimonials.rate === 2">Ruim</span>
                <span cstyle="color:#00ff0094" v-if="dataTestimonials.rate === 3">Bom</span>
                <span style="color:#ffd900" v-if="dataTestimonials.rate === 4">Otimo</span>
                <span style="color:#4855bb" v-if="dataTestimonials.rate === 5">Acima da media !!!</span>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="4" sm="12">
                <div class="form-group">
                    <label for="contrast">Destaque</label>
                    <input  :disabled="this.loading === true" type="text" class="form-control" id="contrast" aria-describedby="contrastFree" v-model=dataTestimonials.contrast>
                    <small id="contrastFree" class="form-text text-muted">Coloca uma caracterista forte que {{freelancer}} teve na prestação do serviço, ex.: Antencioso, boa didatica, etc..</small>
                </div>
            </b-col>
            <b-col>
                <b-form-group
                    label="Conte mais sobre sua experiência."
                    label-for="textarea-no-resize"
                >
                    <b-form-textarea
                        :disabled="this.loading === true"
                        id="textarea-no-resize"
                        rows="3"
                        v-model=dataTestimonials.body
                    ></b-form-textarea>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="mb-3" >
            <b-col cols="12" class="text-right"> 
                <button class="btn btn-sm px-4 text-light font-weight-bolder" @click.prevent="sendTestimonials" bg-mprimary :disabled="this.loading === true">
                    ENVIAR
                    <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>
                </button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import {userKey, baseApiUrl, showError} from '@/global'
import axios from 'axios'
export default {
    name:'Avaliation',
    props:['shedules', 'freelancer'],
    data(){
        return{
            loading:false,
            dataTestimonials:{
                id_shedules : this.shedules,
                rate: 3
            }
        }
        //Ótima didática !!!
    },methods: {
        sendTestimonials(){
            this.loading = true
            const url = `${baseApiUrl}/evaluation`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.post(url, this.dataTestimonials).then(json =>{
                if(json.data.success){
                    this.$toasted.success(json.data.success, {
                        theme: "toasted-primary",
                        position: "top-center", 
                        duration : 5000,
                        icon:'check_circle'
                    })
                    this.$router.go()
                }else{
                    showError(json.data.error)
                }
            }).finally(this.loading = false)
        }
    },
}
</script>
<style>
.avaliation label{
    color:#000 !important;
    font-weight: 800;
    font-size: 1.15rem;
}
.nulo{
    background-color:rgb(36, 36, 36)
}
.too-bad{
    background-color:#ff000057
}
.bad{
    background-color:#0000008c
}
.good{
    background-color:#00ff0094
}
.excellent{
    background-color:#fff
}
.above-average{
    background-color:#4855bb88
}
</style>
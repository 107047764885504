import {baseApiUrl,showError, userKey} from '@/global'
import {mapState} from 'vuex'
import axios from 'axios'
export default {
    computed:mapState(['user', 'freelancer']),
    methods:{
        overlay(){
            this.show = true
            let self = this
            setTimeout(function(){
                self.show = false
            }, 2000)
        },
        editProfile(){
            const url = `${baseApiUrl}/freelancer/${this.user.id}`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.put(url, this.freelancer).then(json =>{
                if(json.data.error != ""){
                   showError(json.data.error)
                }else{
                  this.$toasted.global.defaultSuccess()
                  this.editAbout = false
                  this.inputName = false
                }
                console.log(json.data)
            }).catch(showError)
        }
    }
}
export default{
    environment(status){
        const env = {
            production:{
                ENVIRONMENT:"production",
                USER_KEY     : 'user_local_de_producao',
                PROFILE_PURCHASE: '_use__profile_producao',
                BASE_API_URL : 'https://carreirasapi.bucardcode.com.br/api',
                BASE_SOCKET  : 'https://carreirasapi.bucardcode.com.br/',
                BASE_URL     : 'https://carreirassys.bucardcode.com.br/',
                BASE_SITE    : 'teste',
                VERSION      : '1.0'
            },
            development:{
                ENVIRONMENT:"development",
                USER_KEY     : '__knowleadge_user_dev',
                PROFILE_PURCHASE: '__carreiras_profile',
                BASE_API_URL  : 'http://localhost:8089/api',
                BASE_SOCKET  : 'http://localhost:8089',
                BASE_URL     : 'http://localhost:8081/',
                BASE_SITE    : 'http://localhost:8082',
                VERSION      : '1.0'
            },
        }
        return env[status];
    }
}
<template>
    <div class="password">
        <b-overlay :show="show" rounded="sm">
        <h3 class="font-weight-bolder h5 mb-3">Altera Senha</h3>
        <div v-if="mode === 'start'">
            <div class="row text-center">
                <div class="col">
                    <p>Enviaremos um email com o codigo para alteração.</p>
                    <p class="text-muted"> Clique no botão a baixo para confirma !</p>
                    <b-button @click.prevent="auth" bg-mprimary>Enviar</b-button>
                </div>
            </div>
        </div>
        <div v-if="mode === 'code'">
            <div class="row text-center">
                <div class="col">
                    <p>Digite o codigo que enviamos para você !</p>
                </div>
            </div>
            <b-row align-h="center">
                <b-col cols="6">
                    <input v-model="authCode.code" type="text" class="form-control" id="code">
                    <p class="text-muted"> o codigo contem 5 digitos</p>
                </b-col>
            </b-row>
            <b-row align-h="center">
                <b-col>
                    <b-button @click.prevent="proceed" bg-mprimary class="mt-3">Enviar</b-button>
                </b-col>
            </b-row>
        </div>
        <div class="" v-if="mode === 'view'">
            <div class="form-group">
                <label for="password">Nova senha</label>
                <input type="password" v-model="shift.password" class="form-control" id="password" :class="{'border border-danger' : error != 0 }">
                <span class="text-danger" v-if="error != 0 ">
                    {{error}}
                </span>
            </div>
            <div>
                {{strengthPassword}}
                <div class="d-flex flex-row bd-highlight my-1" >
                    <span class="progress_password mr-2 rounded-pill bd-highlight" v-bind:class="{
                    'bg-danger' : passwordStrength.score <= 1,
                    'bg-warning' : passwordStrength.score == 2,
                    'bg-info' : passwordStrength.score == 3,
                    'bg-success' : passwordStrength.score == 4
                    }"></span>
                    <span class="progress_password mr-2 rounded-pill  bd-highlight" v-bind:class="{
                    'bg-secondary' : passwordStrength.score <= 1,
                    'bg-warning' : passwordStrength.score == 2,
                    'bg-info' : passwordStrength.score == 3,
                    'bg-success' : passwordStrength.score == 4
                    }"></span>
                    <span class="progress_password mr-2 rounded-pill bd-highlight" v-bind:class="{
                    'bg-secondary' : passwordStrength.score <= 2,
                    'bg-info' : passwordStrength.score == 3,
                    'bg-success' : passwordStrength.score == 4
                    }"></span>
                    <span class="progress_password rounded-pill bd-highlight" v-bind:class="{
                    'bg-secondary' : passwordStrength.score <= 3,
                    'bg-success' : passwordStrength.score == 4
                    }"></span>
                </div>
            </div>
            <div class="form-group">
                <label for="password_confirm">Repita nova senha </label>
                <input type="password" v-model="shift.password_confirm" class="form-control" id="password_confirm" :class="{'border border-danger' : error != 0 }">
                <span class="text-danger" v-if="error != 0 ">
                    {{error}}
                </span>
            </div>
            <b-button @click.prevent="setSwap" bg-mprimary>Continuar -></b-button>
        </div>
        <div v-if="loading === true" class="d-flex justify-content-center mb-3">
            <b-spinner label="Loading..."></b-spinner>
            <strong>Loading...</strong>
        </div>
        <div v-if="mode === 'congratulation'">
            Parabéns senha alterada com sucesso 
        </div>
        </b-overlay>
    </div>
</template>
<script>
const zxcvbn = require('zxcvbn')
import axios from "axios"
import { baseApiUrl,showError, userKey } from "@/global"
import {mapState} from 'vuex'
export default {
    name:'Password',
    computed:{
        passwordStrength(){
            return zxcvbn(this.shift.password)
        },
        strength(){
            this.passwordStrength.score == 0 ? this.strengthPassword = '' : this.strengthPassword = ''
            if(this.passwordStrength.score == 0){
                return this.strengthPassword = ''
            }
            if(this.passwordStrength.score == 1){
                return this.strengthPassword = 'Senha não aceitavél'
            }
            if(this.passwordStrength.score == 2){
                return this.strengthPassword = 'Senha não aceitavél, quase lá'
            }
            if(this.passwordStrength.score == 3){
                return this.strengthPassword = 'Senha aceitavél, mas você pode melhora'
            }
            if(this.passwordStrength.score == 4){
                return this.strengthPassword = 'Senha ótima !'
            }
        }
    },
    data(){
        return{
            error:0,
            strengthPassword: '',
            loading:false,
            show: false,
            authCode:{
                code: ''
            },
            mode: 'start',
            shift:{
                password:'',
                password_confirm:''
            }
        }
    },
    methods: {
        passwordConfirm(pass, passconfirm){
            return pass === passconfirm ? true : false
        },
        overlay(){
            this.show = true
            let self = this
            setTimeout(function(){
                self.show = false
            }, 2000)
        },
        validateRegisterOne(){
            this.overlay()
            var error = 0;
            //erro de password
            if(this.shift.password.length < 6){
                showError("Senha tem que conter mais de 6 digitos")
                this.error = "Senha tem que conter mais de 6 digitos"
                error++
            }else if(this.shift.password === undefined){
                showError("Preencha o campo o campo de senha")
                this.error = "Preencha o campo de senha"
                error++
            }else{
                this.error = ""
            }
            //erro de confirmação de password
            if(this.shift.password_confirm === undefined){
                this.error = "Preencha o campo de senha corretamente"
                error++
            }else{
                this.error = ""
            }
            if(this.passwordConfirm(this.shift.password, this.shift.password_confirm)){
                this.error = ""
            }else{
                showError("Senhas não conferir")
                this.error = "Senha não conferir"
                error++
            }
            return (error === 0)            
        },
        auth(){
            this.overlay()
            this.loading = true
            this.mode = ''
            const url = `${baseApiUrl}/user/auth`
            const autentification = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${autentification.token}`
            axios.put(url).then(json=>{
                if(json.data.error != ""){
                    showError(json.data.error)
                    
                }else{
                    this.$toasted.global.defaultSuccess(json.data.success)
                    this.mode = 'code'
                    this.loading = false
                }           
            }).finally(this.loading = false)
        },
        proceed(){
            this.overlay()
            this.loading = true
            this.mode = ''
            const url = `${baseApiUrl}/user/auth/code`
            const autentification = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${autentification.token}`
            axios.put(url, this.authCode).then(json=>{
                if(json.data.error != ""){
                    showError(json.data.error)
                    this.mode = 'code'
                }else{
                    this.$toasted.global.defaultSuccess(json.data.success)
                    this.mode = 'view'
                    this.loading = false
                }
            }).finally(this.loading = false)
        },
        setSwap(){
            if(this.validateRegisterOne()){
                this.loading = true
                this.mode = ''
                const url = `${baseApiUrl}/user`
                const autentification = JSON.parse(localStorage.getItem(userKey))
                axios.defaults.headers.common['Authorization'] = `bearer ${autentification.token}`
                axios.put(url, this.shift).then(json=>{
                    if(json.data.error != ""){
                        showError(json.data.error)
                        this.mode = 'view'
                    }else{
                        this.$toasted.global.defaultSuccess(json.data.success)
                        this.mode = 'congratulation'
                        this.loading = false
                    }
                             
                }).finally(this.loading = false)
            }
        }
    },
}
</script>
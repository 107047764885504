<template>
    <div class="payment mb-4">
        <b-container>
            <b-row>
                <b-col md="6" class="border rounded p-4 mt-4 bg-white">
                    <b class="h5 font-weight-bolder ml-2">Pagamentos</b>
                    <apexchart type="donut" width="380" :options="chartOptions" :series="series"></apexchart>
                </b-col>
                <b-col md="6">
                    <div class="border rounded p-4 mt-4 bg-white">
                        <b class="h5 font-weight-bolder">Valores a receber</b>
                        <p class="text-muted my-4" v-if="viewToReceive == false" @click="viewToReceive = !viewToReceive">
                           <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                width="2.5rem" height="2.5rem" viewBox="0 0 200.000000 200.000000"
                                preserveAspectRatio="xMidYMid meet">
                                <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                                fill="#000000" stroke="none">
                                <path d="M870 1468 c-91 -12 -244 -67 -333 -117 -102 -59 -219 -161 -297 -260
                                -36 -46 -65 -86 -65 -91 0 -5 29 -45 65 -91 213 -270 543 -417 857 -380 186
                                22 366 101 515 226 80 68 208 218 208 245 0 27 -128 177 -208 245 -214 180
                                -481 260 -742 223z m235 -138 c101 -15 222 -60 312 -116 79 -49 233 -190 233
                                -214 0 -21 -142 -155 -215 -202 -144 -94 -269 -131 -435 -131 -166 0 -291 37
                                -435 131 -73 47 -215 181 -215 202 0 7 35 47 78 89 191 189 435 275 677 241z"/>
                                <path d="M905 1283 c-211 -76 -272 -336 -115 -493 61 -61 119 -85 210 -85 91
                                0 149 24 210 85 61 61 85 119 85 210 0 91 -24 149 -85 210 -80 80 -203 109
                                -305 73z m185 -153 c35 -25 70 -90 70 -130 0 -14 -9 -44 -20 -67 -48 -106
                                -195 -120 -268 -25 -38 50 -39 133 0 184 54 71 149 87 218 38z"/>
                                </g>
                            </svg>
                            Mostrar
                        </p>
                        <b-row v-else>
                            <b-col>
                                <p class="font-weight-bolder my-4 h4">R$ {{toreceiver}}</p>
                            </b-col>
                            <b-col @click="viewToReceive = !viewToReceive">
                                <svg version="1.0" fill="grey" class="" xmlns="http://www.w3.org/2000/svg" width="2.5rem" height="2.5rem" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                    fill="#000000" stroke="none">
                                    <path d="M2377 2742 l-1807 -1807 183 -183 182 -182 1808 1808 1807 1807 -183
                                    183 -182 182 -1808 -1808z"/>
                                    <path d="M2360 4089 c-236 -16 -516 -75 -746 -157 -644 -231 -1178 -683 -1513
                                    -1280 l-52 -93 52 -92 c168 -299 430 -611 679 -810 l85 -67 352 352 353 353
                                    -15 78 c-21 109 -19 281 4 392 98 476 514 815 1001 815 60 0 143 -7 187 -15
                                    l78 -15 222 222 c123 123 223 226 223 229 0 8 -171 45 -290 64 -206 31 -402
                                    39 -620 24z"/>
                                    <path d="M3902 3177 l-352 -352 15 -78 c20 -103 19 -278 -1 -375 -45 -218
                                    -130 -379 -284 -533 -155 -155 -336 -249 -554 -288 -86 -16 -261 -13 -353 4
                                    l-78 15 -222 -222 c-123 -123 -223 -226 -223 -229 0 -3 44 -16 97 -28 745
                                    -167 1534 -22 2164 398 185 123 281 202 440 360 196 196 353 404 473 624 l47
                                    86 -22 42 c-154 303 -472 681 -750 893 l-46 34 -351 -351z"/>
                                    </g>
                                </svg>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="border rounded p-4 mt-4 bg-white">
                        <b class="h5 font-weight-bolder">Valores recebidos</b>
                        <p class="text-muted my-4" v-if="viewReceiver == false" @click="viewReceiver = !viewReceiver">
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="2.5rem" height="2.5rem" viewBox="0 0 200.000000 200.000000"
                            preserveAspectRatio="xMidYMid meet">
                            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                            fill="#000000" stroke="none">
                            <path d="M870 1468 c-91 -12 -244 -67 -333 -117 -102 -59 -219 -161 -297 -260
                            -36 -46 -65 -86 -65 -91 0 -5 29 -45 65 -91 213 -270 543 -417 857 -380 186
                            22 366 101 515 226 80 68 208 218 208 245 0 27 -128 177 -208 245 -214 180
                            -481 260 -742 223z m235 -138 c101 -15 222 -60 312 -116 79 -49 233 -190 233
                            -214 0 -21 -142 -155 -215 -202 -144 -94 -269 -131 -435 -131 -166 0 -291 37
                            -435 131 -73 47 -215 181 -215 202 0 7 35 47 78 89 191 189 435 275 677 241z"/>
                            <path d="M905 1283 c-211 -76 -272 -336 -115 -493 61 -61 119 -85 210 -85 91
                            0 149 24 210 85 61 61 85 119 85 210 0 91 -24 149 -85 210 -80 80 -203 109
                            -305 73z m185 -153 c35 -25 70 -90 70 -130 0 -14 -9 -44 -20 -67 -48 -106
                            -195 -120 -268 -25 -38 50 -39 133 0 184 54 71 149 87 218 38z"/>
                            </g>
                        </svg>
                        Mostrar
                        </p>
                        <b-row v-else>
                            <b-col>
                                <p class="font-weight-bolder my-4 h4">R$ {{receiver}}</p>
                            </b-col>
                            <b-col @click="viewReceiver = !viewReceiver">
                                <svg version="1.0" fill="grey" class="" xmlns="http://www.w3.org/2000/svg" width="2.5rem" height="2.5rem" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                    fill="#000000" stroke="none">
                                    <path d="M2377 2742 l-1807 -1807 183 -183 182 -182 1808 1808 1807 1807 -183
                                    183 -182 182 -1808 -1808z"/>
                                    <path d="M2360 4089 c-236 -16 -516 -75 -746 -157 -644 -231 -1178 -683 -1513
                                    -1280 l-52 -93 52 -92 c168 -299 430 -611 679 -810 l85 -67 352 352 353 353
                                    -15 78 c-21 109 -19 281 4 392 98 476 514 815 1001 815 60 0 143 -7 187 -15
                                    l78 -15 222 222 c123 123 223 226 223 229 0 8 -171 45 -290 64 -206 31 -402
                                    39 -620 24z"/>
                                    <path d="M3902 3177 l-352 -352 15 -78 c20 -103 19 -278 -1 -375 -45 -218
                                    -130 -379 -284 -533 -155 -155 -336 -249 -554 -288 -86 -16 -261 -13 -353 4
                                    l-78 15 -222 -222 c-123 -123 -223 -226 -223 -229 0 -3 44 -16 97 -28 745
                                    -167 1534 -22 2164 398 185 123 281 202 440 360 196 196 353 404 473 624 l47
                                    86 -22 42 c-154 303 -472 681 -750 893 l-46 34 -351 -351z"/>
                                    </g>
                                </svg>
                                <p class="text-muted">esconder</p>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
export default {
    name:'Payments',
    props:['toreceiver', 'receiver', 'countToReceiver', 'countReceiver'],
    data(){    
        return{
            viewReceiver:false,
            viewToReceive:false,
            series: [this.countToReceiver,this.countReceiver],
            chartOptions: {
                chart: {
                    width: 380,
                    type: 'donut',
                },
                labels:['A receber', 'Pago'],
                dataLabels:{
                    enabled:false
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: false
                    }
                    }
                }],
                legend: {
                    position: 'right',
                    offsetY: 0,
                    height: 230,
                }
            }
        }
    },
    methods: {
        
    },
}
</script>
<style>

</style>
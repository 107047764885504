<template>
    <div class="reset-password remember">
        <b-row class="text-center border-rounded" align-v="center" align-h="center">
            <b-col cols="12">
                <p class="h5 font-weight-bolder">Redefinição de senha</p>
            </b-col>
            <b-col class="px-md-5" md="6" sm="12">
                <div class="form-group">
                <label for="password">Dígite sua nova senha *</label>
                <input :class="{'border border-danger' : error.password != 0 }" type="password" class="form-control form-control-lg" v-model="form.password" id="password">
                <span class="text-danger">
                {{error.password}}
                </span>
                <div class="">
                    {{this.strengthPassword}}
                    <div class="d-flex flex-row bd-highlight my-1" >
                        <span class="progress_password mr-2 rounded-pill bd-highlight" v-bind:class="{
                        'bg-danger' : passwordStrength.score <= 1,
                        'bg-warning' : passwordStrength.score == 2,
                        'bg-info' : passwordStrength.score == 3,
                        'bg-success' : passwordStrength.score == 4
                        }"></span>
                        <span class="progress_password mr-2 rounded-pill  bd-highlight" v-bind:class="{
                        'bg-secondary' : passwordStrength.score <= 1,
                        'bg-warning' : passwordStrength.score == 2,
                        'bg-info' : passwordStrength.score == 3,
                        'bg-success' : passwordStrength.score == 4
                        }"></span>
                        <span class="progress_password mr-2 rounded-pill bd-highlight" v-bind:class="{
                        'bg-secondary' : passwordStrength.score <= 2,
                        'bg-info' : passwordStrength.score == 3,
                        'bg-success' : passwordStrength.score == 4
                        }"></span>
                        <span class="progress_password rounded-pill bd-highlight" v-bind:class="{
                        'bg-secondary' : passwordStrength.score <= 3,
                        'bg-success' : passwordStrength.score == 4
                        }"></span>
                    </div>
                    </div>
                </div>
                <div>
                    <label for="password_confirm">Confirmar a senha*</label>
                    <input type="password" :class="{'border border-danger' : error.password_confirmation != 0 }" class="form-control form-control-lg" v-model="form.password_confirmation" id="password_confirm">
                    <span class="text-danger">
                    {{error.password_confirmation}}
                    </span>
                </div>
            </b-col>
            <b-col cols="12 mt-1" align-self="end">
            <b-overlay
                :show="show"
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
            >
                <b-button bg-mprimary @click="SendReset" :disabled="disabled">
                    ENVIAR
                    <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>
                </b-button>
            </b-overlay>
            </b-col>
            <b-col cols="12 mt-3">
                <a href="http://localhost:8081/auth">
                    Cancelar
                </a>
            </b-col>
            <b-col cols="12 border-top">
                <div class="text-center">
                    <img src="@/assets/img/Logotipo..png" alt="Logotipo" width="140">
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
const zxcvbn = require('zxcvbn')
import {baseApiUrl,showError, userKey, baseUrl} from '@/global'
import axios from 'axios'
import {debounce } from 'lodash'
export default {
    props:['token'],
    computed:{
        passwordStrength(){
            return zxcvbn(this.form.password)
        },
        strength(){
            this.passwordStrength.score = 0 ? this.strengthPassword = '' : this.strengthPassword = ''
            if(this.passwordStrength.score == 0){
                return this.strengthPassword = ''
            }
            if(this.passwordStrength.score == 1){
                return this.strengthPassword = 'Senha não aceitavél'
            }
            if(this.passwordStrength.score == 2){
                return this.strengthPassword = 'Senha não aceitavél, quase lá'
            }
            if(this.passwordStrength.score == 3){
                return this.strengthPassword = 'Senha aceitavél, mas você pode melhora'
            }
            if(this.passwordStrength.score == 4){
                return this.strengthPassword = 'Senha ótima !'
            }
        }
    },
    watch:{
        form(a,t){
            console.log(t,a)
        }
    },
    data(){
        return{
            form:{
                password:'',
                password_confirmation:'',
                token: this.token,
                email: localStorage.email_carreiras
            },
            disabled:false,
            strengthPassword:'',
            show:false,
            error: {password:'', password_confirmation:''},
            regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        }
    },
    methods: {
        passwordConfirm(pass, passconfirm){
            return pass === passconfirm ? true : false
        },
        overlay(){
            this.show = true
            let self = this
            setTimeout(function(){
                self.show = false
            }, 2000)
        },
        validate(){
            this.overlay()
            var error = 0;
            //erro de password
            if(this.form.password){
                  this.error.password = "" 
            }else if(this.form.password === undefined){
                showError("Preencha o campo o campo de senha")
                this.error.password = "Preencha o campo de senha"
                error++
            }else{
                this.error.password = "Senha invalida"
                error++
            }
            //erro de confirmação de password
            if(this.form.password_confirmation === undefined){
                this.error.password_confirmation = "Preencha o campo de senha corretamente"
                error++
            }else{
                this.error.password_confirmation = ""
            }
            if(this.passwordConfirm(this.form.password, this.form.password_confirmation)){
                this.error.password_confirmation = ""
            }else{
                showError("Senhas não conferir")
                this.error.password_confirmation = "Senha não conferir"
                error++
            }
            return (error === 0)
        },
        SendReset(){
            if(this.validate()){
                axios.post(`${baseApiUrl}/reset-password`, this.form)
                .then(rest => {
                    this.$bvToast.toast(`${this.form.email} senha redefinida com sucesso!`,{
                        title:` ${rest.message}`,
                        variant:'success',
                        toaster: 'b-toaster-top-center',
                        appendToast:true
                    })
                    this.$fire({title:`${rest}`,text:`${this.form.email} use seu email e nova senha para acessa sua conta.`,type:"success"});
                    this.disabled = true
                    window.location.href = `${baseUrl}`
                }).catch(error => showError('Tempo para troca expirado !'))
                
            }
        },
        teste(){
            this.$bvToast.toast(`${this.form.email} senha redefinida com sucesso!`,{
                title:` ${rest.message}`,
                variant:'success',
                toaster: 'b-toaster-top-center',
                appendToast:true
            })
            this.$fire({title:`${rest.message}`,text:`${this.form.email} use seu email e nova senha para acessa sua conta.`,type:"success"});
            this.disabled = true
        }
    },
}
</script>
<style lang="">
    
</style>
<template>
    <div>
        <b-container class="border rounded p-4 mt-4">
            <b-row align-h="between">
                <b-col cols="12" md="6">
                    <b-form-input
                        placeholder="Busca Cliente"
                        v-model="search"
                        type="text"
                        id="name"
                    >
                    </b-form-input>
                </b-col>
                <b-col cols="12" sm="6" class="d-md-flex">
                    <p class="m-auto col-md-3 col-sm-6"> Ordenar por</p>
                    <b-form-select
                        v-model="avaliation"
                        :options="list"
                    >
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row class="mt-4 ">
                <b-col cols="12" md="3">
                    <b-form-checkbox value="">Todos</b-form-checkbox>
                </b-col>
                <b-col cols="12" md="3">
                    <b-form-checkbox value="">Pagos</b-form-checkbox>                    
                </b-col>
                <b-col cols="12" md="3">
                    <b-form-checkbox value="">Recusados</b-form-checkbox>
                </b-col>
                <b-col cols="12" md="3">
                    <b-form-checkbox value="">Aguardando pagamento</b-form-checkbox>
                </b-col>
            </b-row>
            <b-container class="list">
                
                <CalendarDate 
                    service="analise"
                    candidate="Mario"
                    start="18:00"
                    end="17:00"
                />
                <CalendarDate 
                    service="analise"
                    candidate="Mario"
                    start="18:00"
                    end="17:00"
                />
                <CalendarDate 
                    service="analise"
                    candidate="Mario"
                    start="18:00"
                    end="17:00"
                />
                <CalendarDate 
                    service="analise"
                    candidate="Mario"
                    start="18:00"
                    end="17:00"
                />
                <CalendarDate 
                    service="analise"
                    candidate="Mario"
                    start="18:00"
                    end="17:00"
                />
                
            </b-container>
        </b-container>
    </div>
</template>
<script>
import CalendarDate from '../containers/CalendarDate'
export default {
    name:'Transactions',
    components:{CalendarDate},
    data(){
        return{
            list:[
                'Mais recente'
            ]
        }
    }
}
</script>
<style>
.list{
    flex:1;
    overflow-y:auto;
    height: 15rem;
}
.list::-webkit-scrollbar{
    width: 10px;
    height: 6px;
}
.list::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0, 0.2);
}
</style>
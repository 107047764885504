<template>
    <div class="sale-one">
        <b-skeleton-img v-if="this.loading === false"></b-skeleton-img>
        <div class="" v-for="data in dados" :key="data.index">
           <SheduleOne
                @getItem="message = $event"
                :total=data
                :icone=data.service.icon
                status="PAGO"
                :date_pay="data.date_pay | formatDate"
                :data="data.datetime | formatDate"
                :start=data.start
                :end_date=data.end_time
                :avatar=data.who_for.avatar
                :who_for="data.who_for.name != undefined ? data.who_for.name : data.who_for.presentation"
                :service=data.service.name
                :resume=data.service.resume
                :description=data.service.description
                :id=data.id
                :chat_id=data.chat_id
                :room_chat="data.room === null ? callRoom.callVideo : data.room"
           />
            <b-modal id="modal-scoped" centered hide-header hide-footer>

            </b-modal>
            <b-modal id="modal-center" centered hide-header hide-footer>
                <b-row align-h="between">
                    <b-col cols="2" @click="$bvModal.hide('modal-center')">
                        <svg bg-mprimary style="border-radius:0.4rem !important; transform:rotate(180deg);" xmlns="http://www.w3.org/2000/svg" width="2rem" height="1.5rem" fill="#eee" class="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                        </svg>
                    </b-col>
                    <b-col cols="2">
                        <span @click="$bvModal.hide('modal-center')" style="cursor:pointer !important;display:flex !important;justify-content:end !important">
                            <svg width="33" height="33" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11.5" fill="#FAFAFA"/>
                            <path d="M8 8L14.5 14.5" stroke="#292841" stroke-width="2"/>
                            <path d="M14.5 8L8 14.5" stroke="#292841" stroke-width="2"/>
                            </svg>
                            
                        </span>
                    </b-col>
                </b-row>
                <h5 class="font-weight-bolder h4">Iniciar conversar</h5>
                <b-row v-if="send === false">
                    <b-col cols="12">
                        <h1 class="my-3 h6">Envie um olá para começar!</h1>
                    </b-col>
                    <b-col cols="12">
                        <b-form-textarea
                            @keydown="keySend"
                            placeholder="Digite uma mensagem"
                            v-model="message.body"
                            class="mb-3"
                            rows="5"
                        ></b-form-textarea>
                    </b-col>
                    <b-col cols="12" class="text-left"> 
                        <button class="px-4 btn btn-sm text-light font-weight-bolder" @click.prevent="toSend" bg-mprimary>
                            ENVIAR
                            <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="ml-2 bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                            </svg>
                        </button>
                    </b-col>
                </b-row >
                <b-row v-else align-v="center m-5">
                    <b-col class="text-center">
                        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M39 19.5C39 30.2696 30.2696 39 19.5 39C8.73045 39 0 30.2696 0 19.5C0 8.73045 8.73045 0 19.5 0C30.2696 0 39 8.73045 39 19.5Z" fill="#6978F4"/>
                            <path d="M17 15.5C17 17.433 15.433 19 13.5 19C11.567 19 10 17.433 10 15.5C10 13.567 11.567 12 13.5 12C15.433 12 17 13.567 17 15.5Z" fill="white"/>
                            <path d="M29 15.5C29 17.433 27.433 19 25.5 19C23.567 19 22 17.433 22 15.5C22 13.567 23.567 12 25.5 12C27.433 12 29 13.567 29 15.5Z" fill="white"/>
                            <path d="M27 22.5C27 23.4849 26.806 24.4602 26.4291 25.3701C26.0522 26.2801 25.4997 27.1069 24.8033 27.8033C24.1069 28.4997 23.2801 29.0522 22.3701 29.4291C21.4602 29.806 20.4849 30 19.5 30C18.5151 30 17.5398 29.806 16.6299 29.4291C15.7199 29.0522 14.8931 28.4997 14.1967 27.8033C13.5003 27.1069 12.9478 26.2801 12.5709 25.3701C12.194 24.4602 12 23.4849 12 22.5L19.5 22.5H27Z" fill="white"/>
                        </svg>
                        <h1>Prontinho!</h1>
                        <p class="text-muted">
                            Sua mensagem foi enviada,
                            você pode ver suas conversas na aba Mensagens,
                            ou clicando <router-link :to="{path: `/message/chat/${retornApi.chat_id}`, params: {id: retornApi.chat_id}}" variant="light">aqui</router-link>
                        </p>
                    </b-col>
                </b-row>
            </b-modal>
       </div>
    </div>
</template>
<script>
import Title from '../containers/Title'
import SheduleOne from '../containers/SheduleOne'
import {baseApiUrl,showError, userKey} from '@/global'
import {mapState} from 'vuex'
import axios from 'axios'
export default {
    name:'SaleOne',
    components:{SheduleOne, Title},
    computed:mapState(['user', 'callRoom']),
    data(){
        return{
            dados:null,
            shedule:{},
            message:{},
            retornApi:1,
            send:false,
            loading:false
        }
    },
    methods: {
        get(){
            const url =  `${baseApiUrl}/shedules/${this.shedule.id}`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res =>{
                this.dados = res.data.shedule
            }).finally(this.loading = true)
        },
        keySend(e){
            if(e.keyCode === 13 && !e.shiftKey){
                e.preventDefault()
                this.toSend()
            }
        },
        toSend(){
            const url =  `${baseApiUrl}/messages/chat`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.post(url, this.message).then(res =>{
                this.retornApi = res.data
            })
            .finally(() => this.send = true)
        }
    },
    created() {
        this.shedule.id = this.$route.params.id
        this.get()
    }
}
</script>
<style>

</style>
<template>
    <div class="form-group">
        <input class="d-none" type="text" v-model="data.brand" name="brand" value="" id="cartao">
        <b-overlay :show="show" rounded="sm">
        <h5 class="font-weight-bolder">Checkout Cartão</h5>
        <label for="card-number col-12">Número do cartão *</label>
        <b-input-group class="form-row">
            <b-form-input
                class="form-control"
                v-model.trim="data.cardnumber"
                id="card-number"
                @change="get"
                :class="{'border border-danger' : error.cardnumber != 0 || error.cardvalid != 0 }"
                v-mask="'#### #### #### ####'"
            >
            </b-form-input>
            
            <img :src="this.infoCard.image" alt="" srcset="">           
        </b-input-group>
        <span class="text-danger">
                {{error.cardnumber}}
            </span>
            <span class="text-danger">
                {{error.cardvalid}}
            </span>
        <label for="card-number">Nome do titular do cartão *</label>
        <input 
            class="form-control" 
            v-model="data.holder" 
            id="holder"
            :class="{'border border-danger' : error.holder != 0 }" 
        >
        <span class="text-danger">
            {{error.holder}}
        </span>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label for="validate">Validade *</label>
                <input 
                    type="text" 
                    class="form-control" 
                    id="validate"
                    v-model="data.validate"
                    :class="{'border border-danger' : error.validate != 0 }"
                    v-mask="'##/####'"
                    placeholder="00/0000"
                >
                <span class="text-danger">
                    {{error.validate}}
                </span>
            </div>
            <div class="form-group col-md-6">
                <label for="securitycode">Cód. de Segurança *</label>
                <input 
                    type="text" 
                    class="form-control" 
                    id="securitycode"
                    v-model="data.security"
                    :class="{'border border-danger' : error.security != 0 }"
                    v-mask="'###'"
                    placeholder="000"
                >
                <span class="text-danger">
                    {{error.security}}
                </span>
            </div>
        </div>
        <label for="installment">Pagamento *</label>
        <select 
            class="form-control"
            id="installment" 
            v-model="data.installment"
            :class="{'border border-danger' : error.installment != 0 }"
        >
            <option selected>Selecione o parcelamento</option>
            <option 
                class="" v-for="(installment, index) in installments" 
                :key="index"
                :value="index+1"
            >
                {{installment}}
            </option>
        </select>
        <span class="text-danger">
            {{error.installment}}
        </span>
        <b-row align-h="between" class="my-3">
            <b-col>
                <b-button
                    :disabled="this.data.payment == ''"
                    @click.prevent="back"
                    size="sm" style="border-radius:0.4rem !important" 
                    bg-secondary 
                    class="px-4"
                >
                    <svg style="display:inline-block;vertical-align: bottom; transform:rotate(180deg);" xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="bi bi-arrow-right ml-3" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>
                    Volta
                </b-button>
            </b-col>
            <b-col>
                <b-button
                    
                    @click.prevent="createCredToken"
                    size="sm" style="border-radius:0.4rem !important" 
                    bg-mprimary 
                    class="px-4" 
                >
                    Finalizar comprar
                    <svg style="display:inline-block;vertical-align: bottom" xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="bi bi-arrow-right ml-3" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>
                </b-button>
            </b-col>
        </b-row>
        </b-overlay>
    </div>
</template>
<script>
const JQuery = require('jquery')
import {baseApiUrl,showError, userKey, pagSeguroErro} from '@/global'
import {mapState} from 'vuex'
import axios from 'axios'
export default {
    name:'card',
    props:['data', 'installments'],
    data(){
        return{
            show:false,
            error:{
                cardnumber:'',
                installment:'',
                security:'',
                validate:'',
                holder:'',
                cardvalid:''
            }
        }
    },
    computed:mapState(['infoCard']),
    methods: {
        back(){
            this.data.payment = ''
            this.$emit('setData', 'start')
        },
         /**
         * Pagamento cartão 
         */
        //pegando bandeira do cartão 
        get(){
            this.data.send = PagSeguroDirectPayment.getSenderHash()
            PagSeguroDirectPayment.getBrand({
                cardBin: this.data.cardnumber.replace(/ /g, ''),
                success:response => {
                    const name = response.brand.name
                    // Retorna os meios de pagamento disponíveis.
                    //console.log(cardBin)
                    this.$store.commit('setPay', name)
                    this.$store.commit('setCardImage', `https://stc.pagseguro.uol.com.br//public/img/payment-methods-flags/68x30/${name}.png`)
                    this.error.cardvalid = ""
                },
                error:response => {
                    this.error.cardvalid = "Cartão invalido, verifique a numeração do cartão!"
                    this.infoCard.image = ""
                    showError("Cartão invalido, verifique a numeração do cartão!")
                    // Callback para chamadas que falharam.
                    console.log(response)
                }
            })
        },
        //pegando token 
        createCredToken(){
            if(this.validate()){
                this.show = true
                PagSeguroDirectPayment.createCardToken({
                    cardNumber: this.data.cardnumber.replace(/ /g, ''), // Número do cartão de crédito
                    brand: this.infoCard.pay, // Bandeira do cartão
                    cvv: this.data.security, // CVV do cartão
                    expirationMonth: this.data.validate.substring(0,2), // Mês da expiração do cartão
                    expirationYear: this.data.validate.substring(3), // Ano da expiração do cartão, é 
                    success:response => {
                        // Retorna os meios de pagamento disponíveis.
                        console.log('Sucesso')
                        console.log(response)
                        const token = response.card.token
                        this.$store.commit('setCardToken', token)
                        this.SendCard()
                    },
                    error:response => {
                        // Callback para chamadas que falharam.errors
                        
                        pagSeguroErro(response)
                        console.log(response.errors)
                        this.show = false
                    }
                })
            }
        },
        validate(){
            var errors = 0;
            // Verificação preenchimento do campo nome do cartão
            if(this.data.holder){
                this.error.holder = ''
            }else{
                showError("Nome do titular do cartão precisa se preenchido")
                this.error.holder = "Campo obrigatorio !"
                errors++
            }
            // Verificação preenchimento do campo validade do cartão
            if(this.data.validate){
                this.error.validate = ''
            }else{
                showError("Coloque a validade do cartão.")
                this.error.validate = "Campo obrigatorio !"
                errors++
            }
            // Verificação preenchimento do numero do cartão
            if(this.data.cardnumber != undefined){
                if(this.data.cardnumber.length < 16){
                    showError("Numero do cartão incompleto")
                    this.error.cardnumber = "faltando números"
                    errors++
                }else{
                    this.error.cardnumber = ''
                }
            }else{
                showError("Campo cpf precisa ser preenchido")
                this.error.cardnumber = "Campo obrigatorio !"
                errors++
            }
            // Verificação preenchimento do CVC
            if(this.data.security != undefined){
                if(this.data.security.length < 3){
                    showError("Numero do codigo de segurança incompleto")
                    this.error.security = "faltando números"
                    error++
                }else{
                    this.error.security = ''
                }
            }else{
                showError("Coloque o codigo de segurança do cartão")
                this.error.security = "Campo obrigatorio !"
                errors++
            }
            // Verificação da seleção do parcelamento installment
            if(this.data.installment != undefined){
                this.error.installment = ''
            }else{
                showError("Escolha a opção de paracelamento")
                this.error.installment = "Campo obrigatorio !"
                errors++
            }
            // se o cartão for invalido 
            
            return (errors === 0)           
        },
        SendCard(){
            this.data.brand = this.infoCard.pay
            this.data.token = this.infoCard.token
            this.data.cardExpiryMonth = this.data.validate.substring(0,2)
            this.data.cardExpiryYear = this.data.validate.substring(3)
            
            this.show = true
            this.data.value = (this.data.service_price / this.data.installment).toFixed(2)
            const url =  `${baseApiUrl}/pagseguro-card`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.post(url,this.data)
            .then(res =>{
                this.$emit('setData', 'cardSucesso')
                this.data.code = res.data.code
                this.show = false
                console.log(res.data)
            })
            .catch(error =>showError('Verifique os dados, houve uma incompatibilidade!'))
            .finally(()=>this.show=false)            
        }
    },
    created() {
       
    },
}
</script>
import Echo from 'laravel-echo'
import {userKey, baseSocket } from '@/global'
window.Pusher = require('pusher-js')
const auth = JSON.parse(localStorage.getItem(userKey))
if(auth != undefined){
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'dd71fc6d24c122381c1f',
        cluster: 'us2',
        forceTLS: false,
        authEndpoint : `${baseSocket}/broadcasting/auth`,
        auth: {
            headers : { 
                'Authorization': 'Bearer '+auth.token,
            }
        },
    });
}
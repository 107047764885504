import {baseApiUrl,showError, userKey} from '@/global'
import {mapState} from 'vuex'
import axios from 'axios'
export default {
    computed:mapState(['user', 'profile']),
    methods: {
        SendBoleto(){
            console.log('oi')
        }
    },
    created() {
       
    }
}
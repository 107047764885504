<template>
    <div class="bloco-nota" dashboard>
        <Title
            name="Editor de Texto"
        />
        <transition mode="out-in"
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
        >
            <router-view/>
        </transition>
    </div>
</template>
<script>
import Title from '../components/containers/Title'
export default {
    name:"NotePad",
    components:{Title},
}
</script>
<style>

</style>
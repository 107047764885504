import {baseApiUrl,showError, userKey} from '@/global'
import axios from 'axios'
export default {
    state:{
        list:[],
        clients:[],
        member:[],
        tags:[],
        chatSupport:[
            {
                body:"Ola, faça sua pergunta !",
                id:"0",
                author_id:"0",
                image:"https://api-carreiras.carreiras.co/storage/images/favicon.ico"
            }
        ]
    },
    mutations:{
        // setando mensagens
        setChatSuport(state, chat){
            state.chatSupport = chat
        },
    },
    actions:{
        
    }
}
<template>
    <div class="remember">
        <b-row class="text-center border-rounded" align-v="center" align-h="center">
            <b-col md="6" sm="12">
                <b-form-group
                    id="input-group-1"
                    label="Digite o e-email"
                    label-for="input-1"
                    description="Digitei e clique em enviar. Iremos enviar intruições para seu e-mail, que e verifique seu e-mail, confira o spam."
                >
                    <b-form-input
                        id="input-1"
                        v-model="form.email"
                        type="email"
                        placeholder="Digite seu e-mail"
                        required
                        :class="{'border border-danger' : error.email != 0}"
                        size="sm"
                    >
                    </b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="12 mt-1" align-self="end">
            <b-overlay
                :show="show"
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
            >
                <b-button bg-mprimary @click="SendLink" :disabled="disabled">
                    ENVIAR
                    <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>
                </b-button>
            </b-overlay>
            </b-col>
            <b-col cols="12 mt-3">
                <a href="#" @click.prevent="cancelled">
                    Cancelar
                </a>
            </b-col>
            <b-col cols="12 border-top">
                <div class="text-center">
                    <img src="@/assets/img/Logotipo..png" alt="Logotipo" width="140">
                </div>
            </b-col>
        </b-row>       
    </div>
</template>
<script>
import {baseApiUrl,showError, userKey, baseUrl} from '@/global'
import axios from 'axios'
export default {
    name:"RememberEmail",
    props:['cancelled'],
    data(){
        return{
            form:{
                email:''
            },
            show:false,
            error: {email:"" },
            regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            disabled:false,
        }
    },
    methods: {
        overlay(){
            this.show = true
            let self = this
            setTimeout(function(){
                self.show = false
            }, 5000)
        },
        validateEmail(){
            this.overlay()
            var error = 0;
            //erro de email
            if(this.regex.test(this.form.email)){
                this.error.email = ""              
            }else if(this.form.email === ''){
                showError("Preencha o campo email")
                this.error.email = "Preencha o campo de email"
                error++
            }else{
                showError("Email não é válido")
                this.error.email = "E-mail invalido"
                error++
            }
            return (error === 0)
        },
        SendLink(){
            if(this.validateEmail()){
                localStorage.email_carreiras = this.form.email
                axios.post(`${baseApiUrl}/forgot-password`, this.form)
                .then(rest => {
                    this.$bvToast.toast(`Verifica sua caixa de e-mail, até o spam.`,{
                        title:`e-mail enviado com sucesso para ${this.form.email} - ${rest.data.status}`,
                        variant:'success',
                        toaster: 'b-toaster-top-center',
                        appendToast:true
                    })
                    this.$fire({title:`${rest.data.status}`,text:`e-mail enviado com sucesso para ${this.form.email}`,type:"success"});
                    this.disabled = true
                }).catch(error => showError(error))
            }
        }
    },
}
</script>
<style>
.remember{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
}
.remember .row{
    background-color: #cececea3;
    padding:4rem
}
</style>
<template>
    <b-row align-h="between">
        <b-embed
            type="iframe"
            aspect="16by9"
            :src="user.video"
            allowfullscreen
        ></b-embed>
        <b-col>
            <div class="d-flex">
                <div class="mt-3">
                    <!--<img :src="user.video" alt="" srcset="" class="rounded" width="400" height="500">-->
                    <input type="file" class="d-none" 
                            id="video" 
                            accept="video/*" 
                            ref="video" 
                            name="video" 
                            @change="getVideos($event)"
                          >
                     <a href @click.prevent="openFile()" class="edit-video">
                        <svg version="1.1"  xmlns="http://www.w3.org/2000/svg" fill="#4855BB" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        width="1.5rem" height="1.5rem" viewBox="0 0 528.899 528.899" style="enable-background:new 0 0 528.899 528.899;"
                        xml:space="preserve" >
                            <path d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981
                            c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611
                            C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069
                            L27.473,390.597L0.3,512.69z"/>
                        </svg><br>
                    </a>
                    <div v-if="videoFile.video" class="mt-2"> 
                        {{videoFile.video.name}}
                        <button class="btn badge-light" @click="videoFile.video = ''" type="button">
                            <i class="fa fa-trash text-danger"></i>
                        </button>
                    </div>
                    <div v-if="!setVideo">
                        <b-button @click.prevent="uploadVideo"  bg-mprimary class="mt-2">Upload</b-button>
                    </div>
                    <div v-if="dash">
                        <b-button @click.prevent="deleteFile"  bg-mprimary class="mt-2">delete</b-button>
                    </div>
                   
                </div>
            </div>
        </b-col>
    </b-row>
</template>
<script>
//import uploadVideo from '../../../mixins/Config/UploadVideo'
import {baseApiUrl,showError, userKey} from '@/global'
import {mapState} from 'vuex'
import axios from 'axios'
export default {
    name:"Video",
    //mixins:[uploadVideo],
    computed:mapState(['user']),
    data() {
        return {
            dash:false,
            inputVideo:false,
            state:true,
            setVideo:true,
            headers:{"Accept": "form-data"},
            video:{
                name:''
            },
            videoFile:''
        }
    },
    methods: {
        deleteFile(){
            this.video.name = ''
            this.videoFile=''
            this.user.video = ''
            this.dash = false
        },
        openFile(){
            this.$refs.video.value = ''
            this.$refs.video.click()
        },
        getVideos(e){     
            let select = e.target.files[0]
            console.log(select)
            this.videoFile = select;
            this.video.name = this.videoFile.name
            let reader = new FileReader()
            reader.readAsDataURL(select);
            reader.onload = e =>{
                this.user.video = e.target.result
                this.setVideo = false
            }
            this.dash = true
        },
        uploadVideo(){
            let formData = new FormData();
            formData.append('video', this.videoFile, this.videoFile.name)
    
            const url = `${baseApiUrl}/user/video`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.defaults.headers.common['Accept'] = 'form-data'
            axios.post(url,formData).then(res => {
                if(res.data.error != ""){
                    showError(res.data.error)
                }else{
                    this.setImage = true
                    this.$toasted.show(res.data.success)
                }
            }).catch(showError)            
        },
        setPresentation(){
            this.inputVideo = true
        },
        Loadfile(){
            if(e.target.files[0]){
                this.dash = true
            }
        }
    },
    mounted() {
        this.Loadfile()
    },
}
</script>
<style>

</style>
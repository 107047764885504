//import {baseApiUrl,showError, userKey} from '@/global'
//import axios from 'axios'
export default {
    namespaced:true,
    state:{
        availabilityWeekday: [],
        data:null
    },
    mutations:{
        //setando pelo dia da semana array com os dados
        avaiWeekday(state, payload){
            state.availabilityWeekday = payload
        },
        
        setData(state, payload){
            state.data = payload
        }
    },
    actions:{
        setAvailabilityWeekday(context, params){
           
           if(params.length > 0){
                let arrayNew = [];
                // iniciando como vazio
                let indexNew;
                for(let i=0;i<params.length; i++){
                    indexNew = params[i].weekday
                    arrayNew[indexNew] = params[i].hour
                }
                
                context.commit('avaiWeekday', arrayNew)
            }
        }
    }
}
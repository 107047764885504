const zxcvbn = require('zxcvbn')
import {baseApiUrl,showError, userKey, baseUrl} from '@/global'
import axios from 'axios'
export default {
    computed:{
        passwordStrength(){
            return zxcvbn(this.user.password)
        }
    },
    methods:{
        passwordConfirm(pass, passconfirm){
            return pass === passconfirm ? true : false
        },
        overlay(){
            this.show = true
            let self = this
            setTimeout(function(){
                self.show = false
            }, 2000)
        },
        validate(){
            this.overlay()
            var error = 0;
            //erro de email
            if(this.regex.test(this.user.email)){
                this.error.email = ""              
            }else if(this.user.email === undefined){
                showError("Preencha o campo email")
                this.error.email = "Preencha o campo de email"
                error++
            }else{
                showError("Email não é válido")
                this.error.email = "E-mail invalido"
                error++
            }
            //erro de password
            if(this.user.password){
                  this.error.password = "" 
            }else if(this.user.password === undefined){
                showError("Preencha o campo o campo de senha")
                this.error.password = "Preencha o campo de senha"
                error++
            }else{
                this.error.password = "Senha invalida"
                error++
            }
            return (error === 0)
        },
        validateRegisterOne(){
            this.overlay()
            var error = 0;
            //erro de email
            if(this.regex.test(this.user.email)){
                this.error.email = ""              
            }else if(this.user.email === undefined){
                showError("Preencha o campo email")
                this.error.email = "Preencha o campo de email"
                error++
            }else{
                this.error.email = "E-mail invalido"
                error++
            }
            
            //erro de password
            if(this.user.password.length < 6){
                this.error.password = "Senha tem que conter mais de 6 digitos"
                error++
            }else if(this.user.password === undefined){
                showError("Preencha o campo o campo de senha")
                this.error.password = "Preencha o campo de senha"
                error++
            }else{
                this.error.password = ""
            }
            //erro de confirmação de password
            if(this.user.password_confirm === undefined){
                this.error.password_confirm = "Preencha o campo de senha corretamente"
                error++
            }else{
                this.error.password_confirm = ""
            }
            if(this.passwordConfirm(this.user.password, this.user.password_confirm)){
                this.error.password_confirm = ""
            }else{
                showError("Senhas não conferir")
                this.error.password_confirm = "Senha não conferir"
                error++
            }
            if(error === 0){
                return this.showSignin = true
            }
            
        },
        validateRegister(){
            this.overlay()
            var error = 0;
            //erro de birthdate
            if(this.user.birthdate){
                this.error.birthdate = ""
            }else if(this.user.birthdate === undefined){
                showError("Preencha o campo data de nascimento")
                this.error.birthdate = "Campo obrigatorio vazio"
                error++
            }else{
                this.error.password = "Data de nascimento invalida"
                error++
            }
            //erro de name
            if(this.user.name.length < 6){
                this.error.name = "Campo tem que conter nome e sobrenome"
            }else if(this.user.name === undefined){
                showError("Preencha o campo data de nascimento")
                this.error.name = "Campo obrigatorio vazio"
                error++
            }else{
                this.error.name = ""
            }
            if(this.user.terms == ""){
                showError("O campo termos deve ser preenchido")
                this.error.terms = "Leia os termos e clique em aceitar para acessar nossa plataforma"
                error++
            }else{
                this.error.terms = ""
            }
            return (error === 0)
        },
        signin(){
            if(this.validate()){
                axios.post(`${baseApiUrl}/auth/login`, this.user, this.headers)
                .then(rest => {
                
                    if(!rest.data.error){
                        this.showLoading = true
                        this.$store.commit('setUser', rest.data)
                        localStorage.setItem(userKey, JSON.stringify(rest.data))
                        //Essa não é a forma ideal mas até aqui a unica que encontrei de fazer a 
                        // validação do token e seta o usuarios
                        window.location.href = `${baseUrl}`
                        //this.$router.push({ name: 'Home',})
                    }else{
                        showError(rest.data.error)
                    }
                    console.log('oiii sumida', rest)
                }).catch((e) => showError(e))
            }
        },
        signup(){
            if(this.validateRegister()){
                axios.post(`${baseApiUrl}/auth/register`, this.user)
                .then(json=>{
                    if(!json.data.success){
                        showError(json.data.error)
                    }else{
                        this.$fire({
                            title:`Seja bem vindo!`,
                            text:`Obrigado,você esta um passo de usar nossa plataforma e dar um salto em sua carreiras.`,
                            type:"success"
                        });
                        this.showTop = true
                        this.showSignup = false
                        this.user = {}
                    }
                }).catch(showError)
            }
        },
        teste(){
            this.$fire({
                title:`Seja bem vindo!`,
                text:`Obrigado,você esta um passo de usar nossa plataforma e dar um salto em sua carreiras.`,
                type:"success"
            });
            this.showTop = true
        }
    },
    created(){
        // this.strength()
    }
}
<template>
    <div class="view-avatar">
        <img 
            :src="imageAvatar | imageURL" 
            :alt="`avatar-${name}`" 
            class="mr-2 border rounded-circle bg-light img-fluid" 
            style="max-width: 2.5rem;max-height:2rem"
        > 
        <b style="color:#000 !important">{{name}}</b><i class="ml-3 fa fa-list" aria-hidden="true"></i>
    </div>
</template>

<script>
// import { baseApiUrl, userKey, showError, showSuccess } from "@/global"
export default{
    name:"ViewAvatar",
    props:{
        imageAvatar:{type:String, default:"img/default-avatar.jpg"},
        name:{type:String, default:"Fulano de tal"}
    }
}
</script>
<template>
    <div class="account">
        <Avatar/>
        <div class="form-group">
            <label> E-MAIL</label>
            <input type="email" name="email" :value="user.email" disabled id="email" class="form-control  col-md-4 col-sm-12 form-control-lg">
        </div>
        <div class="form-group">
            <label> SENHA</label>
            <div class="d-flex">
            <input type="password" name="password" disabled placeholder="*********" id="password" class="form-control  col-md-4 col-sm-12 form-control-lg">
            <svg class="ml-3" v-b-modal.modal-center version="1.1" width="1.2rem" fill="currentColor" heigth="1.2rem"
             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 477.873 477.873" style="enable-background:new 0 0 477.873 477.873;outline-style: none;" xml:space="preserve">
                <path d="M392.533,238.937c-9.426,0-17.067,7.641-17.067,17.067V426.67c0,9.426-7.641,17.067-17.067,17.067H51.2
                    c-9.426,0-17.067-7.641-17.067-17.067V85.337c0-9.426,7.641-17.067,17.067-17.067H256c9.426,0,17.067-7.641,17.067-17.067
                    S265.426,34.137,256,34.137H51.2C22.923,34.137,0,57.06,0,85.337V426.67c0,28.277,22.923,51.2,51.2,51.2h307.2
                    c28.277,0,51.2-22.923,51.2-51.2V256.003C409.6,246.578,401.959,238.937,392.533,238.937z"/>
                <path d="M458.742,19.142c-12.254-12.256-28.875-19.14-46.206-19.138c-17.341-0.05-33.979,6.846-46.199,19.149L141.534,243.937
                    c-1.865,1.879-3.272,4.163-4.113,6.673l-34.133,102.4c-2.979,8.943,1.856,18.607,10.799,21.585
                    c1.735,0.578,3.552,0.873,5.38,0.875c1.832-0.003,3.653-0.297,5.393-0.87l102.4-34.133c2.515-0.84,4.8-2.254,6.673-4.13
                    l224.802-224.802C484.25,86.023,484.253,44.657,458.742,19.142z"/>
            </svg>
            </div>
            <b-modal id="modal-center" 
                centered
                hide-header
                hide-footer
                no-close-on-backdrop
                no-close-on-esc
            >
                <b-row align-h="between">
                    <b-col cols="2" @click="$bvModal.hide('modal-center')">
                        <svg bg-mprimary style="border-radius:0.4rem !important; transform:rotate(180deg);" xmlns="http://www.w3.org/2000/svg" width="2rem" height="1.5rem" fill="#eee" class="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                        </svg>
                    </b-col>
                    <b-col cols="2">
                        <span @click="$bvModal.hide('modal-center')" style="cursor:pointer !important;display:flex !important;justify-content:end !important">
                            <svg width="33" height="33" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11.5" fill="#FAFAFA"/>
                            <path d="M8 8L14.5 14.5" stroke="#292841" stroke-width="2"/>
                            <path d="M14.5 8L8 14.5" stroke="#292841" stroke-width="2"/>
                            </svg>
                            
                        </span>
                    </b-col>
                </b-row>
                <Password/>
            </b-modal>
           
        </div>
        <div class="row">
            <ul class="col-6" list>
                <li class="my-3"><a href="">VER TERMOS DE USO</a></li>
                <li class="mb-3"><a href="">VER POLITICA DE PRIVACIDADE</a></li>
                <li><a href="">APAGAR CONTA</a></li>
            </ul>
        </div>
    </div>
</template>
<script>
import Password from '../containers/Password'
import Avatar from '../containers/Avatar'
import {mapState} from 'vuex'
export default {
    name:'account',
    computed:mapState(['user']),
    components:{Password, Avatar},
}
</script>
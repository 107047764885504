import {baseApiUrl,showError, userKey} from '@/global'
import {mapState} from 'vuex'
import axios from 'axios'
export default {
    computed:mapState(['chat', 'user']),
    methods: {
        keySend(e){
            if(e.keyCode === 13 && !e.shiftKey){
                e.preventDefault()
                this.toSend()
            }
        },
        scrollToElement() {
            const el = this.$refs.list;

            if (el) {
            // Use el.scrollIntoView() to instantly scroll to the element
            el.scrollIntoView({behavior: 'smooth'});
            }
        },
        scrollToEnd(){
            var container = document.querySelector(".list");
            var scrollHeight = container.scrollHeight;
            container.scrollTop = scrollHeight;
        },
        toSend(){
            this.loading = true
            //this.$store.dispatch('storeMessage', this.$route.params.id, {body:this.send.body, receiver_id: this.send.receiver_id})
            const url =  `${baseApiUrl}/chat/${this.$route.params.id}`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.post(url, this.send).then(res =>{
                console.log(res.data)
                //this.$store.commit('addMessage',res.data.dados)
                this.send.body = ''
            }).finally(() => this.loading = false)
        },
        getMessage(){
            this.show = true
            const url =  `${baseApiUrl}/messages/chat/${this.messages.id}`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res =>{
                if(res.data){
                    // BOA FAZER TESTE DE COMPUTED
                    this.$store.commit('setChat', res.data)
                    console.log(res.data)
                    let receiver = res.data[0]['author_id'] === this.user.id ? res.data[0]['receiver_id'] : res.data[0]['author_id']
                    
                    this.send.title_group = this.chat[0].title
                    this.send.receiver_id = receiver
                    
                }
                
            }).finally(() => {
                this.show = false
                
                //não funcionou com $refs
                this.scrollToEnd()
            })
            
        }
    },
    created() {
        this.messages.id = this.$route.params.id
        this.getMessage()
    },mounted() {
        this.scrollToEnd()
    },
    updated() {
        this.scrollToEnd();
        
    }
}
<template>
    <div>
        <b-container fluid>
            <RowProfile/>
            <b-row class="mt-4">
                <b-col cols="12" align-v="center">
                    <div>
                    <p class="text-muted">
                        RESUMO
                        <a class="text-decoration-none ml-2 pb-5" href @click.prevent="editAbout = !editAbout">
                            <svg version="1.1" width="1.2rem" fill="currentColor" heigth="1.2rem" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 477.873 477.873" style="enable-background:new 0 0 477.873 477.873;" xml:space="preserve">
                                <path d="M392.533,238.937c-9.426,0-17.067,7.641-17.067,17.067V426.67c0,9.426-7.641,17.067-17.067,17.067H51.2
                                    c-9.426,0-17.067-7.641-17.067-17.067V85.337c0-9.426,7.641-17.067,17.067-17.067H256c9.426,0,17.067-7.641,17.067-17.067
                                    S265.426,34.137,256,34.137H51.2C22.923,34.137,0,57.06,0,85.337V426.67c0,28.277,22.923,51.2,51.2,51.2h307.2
                                    c28.277,0,51.2-22.923,51.2-51.2V256.003C409.6,246.578,401.959,238.937,392.533,238.937z"/>
                                <path d="M458.742,19.142c-12.254-12.256-28.875-19.14-46.206-19.138c-17.341-0.05-33.979,6.846-46.199,19.149L141.534,243.937
                                    c-1.865,1.879-3.272,4.163-4.113,6.673l-34.133,102.4c-2.979,8.943,1.856,18.607,10.799,21.585
                                    c1.735,0.578,3.552,0.873,5.38,0.875c1.832-0.003,3.653-0.297,5.393-0.87l102.4-34.133c2.515-0.84,4.8-2.254,6.673-4.13
                                    l224.802-224.802C484.25,86.023,484.253,44.657,458.742,19.142z"/>
                            </svg>
                        </a>
                    </p> 
                    </div>
                    <div class="mt-4">
                        <div v-if="freelancer.about == null && editAbout == false" class="text-center">
                            <NotSet
                                frase="Por enquanto você não adicionou nada sobre você"
                            />
                            <b-row align-h="center">
                                <b-col lg="8" cols="12">
                                    <b-button class="btn-sm" bg-mprimary @click.prevent="editAbout = !editAbout">
                                        ADICIONAR RESUMO
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                        <div v-if="editAbout">
                            <b-form-textarea 
                                rows="3"
                                max-rows="8"
                                :value="freelancer.about"
                                v-model="freelancer.about"
                            ></b-form-textarea>
                            <div class="d-flex col-12 flex-column align-items-center">
                            <b-button  bg-mprimary @click="editProfile">
                                Enviar
                            </b-button>

                            </div>
                        </div>
                        <p v-if="!editAbout">{{freelancer.about}}</p>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-5 text-muted">
                <p> SETORES DE ENTENDIMENTO</p>
            </b-row>
            <b-row class="mt-5 text-muted">
                <p>SERVIÇOS</p>
            </b-row>
            <div v-if="freelancer.services === null" class="text-center">
                <b-row align-h="center">
                    <NotSet
                        frase="Por enquanto você não selecionar nenhuma serviço"
                        textButton=""
                    />
                </b-row>
            </div>
        </b-container>
        <Services/>
        <b-row align-h="center" class="text-center">
            <b-col cols="12" class="my-3">
                <b-overlay
                    :show="show"
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                >
                    <b-button bg-mprimary v-b-modal.modal-center @click.prevent="overlay">
                        ADICIONAR SERVIÇOS
                    </b-button>
                </b-overlay>
                <b-modal 
                    id="modal-center" 
                    centered 
                    hide-header
                    hide-footer
                    no-close-on-backdrop
                    no-close-on-esc
                >
                <b-row align-h="end">
                    <b-col cols="2">
                        <span @click="$bvModal.hide('modal-center')" style="cursor:pointer !important;display:flex !important;justify-content:end !important">
                            <svg width="33" height="33" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11.5" fill="#FAFAFA"/>
                            <path d="M8 8L14.5 14.5" stroke="#292841" stroke-width="2"/>
                            <path d="M14.5 8L8 14.5" stroke="#292841" stroke-width="2"/>
                            </svg>
                        </span>
                    </b-col>
                </b-row>
                    <SelectService/>
                </b-modal>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import RowProfile from '../../containers/RowProfile'
import Services from '../../containers/Services'
import SelectService from '../../containers/SelectServices'
import { mapState } from 'vuex'
import Profile from '../../../mixins/Profile'
import NotSet from '../../containers/NotSet'
export default {
    name:'ConfProfile',
    components:{Services, RowProfile, SelectService, NotSet},
    computed:mapState(['user', 'freelancer']),
    data(){
        return {
            editAbout: false,
            show: false
        }
    },
    methods: {
        activeButton(){
            this.editAbout = true
        }
    },
    mixins:[Profile]
}
</script>
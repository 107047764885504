<template>
    <div class="bank">
        <div v-if="this.have == ''">
            <b-row 
                align-h="between" 
                class="p-3 mt-3 rounded" 
                bg-mlight
                v-for="(item, index) in account" :key="index"
            >
                <b-col> 
                    <h1 text-msecondary class="h5 m-auto">
                        {{item.full_name}}
                    </h1>
                </b-col>
                <b-col class="d-flex">
                    <div @click="loadAccount(item, 'edit')">ver conta</div>
                    <div class="ml-5 form-check">
                        <input 
                            v-if="item.status == 1 "
                            type="checkbox" 
                            class="form-check-input" 
                            name="conta" 
                            checked
                        >
                        <input 
                            v-else
                            type="checkbox" 
                            class="form-check-input" 
                            name="conta" 
                            :id=item.id
                            disabled
                        >
                        <label 
                            class="form-check-label" 
                            @click="Sendfavorite(item, 'favorite')" 
                            :for=item.id
                        >Conta para saque</label>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div v-else class="container">
            {{have}}
        </div>
        <b-row align-h="center">
            <b-button bg-mprimary class="my-3" @click="createAccount('create')">
                ADICIONAR CONTA
            </b-button>
        </b-row>
        <b-modal 
            id="modal-center" 
            centered
            hide-header
            hide-footer
            no-close-on-backdrop
        >
            <b-row align-h="between">
                <b-col cols="2" @click="closeModal">
                    <svg bg-mprimary style="border-radius:0.4rem !important; transform:rotate(180deg);" xmlns="http://www.w3.org/2000/svg" width="2rem" height="1.5rem" fill="#eee" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>
                </b-col>
                <b-col cols="2">
                    <span @click="closeModal" style="cursor:pointer !important;display:flex !important;justify-content:end !important">
                        <svg width="33" height="33" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="11.5" cy="11.5" r="11.5" fill="#FAFAFA"/>
                        <path d="M8 8L14.5 14.5" stroke="#292841" stroke-width="2"/>
                        <path d="M14.5 8L8 14.5" stroke="#292841" stroke-width="2"/>
                        </svg>
                        
                    </span>
                </b-col>
            </b-row>
            <AccountBank
                :select=selectAccount
                :mode=mode
                :favorite=favorite
            />
        </b-modal>
    </div>
</template>
<script>
import axios from 'axios'
import {mapState} from 'vuex'
import { baseApiUrl, userKey } from '@/global'
import AccountBank from '../containers/AccountBank'
export default {
    name: 'Bank',
    components:{AccountBank},
    computed:mapState(['user']),
    data(){
        return{
            account:[],
            have:'',
            selectAccount:{},
            mode:'',
            favorite:{}
        }
    },
    methods: {
        closeModal(){
            // tem que zera o v-model
            this.$bvModal.hide('modal-center')
            this.selectAccount = ''
        },
        getAccount(){
            const url =  `${baseApiUrl}/bank`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res =>{
                if(res.data.error != ''){
                    this.have = res.data.error
                }else{
                    this.account = res.data.account
                }
            })
        },
        loadAccount(account, mode){
            this.mode = mode
            this.selectAccount = { ...account}
            this.$bvModal.show('modal-center')
        },
        createAccount(mode){
            this.$bvModal.show('modal-center')
            this.mode = mode
        },
        Sendfavorite(account, mode){
            this.favorite ={
                id_user : this.user.id,
                id_account: account.id,
                status: 1
            }
            this.$bvModal.show('modal-center')
            this.mode = mode
        }
    },
    created() {
        this.getAccount()
    },


}
</script>
<template>
    <div class="availability-hours">
        <div class="" v-if="!loading">
        
            <b-row align-h="center">
                <b-col cols="6">
                    <div class="mt-md-5 d-flex"> 
                        <h2 class="h5 font-weight-bolder">
                            Horários 
                        </h2>
                        <!-- <span class="text-monospace text-muted" v-if="!getDataDay.status">(inativado)</span> -->
                        <!-- <div class="" v-if="!availability || !getDataDay.status">ESTOU AQUI</div> -->
                        <span class="text-monospace text-muted" v-if="checkAlreadyExists">(Configure para aparece)</span>
                        
                        <span class="text-monospace text-muted" v-if="checkStatusAvailability">inativo</span>
                    </div>
                    
                    <b-form-checkbox-group
                        v-model="selectedHour"
                        :options="hours"
                        :disabled="checkStatusAvailability"
                    ></b-form-checkbox-group>
                
                </b-col>
            </b-row>
            <b-row  v-if="checkStatusAvailability" align-h="center">
                <b-button variant="success" cols="3" v-if="availability" @click.prevent="activeWeekday">Reativa dia</b-button>
            </b-row>
            <b-row align-h="around" class="my-5" v-else>
                <!-- <b-col cols="12"> -->
                    
                    <b-button cols="3" v-if="availability" @click.prevent="showMsgBoxTwo" variant="danger">Inativa dia</b-button>
                    <!-- <b-button variant="danger" cols="3" v-if="availability" @click.prevent="deleteWeekday">Excluir</b-button> -->
                    <b-button 
                    cols="3" 
                    v-if="selectedHour.length > 0 && verifyDiff.statusDiff" 
                    variant="outline-primary"
                    @click.prevent="updatedWeekday()">Atualiza</b-button>
                <!-- </b-col> -->
            </b-row>
        </div>
        <b-skeleton-img no-aspect height="150px" v-else></b-skeleton-img>   
    </div>
</template>
<script>
import { mapState } from 'vuex'
import axios from "axios"
import { baseApiUrl, userKey, showError, showSuccess } from "@/global"
export default {
    name:'AvailabilityHours',
    // props:['availability'],
    props:{ availability:{type:String}, dayWeek:{type:Number, required:true}},
    computed:{
        verifyDiff(){
            return{
                statusDiff:this.selectedHour != this.availability,
            }
        },
        getDataDay(){
            return this.$store.state.freelancer.free_availability.filter((e) => { return e.weekday === this.dayWeek})
        },
        checkAlreadyExists(){
            return !this.availability && this.getDataDay.length <= 0
        },
        checkStatusAvailability(){
            // 0 false
            // 1 tru 
            return !this.checkAlreadyExists && (this.getDataDay[0].status == 0)
        }
    },
    data(){
        return{
            selectedHour:[],
            tabIndex:0,
            loading:true,
            setData:{
                weekday:null,
                hour:null
            },
            hours:[
                { text: '00:00', value: '00:00' },
                { text: '01:00', value: '01:00' },
                { text: '02:00', value: '02:00' },
                { text: '03:00', value: '03:00' },
                { text: '04:00', value: '04:00' },
                { text: '05:00', value: '05:00' },
                { text: '06:00', value: '06:00' },
                { text: '07:00', value: '07:00' },
                { text: '08:00', value: '08:00' },
                { text: '09:00', value: '09:00' },
                { text: '10:00', value: '10:00' },
                { text: '11:00', value: '11:00' },
                { text: '12:00', value: '12:00' },
                { text: '13:00', value: '13:00' },
                { text: '14:00', value: '14:00' },
                { text: '15:00', value: '15:00' },
                { text: '16:00', value: '16:00' },
                { text: '17:00', value: '17:00' },
                { text: '18:00', value: '18:00' },
                { text: '19:00', value: '19:00' },
                { text: '20:00', value: '20:00' },
                { text: '21:00', value: '21:00' },
                { text: '22:00', value: '22:00' },
                { text: '23:00', value: '23:00' },
            ]
        }
    },
    methods: {
        updateValue(){
            this.selectedHour =  this.availability ? this.availability.split(',') : []
            this.loading = false
        },
        showMsgBoxTwo() {
        this.boxTwo = ''
        this.$bvModal.msgBoxConfirm('Apos essa ação não estará mais disponível esse dia da semana para seus clientes agendarem.', {
          title: 'Tem certeza?',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(value => {
            this.boxTwo = value
            if(value){
                this.suspenseWeekday()
            }
            // console.log('ESTOU AQUI THEN', value)
            
        }).catch(err => {
            console.log('ESTOU AQUI ERROR', err)
            // An error occurred
        }).finally(() => this.loading = false)
        },
        updatedWeekday(){
            this.loading = true
            const sendData = {
                weekday:this.dayWeek,
                hour:this.selectedHour.join(),
                status:1,
                id: this.getDataDay.length>0 ? this.getDataDay[0].id :''
            }

            this.getDataDay[0] ? this.putAvailability(sendData) : this.configWeekday(sendData)
        },
        setUpdateLocal(newOrigin){
            let upJson = this.$store.state.freelancer.free_availability.map((e) =>{
                if(e.id == newOrigin.id){
                    return newOrigin
                }
                return e
            })
            return upJson
        },
       
        suspenseWeekday(){
            this.loading = true
            const sendData = {
                weekday:this.dayWeek,
                hour:this.selectedHour.join(),
                status:0,
                id:this.getDataDay[0].id
            }
            // verificar se a seleção esta vazia se estive tem que fazer outra tipo de requisição
            this.selectedHour.length > 0 ? this.putAvailability(sendData) : this.deleteWeekday(sendData)
        },
        
        activeWeekday(){
            this.getDataDay[0].status = 1
            this.putAvailability(this.getDataDay[0])
        },
        configWeekday(sendData){
            this.loading = true
            const url = `${baseApiUrl}/availability`
            axios.post(url, sendData).then( res => {
                showSuccess(res.data.message)
                // console.log('resposta -> ', res)
                // console.log('estou no configWeekday', res.data)
                this.$store.commit('updateFreeAvailability', res.data.availability)                
                this.$router.push({name:'Perfil'})
            }).catch(e =>  {
                showError(e)
                console.log('catch => ', e)
            }).finally(() => this.loading = false)
        },
        putAvailability(data){
            this.loading = true
            const url = `${baseApiUrl}/availability/${data.id}`
            axios.put(url, data).then( res => {
                showSuccess(res.data.message)
                console.log('resposta put -> ', res)
                // console.log(this.setUpdateLocal(res.data.availability))
                this.$store.commit('updateFreeAvailability', this.setUpdateLocal(res.data.availability))                
                this.$router.push({name:'Perfil'})
            }).catch(e =>  {
                showError(e)
                console.log('catch => ', e)
            }).finally(() => this.loading = false)
        },
        deleteWeekday(data){
            this.loading = true
            const url = `${baseApiUrl}/availability/${data.id}`
            axios.delete(url).then( res => {
                showSuccess(res.data.message)
                
                console.log('estou no deleteWeekday -> ', res.data)
                this.$store.commit('updateFreeAvailability', res.data.availability)                
                this.$router.push({name:'Perfil'})
            }).catch(e =>  {
                showError(e)
                console.log('catch => ', e)
            }).finally(() => this.loading = false)
        }
    },
    mounted() {
        this.updateValue()
    },
}
</script>
<template>
    <div>       
        <b-row align-h="between" class="mt-3 py-1 border rounded" >
            <b-col align-self="start" >
                <div class="d-flex">
                    <div class="my-auto">
                        <img :src="image" class="mr-3 rounded p-2" bg-mprimary width="50" height="50">
                    </div>
                    <div class="ml-2 my-auto">
                        <b class="h5 font-weight-bolder"> {{service}}</b>
                        <p id="to">Para - {{candidate}}</p>
                    </div>
                </div>
            </b-col>
            <b-col  md="3" offset-md="3" class="border-left my-auto" align-center="center">
                <p class="font-weight-bolder">
                    {{date | moment("DD/MM/YYYY")}}<br/>
                    {{start}} - 
                    {{end}}<br/>
                </p>
                <b-button size="sm" style="border-radius:0.4rem !important" bg-mprimary>
                    <router-link :to="{path: `/sales/salesOne/${id}`, params: {id: id}}" tag="p" class="m-auto" >
                        PÁGiNA DA VENDA 
                    </router-link>
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    name:'CalendarDate',
    props:['service', 'candidate', 'date', 'start', 'end', 'image', 'id']
}
</script>
<style>
#to{
    color: grey;
    margin-bottom: 0 !important;
}
</style>
<template>
  <div id="app">
    <Loading v-if="validatingToken" />
    <Content v-if="!validatingToken && !validatingEmail" />
    <EmailVerifield 
        v-if="validatingEmail"
        :name=user.name
        :email=user.email
    />
  </div>
</template>
<script>
import axios from "axios"
import { mapState } from 'vuex'
import { baseApiUrl, userKey, baseUrl,showError, profilePurchase } from "@/global"
import Loading from './components/template/Loading'
import Content from "@/views/Content"
import EmailVerifield from '@/components/auth/AuthEmail'
export default {
    name:"App",
    components: { Loading,Content,EmailVerifield},
    computed:mapState(['user', 'freelancer', 'candidate', 'controleRouter']),
    data() {
        return {
            validatingToken: true,
            validatingEmail:'',
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('setControleRouter', from.name)
        console.log(to.name)
        next()
    },
    methods: {
        async validateToken(){
            this.validatingToken = true
            const json = localStorage.getItem(userKey)
            const userData = JSON.parse(json)
            this.$store.commit('setUser', null)
            if(!userData){
                this.validatingToken = false
                //console.log(window.location.toString())
              
                if(window.location.href === baseUrl){
                    this.$router.push({ name: 'auth'})
                    return
                }else{
                    let req = this.$router.app._route.query.q 
                    this.$router.push({ name: 'auth', query:{q:req} })
                    return
                }
            }
            const res = await axios.post(`${baseApiUrl}/auth/validate`, userData)
            if(res.data.user){
                let loggedUSer = res.data.user	
                this.$store.commit('setUser', loggedUSer)
                if(loggedUSer.verifield_email !== null){
                    if(loggedUSer.type == 'freelancers'){
                        this.setFreelancer(loggedUSer.id)
                    }else{
                        this.setCandidate(loggedUSer.id)
                    }
                    //redirecionando caso o usuario tenha vindo atraves de algum perfil 
                    if(localStorage.getItem(profilePurchase) != null){
                        let profile = localStorage.getItem(profilePurchase)
                        this.$router.push({ path: `/perfil/${profile}/about`, query:{buy:'buy'}})
                    }
                    // // Se o usuario já estiver setado o usuario estive logado
                    // this.$router.push({ name: 'Home'})
                }else{
                    this.validatingEmail = true
                    this.$router.push({ name: 'email'})
                }
            } else {
                localStorage.removeItem(userKey)
                this.$router.push({ name: 'auth', query:{q:q} })
            }
            this.validatingToken = false
        },
        setFreelancer(loggedId){
            const url = `${baseApiUrl}/freelancer/${loggedId}`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res => {
                this.$store.commit('setFree', res.data.free)
                //this.profile = res.data.free
                this.$router.push({ name: 'overview'})
            }).catch(err => showError(err))
        },
        setCandidate(loggedId){
            const url = `${baseApiUrl}/candidate/${loggedId}`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res => {
                this.$store.commit('setCandidate', res.data.candidate)
                //this.profile = res.data.free
                this.$router.push({ name: 'Home'})
            }).catch(err => showError(err))
        }
    },
    created(){
        this.validateToken()
    }
}
</script>
<style>
</style>

<template>
    <div>
        <b-container>
            <!-- <div class="text-center">
                <h1 class="h5 font-weight-bolder"> Escolha os dias/horas</h1>
            </div> -->
            <h2 class="h5 font-weight-bolder mt-md-5"> Dias da Semana</h2>
            <b-row align-h="center">
                <b-tabs v-model="tabIndex" card>
                    <b-tab title="Domingo" :title-link-class="linkClass(0)" :key="0">
                        <b-skeleton-img no-aspect height="150px" v-if="loading"></b-skeleton-img>
                        <AvailabilityHours :availability="availabilityWeekday[0]" :dayWeek="0" v-else />
                    </b-tab>
                    <b-tab title="Segunda-feira" :title-link-class="linkClass(1)" :key="1">
                        <b-skeleton-img no-aspect height="150px" v-if="loading"></b-skeleton-img>
                        <AvailabilityHours :availability="availabilityWeekday[1]" :dayWeek="1" v-else/>
                    </b-tab>
                    <b-tab title="Terça-feira" :title-link-class="linkClass(2)" :key="2">
                        <b-skeleton-img no-aspect height="150px" v-if="loading"></b-skeleton-img>
                        <AvailabilityHours :availability="availabilityWeekday[2]" :dayWeek="2" v-else/>
                    </b-tab>
                    <b-tab title="Quarta-feira" :title-link-class="linkClass(3)" :key="3">
                        <b-skeleton-img no-aspect height="150px" v-if="loading"></b-skeleton-img>
                        <AvailabilityHours :availability="availabilityWeekday[3]" :dayWeek="3" v-else/>
                    </b-tab>
                    <b-tab title="Quinta-feira" :title-link-class="linkClass(4)" :key="4">
                        <b-skeleton-img no-aspect height="150px" v-if="loading"></b-skeleton-img>
                        <AvailabilityHours :availability="availabilityWeekday[4]" :dayWeek="4" v-else/>
                    </b-tab>
                    <b-tab title="Sexta-feira" :title-link-class="linkClass(5)" :key="5">
                        <b-skeleton-img no-aspect height="150px" v-if="loading"></b-skeleton-img>
                        <AvailabilityHours :availability="availabilityWeekday[5]" :dayWeek="5" v-else/>
                    </b-tab>
                    <b-tab title="Sabado" :title-link-class="linkClass(6)" :key="6">
                        <b-skeleton-img no-aspect height="150px" v-if="loading"></b-skeleton-img>
                        <AvailabilityHours :availability="availabilityWeekday[6]" :dayWeek="6" v-else/>
                    </b-tab>
                </b-tabs>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import RowProfile from '../../containers/RowProfile'
import AvailabilityHours from '../../containers/ConfigProfile/AvailabilityHours'
import { mapState, mapActions } from 'vuex'
// import axios from "axios"
// import { baseApiUrl, userKey } from "@/global"

export default {
    name:'ConfigAvailability',
    components:{RowProfile, AvailabilityHours},
    computed:{
        freelancer(){
            return this.$store.state.freelancer
        },
        availabilityWeekday(){
            return this.$store.state.availability.availabilityWeekday
        }
    },
    data(){
        return{
            freeAvailability:'',
            profile:{},
            selected:[],
            selectedHour:[],
            tabIndex:0,
            loading:true,
            options:[
                { text: 'Segunda-feira', value: '1' },
                { text: 'Terça-feira', value: '2' },
                { text: 'Quanta-feira', value: '3' },
                { text: 'Quinta-feira', value: '4' },
                { text: 'Sexta-feira', value: '5' },
                { text: 'Sabado', value: '6' },
                { text: 'Domingo', value: '0' },
            ],
            hours:[
                { text: '00:00', value: '00:00' },
                { text: '01:00', value: '01:00' },
                { text: '02:00', value: '02:00' },
                { text: '03:00', value: '03:00' },
                { text: '04:00', value: '04:00' },
                { text: '05:00', value: '05:00' },
                { text: '06:00', value: '06:00' },
                { text: '07:00', value: '07:00' },
                { text: '08:00', value: '08:00' },
                { text: '09:00', value: '09:00' },
                { text: '10:00', value: '10:00' },
                { text: '11:00', value: '11:00' },
                { text: '12:00', value: '12:00' },
                { text: '13:00', value: '13:00' },
                { text: '14:00', value: '14:00' },
                { text: '15:00', value: '15:00' },
                { text: '16:00', value: '16:00' },
                { text: '17:00', value: '17:00' },
                { text: '18:00', value: '18:00' },
                { text: '19:00', value: '19:00' },
                { text: '20:00', value: '20:00' },
                { text: '21:00', value: '21:00' },
                { text: '22:00', value: '22:00' },
                { text: '23:00', value: '23:00' },
            ]
        }
    },
    methods: {
        ...mapActions('availability',['setAvailabilityWeekday']),
        
        async getAvailability(){
            try{
                await this.setAvailabilityWeekday(this.freelancer.free_availability)

            }catch(e){
                console.log(e)
            }finally{
                this.loading=false
            }
            // const url = `${baseApiUrl}/availability/${this.freelancer.id_user}`
            // axios.get(url).then(res => {
            //     this.freeAvailability = res.data.availability
            //     // console.log('estou aqui', res.data)
            //     if(this.freeAvailability.length > 0 ){
            //         this.setAvailabilityWeekday(this.freeAvailability)
            //         this.$store.commit('setData', this.freeAvailability)
            //     }
            // }).finally(this.loading=false)
        },

        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['bg-primary', 'text-light']
            } else {
             return ['bg-light', 'text-info']
            }
        }
    },
    created() {
        this.getAvailability()
    },
}
</script>
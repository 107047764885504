<template>
    <div class="message" dashboard>
        <Title
            name="Mensagens"
        />
        <b-card title="Card Title" no-body class="mr-3 my-3">
            <b-card-header header-tag="nav" card-headerr>
                <b-nav class="d-md-flex d-none">
                    <!--path: '/perfil/:id/home' -->
                    <b-nav-item 
                        :to="{path: '/message'}" 
                        active-class="active" 
                        exact
                    >
                        CONVERSAS
                    </b-nav-item>
                    <div class="border-vertical"></div>
                    <b-nav-item 
                        :to="{path:'/message/groups'}" 
                        active-class="active" 
                        exact
                    >
                        GRUPOS
                    </b-nav-item>
                    <div class="border-vertical"></div>
                    <b-nav-item 
                        :to="{path:'/message/invite'}" 
                        active-class="active" 
                        exact
                    >
                        CONVITE
                    </b-nav-item>
                    <b-nav-item 
                       :to="{path:'/message/friends'}" 
                        active-class="active" 
                        exact
                    >
                        AMIGOS
                    </b-nav-item>
                    <div class="border-vertical"></div>
                </b-nav>
                <b-nav class="d-md-none d-flex">              
                    <b-nav-item-dropdown
                        id="carreiras-nav-dropdown"
                        :text="`${$route.name}`"
                        toggle-class="nav-link-custom"
                        @click.prevent="status = !status"
                        active-class="active"
                    >
                        <b-dropdown-item>
                            <b-nav-item 
                                :to="{path: '/message'}"
                                exact
                            >
                                CONVERSAS
                            </b-nav-item>
                        </b-dropdown-item>
                        <b-dropdown-item>
                            <b-nav-item 
                                :to="{path:'/message/groups'}"
                                exact
                            >
                                GRUPOS
                            </b-nav-item>
                        </b-dropdown-item>
                        <b-dropdown-item>
                            <b-nav-item 
                                :to="{path:'/message/invite'}"
                                exact
                            >
                                CONVITE
                            </b-nav-item>
                        </b-dropdown-item>
                        <b-dropdown-item>
                            <b-nav-item 
                                :to="{path:'/message/friends'}"
                                exact
                            >
                                AMIGOS
                            </b-nav-item>
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                    <i class="fa fa-chevron-up icon-dropdown" aria-hidden="true" style="margin-top:0.3rem"></i>
                </b-nav>
            </b-card-header>
            <b-card-body class="p-0">
                <transition mode="out-in"
                    enter-active-class="animate__animated animate__fadeIn"
                    leave-active-class="animate__animated animate__fadeOut"
                >
                    <router-view/>
                </transition>
            </b-card-body>
        </b-card>
    </div>
</template>
<script>
import Title from '../components/containers/Title'
export default {
    name: 'Message',
    components:{Title}
}
</script>
<style>
.card-header{
    background-color: #fff !important;
}
</style>
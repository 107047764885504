<template>
    <div class="SalesHistoric">
        <b-skeleton-img v-if="this.loading === false"></b-skeleton-img>
        <div v-if="this.sales != ''">
            <div v-for="sale in sales" :key="sale.index">
                <Shedule
                    :icone=sale.service.icon
                    :service=sale.service.name
                    :description=sale.service.description
                    :id=sale.id
                    :who_for=sale.who_for.name
                    :avatar=sale.who_for.avatar
                    :start=sale.start
                    :data="sale.datetime | formatDate"
                    :end_data=sale.end_time
                    :type=userType
                />
            </div>
        </div>
        <NotSet v-else/>
    </div>
</template>
<script>
import NotSet from '../containers/NotSet'
import Shedule from '../containers/Shedule'
import {baseApiUrl,showError, userKey} from '@/global'
import axios from 'axios'
import {mapState} from 'vuex'
export default {
    name:'SalesHistoric',
    components:{Shedule, NotSet},
    computed:mapState(['user']),
    data (){
        return{
            sales:null,
            loading:false,
            userType: ''
        }
    },
    methods: {
        get(){
            const url =  `${baseApiUrl}/sales/historic`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res =>{
                if(res.data.sales){
                    this.sales = res.data.sales
                    this.userType = this.user.type
                }
            }).catch(showError).finally(()=>this.loading = true)
        }
    },
    created() {
        this.get()
    }
}
</script>
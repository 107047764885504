import {baseApiUrl,showError, userKey} from '@/global'
import axios from 'axios'
export default {
    state:{
        list:[],
        clients:[],
        member:[],
        tags:[]
    },
    mutations:{
        // pegando os chat's de group, se tiver
        loadList(state, client){
            state.list = client
        },
        //pegando os clientes
        loadClients(state, clients){
            state.clients = clients
        },
        //Adicionado usuario na lista de novo grupo e removendo da lista clients
        putGroup(state, params){
            //adicionando no array para envio no post 
            state.member.unshift(params.id)
            //adicionando na tag
            state.tags.unshift(params)
            //removendo da do clients
            let id = state.clients.filter(c => c.id == params.id)
            //console.log('Aqui id putGroup>> ', id)
            let removeClient = state.clients.filter(e=> e.id != id[0].id)
            //state.clients.splice(id, 1)
            state.clients = removeClient
        },
        //retornando para o estado inicial as tags e os membros(POST)
        reload(state){
            state.tags = []
            state.member = []
        },
        remove(state, params){
            let id = state.tags.filter(c => c.id == params.id)
            //console.log('Aqui id remove>> ', id[0].id)
            let removeTag = state.tags.filter(e =>  e.id != id[0].id)
            let removeMember = state.member.filter(e =>  e.id != id[0].id)
            state.tags = removeTag
            state.member = removeMember
            /*
            state.tags.splice(id,1)
            state.member.splice(id,1)
            */
            state.clients.unshift(params)

        }
    },
    actions:{
        //pegando possivel grupo do freelancer
        getChatGroup(context){
            const url =  `${baseApiUrl}/group`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res =>{
                if(!res.data.error){
                    context.commit('loadList', res.data)
                }
            })
        },
        // Pegando clients do freelancer
        getClients(context){
            const url =  `${baseApiUrl}/clients`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res =>{
                if(!res.data.error){
                    context.commit('loadClients', res.data.clients)
                }
            })
        },
        //colocando no grupo(na criação)
        putInGroup(context, params){
            context.commit('putGroup', params)
        },
        //removendo do grupo(na criação)
        removePut(context, params){
            context.commit('remove', params)
        },
        //Criando grupo
        createGroup(conxtet, grupo){
            const url =  `${baseApiUrl}/group`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.post(url, grupo).then(res =>{
                console.log(res)
                
                /*if(!res.data.error){
                    context.commit('loadClients', res.data.clients)
                }*/
            })
        }
    }
}
import {baseApiUrl,showError, userKey} from '@/global'
import {mapState,mapActions, mapGetters} from 'vuex'
import axios from 'axios'
export default {
    data(){
        return{
            service:{},
            perfil:{},
            loading:false
        }
    },
    computed:{
        ...mapState(['user', 'profile',['dataProfile']]),
        ...mapGetters('profile', ['services', 'testimonials', 'testimonialsLength', 'mediaReviews', 'certificates', 'checkRequest'])
    },
    methods: {
        ...mapActions('profile', ['setDataProfile']),
        requestGetProfile(){
            const url =  `${baseApiUrl}/freelancer/${this.perfil.id}`
            // const auth = JSON.parse(localStorage.getItem(userKey))
            // axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res =>{
                this.setDataProfile(res.data.free)                
            }).catch(e => showError(e)).finally(() =>this.loading = true)
        },
        get(){
            if(this.checkRequest(this.$route.params.id))
            {
                this.requestGetProfile()
            }else{
                this.loading = true
            }
           
        },
        getService(services, index){
            this.setsevice = {...services}
            //this.modal.splice(index, 2)f
        }
    },
    created() {
        this.perfil.id = this.$route.params.id
        this.get()
    }
}
<template>
    <div class="title mb-2 controlsm-title">
        <a href="javascript:history.back()">
            <svg bg-marlon-secondary  width="1.5rem" height="1.5rem" viewBox="0 0 12 10" fill="#eee" class="bi bi-arrow-left" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.191817 5.46686L4.52544 9.80058C4.64914 9.92429 4.81402 9.99219 4.98982 9.99219C5.16582 9.99219 5.3306 9.92419 5.4543 9.80058L5.84776 9.40702C5.97137 9.28351 6.03947 9.11854 6.03947 8.94263C6.03947 8.76683 5.97137 8.5963 5.84776 8.47278L3.31959 5.93905L11.3517 5.93905C11.7139 5.93905 12 5.65554 12 5.2933L12 4.73691C12 4.37467 11.7139 4.06258 11.3517 4.06258L3.29091 4.06258L5.84767 1.5147C5.97128 1.39099 6.03937 1.2305 6.03937 1.0546C6.03937 0.878896 5.97128 0.716068 5.84767 0.592459L5.45421 0.20017C5.3305 0.0764641 5.16572 0.00904977 4.98972 0.00904976C4.81392 0.00904974 4.64904 0.0773414 4.52534 0.201047L0.19172 4.53467C0.0677209 4.65877 -0.000473524 4.82442 1.37878e-05 5.00052C-0.00037628 5.1772 0.0677208 5.34296 0.191817 5.46686Z" fill="#FEFEFE"/>
            </svg>
        </a>
        <b class="h5 font-weight-bolder ml-2">{{name}}</b>
    </div>
</template>
<script>
export default {
    name:'Title',
    props:['name']
}
</script>
<style scoped>
.title{
    display: flex;
    flex-flow: nowrap;
}
.bi-arrow-left{
    border-radius:0.35rem !important;
    padding: 0.35rem !important;
}
@media (max-width: 991px){
    .controlsm-title{
        position: relative;
        margin-top: -2.2rem !important;
        z-index:1004 !important;
        margin-bottom: 2.1rem !important;
    }
}
</style>
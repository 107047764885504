//import {baseApiUrl,showError, userKey} from '@/global'
//import axios from 'axios'
import {baseApiUrl,showError, userKey, baseUrl} from '@/global'
export default {
    namespaced:true,
    state:{
        setData: {
            status:null
        }
    },
    mutations:{
        //Sempre verificar
        setStatus(state, payload){
            state.setData.status = payload
        }
    },
    actions:{
        verifyStatus(context){
            // if(this.$route.name != 'auth'){
                const local = JSON.parse(localStorage.getItem(userKey))
                if(local && local.terms){
                    console.log('ACHEI O TERMS')
                    context.commit('setStatus', local.terms)
                }else{
                    console.log('NÂO ACHEI TERMS ')
                    context.commit('setStatus', null)
                }
            // }
        },
        configStatus(context, params){
            const local = JSON.parse(localStorage.getItem(userKey))
            local.terms = params

            localStorage.setItem(userKey, JSON.stringify(local))
            context.commit('setStatus', params)
        }
    }
}
<template>
    <div>
        <b-alert
            v-model="showTop"
            class="m-0 text-center position-fixed fixed-top rounded-0"
            style="z-index: 2000;"
            variant="danger"
            dismissible
        >
            {{error.date}}
        </b-alert>
        <b-overlay :show="show" rounded="sm">
            <div v-if="mode === 'start'" style="margin-top: -10px !important;">
                <b-row class="text-center border-bottom">
                    <b-col class="justify-content-center">
                        <img :src="img" class="p-2 rounded mr3" width="50" height="50" :alt="name" :srcset=img bg-mprimary>
                        <h6 class="font-weight-bold">{{name}}</h6>
                        <p>{{descript}}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mt-3 d-flex">
                        <img :src="imgConsultor" class="p-2 rounded mr3" width="50" height="50" :alt=consultor :srcset=imgConsultor>
                        <p class="font-weight-bold"> Agende um horário com {{consultor}} para contratar os serviço: {{name}}</p>
                    </b-col>
                </b-row>
                <p class="text-muted"> Tempo necessário para consultoria 3 horas</p>
                <b-row>
                    <b-col bg-mprimary>
                        <h4 class="text-light"> <b>{{ context.activeFormatted }}</b></h4>
                        <!-- <div v-if="this.selected_date != ''" >
                            <b-time
                                locale="pt-BR"
                                id="ex-disabled-readonly"
                                v-model="hour"
                                minutes-step="5"
                            ></b-time>

                            {{ AQUI VAI VIM A LISTA DE HORARIOS }}
                           .
                        </div> -->
                        <div class="hours-availability" v-if="selectHours && selectHours.status != 0">
                            <b-form-checkbox-group
                                v-model="hour"
                                :options="hoursEdit"
                                plain
                                stacked
                            ></b-form-checkbox-group>
                        </div>
                        <div class="hours-not-availability" v-else>Não tem disponibilidade nesse dia</div>
                    </b-col>
                    <b-col md="auto">
                        <b-calendar 
                            v-if="mode === 'start'"
                            v-model="selected_date" 
                            @context="onContext" 
                            locale="pt-BR"
                            nav-button-variant="primarym"
                            today-variant="primarym"
                            selected-variant="success"
                        ></b-calendar>
                    </b-col>
                </b-row>
                <b-row align-h="end" class="m-2">
                    <b-col cols="6">
                        <b-button
                            :disabled="this.hour === ''"
                            @click.prevent="setPurchase"  
                            v-if="this.mode === 'start'" 
                            size="sm" style="border-radius:0.4rem !important" 
                            bg-mprimary 
                            class="px-4" 
                            v-b-modal.modal-center
                        >
                            CONTINUA
                            <svg style="display:inline-block;vertical-align: bottom" xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="ml-3 bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                            </svg>
                        </b-button>
                    </b-col>
                </b-row>
            </div>  
            <div v-if="mode === 'confirm'  && this.data.payment != 'card'">
                <b-row aling-h="center" class="text-center" v-if="loading === true">
                    <b-col cols="12">
                        <b-spinner variant="primary" label="Text Centered"></b-spinner>
                        <strong>Loading...</strong>
                    </b-col>
                </b-row>
                <b-row v-if="loading === false" class="border rounded bg-light">
                    <b-col cols="12" md="8">
                        <h1 class="h6 font-weight-bold">{{name}}</h1>
                        <div class="d-flex">
                            <img :src="img" class="p-2 mr-3 rounded" width="50" height="50" :alt=name :srcset=img bg-mprimary>
                            <div class="">
                                <p><s>{{previous}},00</s></p>
                                <p class="font-weight-bold">R$ {{price}},00</p>
                            </div>
                        </div>
                        <div class="mt-2 d-flex">
                            <img :src="imgConsultor" class="mr-2 rounded-circle" width="25" height="25" :alt=name :srcset=imgConsultor bg-mprimary>
                            <p> Fornecido por: <b>{{consultor}}</b></p>
                        </div>
                    </b-col>
                    <b-col sm="6" md="4" class="text-right">
                        <p class="text-muted">Agendado para:</p>
                        <p>{{selected_date | formatDate}}</p>
                    </b-col>
                </b-row>
                <h2 class="mt-4 h5 " v-if="loading === false">Forma de pagamento</h2>
                <b-row align-h="around" class="mt-2" v-if="loading === false">
                    <input type="radio" name="" style="display:none;" id="teste" value="boleto" v-model="data.payment">
                    <input type="radio" name="" style="display:none;" id="teste2" value="card" v-model="data.payment">
                
                    <label :class="{'selectSer' : this.data.payment == 'boleto'}" cols="4" for="teste" class="px-3 border rounded bg-light tex-center select">
                        <div class="d-flex">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="5rem" height="5rem" fill="CurrentColor" viewBox="0 0 297 297" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 297 297">
                                <rect width="33" y="49.5" height="198"/>
                                <rect width="33" x="83" y="49.5" height="198"/>
                                <rect width="33" x="133" y="49.5" height="198"/>
                                <rect width="49" x="248" y="49.5" height="198"/>
                                <rect width="16" x="50" y="49.5" height="198"/>
                                <rect width="16" x="182" y="49.5" height="198"/>
                                <rect width="16" x="215" y="49.5" height="198"/>
                            </svg>
                            <div class="ml-3 align-self-center">
                                <p class=""> Boleto</p>
                            </div>
                        </div>
                    </label>
                    <label :class="{'selectSer' : this.data.payment == 'card'}" for="teste2" cols="4" class="px-3 border rounded select bg-light" >
                        <div class="d-flex align-items-center">
                        <svg version="1.1" id="Capa_1" fill="CurrentColor" width="5rem" height="5rem" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 477.867 477.867" style="enable-background:new 0 0 477.867 477.867;" xml:space="preserve">
                                <path d="M426.667,68.267H51.2c-28.277,0-51.2,22.923-51.2,51.2V358.4c0,28.277,22.923,51.2,51.2,51.2h375.467
                                    c28.277,0,51.2-22.923,51.2-51.2V119.467C477.867,91.19,454.944,68.267,426.667,68.267z M443.733,358.4
                                    c0,9.426-7.641,17.067-17.067,17.067H51.2c-9.426,0-17.067-7.641-17.067-17.067V256h409.6V358.4z M443.733,221.867h-409.6v-34.133
                                    h409.6V221.867z M443.733,153.6h-409.6v-34.133c0-9.426,7.641-17.067,17.067-17.067h375.467c9.426,0,17.067,7.641,17.067,17.067
                                    V153.6z"/>

                                <path d="M392.533,290.133h-68.267c-9.426,0-17.067,7.641-17.067,17.067s7.641,17.067,17.067,17.067h68.267
                                    c9.426,0,17.067-7.641,17.067-17.067S401.959,290.133,392.533,290.133z"/>
                                
                            </svg>
                            <div class="ml-3 align-self-center">
                                <p class=""> Cartão</p>
                            </div>
                        </div>
                    </label>
                </b-row>
                <div class="form-group" v-if="this.data.payment == 'boleto'">
                    <label for="cpf">CPF *</label>
                    <input 
                        class="form-control form-control-lg" 
                        :class="{'border border-danger' : error.cpf != 0 }" 
                        v-model="data.cpf" 
                        id="cpf" 
                        placeholder="000.000.000-00"
                        v-mask="'###.###.###-##'"
                    >
                    <span class="text-danger">
                        {{error.cpf}}
                    </span>
                </div>
                <b-row align-h="between" v-if="loading === false">
                    <b-col >
                        <b-button
                            :disabled="this.data.payment == ''"
                            @click.prevent="back"
                            size="sm" style="border-radius:0.4rem !important" 
                            bg-secondary 
                            class="px-4" 
                            v-b-modal.modal-center
                        >
                            <svg style="display:inline-block;vertical-align: bottom; transform:rotate(180deg);" xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="ml-3 bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                            </svg>
                            Volta
                            
                        </b-button>
                    </b-col>
                    <b-col>
                        <b-button
                            v-if="this.data.payment == ''"
                            :disabled="this.data.payment == ''"
                            @click.prevent="SendPurchase"
                            size="sm" style="border-radius:0.4rem !important" 
                            bg-mprimary 
                            class="px-4" 
                            v-b-modal.modal-center
                        >
                            CONTINUA
                            <svg style="display:inline-block;vertical-align: bottom" xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="ml-3 bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                            </svg>
                        </b-button>
                        <b-button
                            v-if="this.data.payment == 'boleto'"
                            :disabled="this.data.payment == ''"
                            @click.prevent="SendBillet"
                            size="sm" style="border-radius:0.4rem !important" 
                            bg-mprimary 
                            class="px-4" 
                            v-b-modal.modal-center
                        >
                            Gerar Boleto
                            <svg style="display:inline-block;vertical-align: bottom" xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="ml-3 bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                            </svg>
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <div class="m-5 text-center" v-if="this.mode == 'billet'">
                <h5 class="h1">Boleto gerado !</h5>
                <p class="my-3 text-muted">Seu boleto foi gerado em uma nova abam caso isso não tenha acontecido, clique
                    <a :href=this.data.urlBillet target="_blank" rel="noopener noreferrer"> aqui</a>
                    para abrir novamente o boleto
                </p>
                <p class="text-font-weight-bolder px-md-5">Seu pedido está disponível na aba Minhas Compras.</p>
            </div>
            <!-- MODO CARTÂO -->
            <Card
                v-if="
                this.data.payment == 'card' && this.mode != 'cardSucesso' "
                :data=this.data
                :installments=this.installments
                @setData="mode = $event"
            />
            <div class="m-5 text-center" v-if="this.mode == 'cardSucesso'">
                <h5 class="h1">Compra Realizada com sucesso!</h5>
                <p class="my-3 text-muted">Codigo da transação - {{data.code}}.Já notificamos o freelancer... ele entrará em contato em breve fica alerta!
                </p>
                <p class="text-font-weight-bolder px-md-5">Seu pedido está disponível na aba Minhas Compras.</p>
                <p class="text-font-weight-bolder px-md-5">Se tiver algum problema entre em contato com nosso suporte.</p>
            </div>       
        </b-overlay>
    </div>
</template>
<script>
import Payment from '../../mixins/PagSeguro/Payment'
import {baseApiUrl,showError, userKey} from '@/global'
import {mapState,mapGetters} from 'vuex'
import axios from 'axios'
import Card from './Purchase/card'
export default {
    name:'Purchase',
    computed:{
        ...mapState('profile', ['dataProfile'] ),
        ...mapGetters('profile', ['availabilityId', 'hoursAvailability'])
    },
    props:['img','imgConsultor', 'descript', 'name', 'price', 'consultor', 'id', 'previous'],
    components:{Card},
    data() {
        return {
            paymentMethod:{
                data: ''
            },
            selected_date: '',
            context: '',
            mode: 'start',
            dateData:'',
            hour:[],
            data:{},
            loading:false,
            urlBillet:'',
            error:{cpf:'',date:'',card: ''},
            showTop:false,
            show:false,
            installments:[],
            hoursEdit:null,
            selectHours:""
        }
    },
    methods: {
        dataNow(){
            let data  = new Date()
            let day = String(data.getDate()).padStart(2,'0');
            let month = String(data.getMonth()+1).padStart(2, '0')
            let year = data.getFullYear()
            this.context = day + '/' + month +'/'+ year
        },
        scriptPagSeguro(){
            // Carregar o script do PagSeguro de forma assíncrona
            const scriptpagdirect = document.createElement('script');
            scriptpagdirect.src = 'https://stc.sandbox.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js';
            scriptpagdirect.type='text/javascript';
            scriptpagdirect.async = true;

            const script = document.createElement('script');
            script.src = 'https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js';
            script.async = true;


            
            document.head.appendChild(scriptpagdirect);
            document.head.appendChild(script);
        },
        onContext(ctx) {
            this.context = ctx;

            this.selectHours = this.availabilityId(this.context.activeYMD)
            this.hoursEdit = this.selectHours != undefined ? this.hoursAvailability(this.selectHours.hour, this.selectHours.weekday) : []
            console.log(this.context.activeYMD)
            console.log('ESTOU NO onContext',this.availabilityId(this.context.activeYMD) )
        },
        back(){
            this.mode = 'start'
        },
        setPurchase(){
            this.data = {
                hour: this.hour,
                service: this.id,
                date: this.selected_date,
                service_name:this.name,
                service_price: this.price.replace(/[.]+/g,"").trim(),
                id_freelancer:this.dataProfile.id_user,
                payment: ''
            }
            this.installments = [
                'Á vista - 1x de R$'+this.price,
                '2x R$'+(this.data.service_price/2).toFixed(2).replace(".",",")+' sem juros',
                '3x R$'+(this.data.service_price/3).toFixed(2).replace(".",",")+' sem juros',
                '4x R$'+(this.data.service_price/4).toFixed(2).replace(".",",")+' sem juros',
                '5x R$'+(this.data.service_price/5).toFixed(2).replace(".",",")+' sem juros',
                '6x R$'+(this.data.service_price/6).toFixed(2).replace(".",",")+' sem juros'
            ]

            this.pagSeguro()
            this.mode = 'confirm'
        },
        //ABSOLETO - essa requisiação vai ser feita internamente
        SendPurchase(){
            this.loading = true
            const url =  `${baseApiUrl}/shedules/${this.dataProfile.id_user}`
            // const auth = JSON.parse(localStorage.getItem(userKey))
            // axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.post(url, this.data).then(res =>{
                if(res.data.success){
                   this.$toasted.success(res.data.success, {
                        theme: "toasted-primary",
                        position: "top-center", 
                        duration : 5000,
                        icon:'check_circle'
                    })
                    this.$router.go()
                }else{
                    showError(res.data.error)
                }
            }).catch(showError).finally(this.loading = false)
        },
        /**
         * PagSeguro
         */
        getPaymentMethods(){
            PagSeguroDirectPayment.getPaymentMethods({
                amount: 500.00,
                success: function(response) {
                    // Retorna os meios de pagamento disponíveis.
                    console.log(response.paymentMethods)
                    this.paymentMethod.data = response
                                       
                },
                error: function(response) {
                    // Callback para chamadas que falharam.
                    console.log(response)
                },
                complete: function(response) {
                    // Callback para todas chamadas.
                    this.show = false
                }
            })
        },
        pagSeguro(){
            this.show = true
            const url =  `${baseApiUrl}/pagseguro`
            // const auth = JSON.parse(localStorage.getItem(userKey))
            // axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res =>{
                PagSeguroDirectPayment.setSessionId(res.data)
                this.getPaymentMethods()
                this.show = false 
            }).catch(error =>console.log(error))
        },
        validate(){
            var error = 0;
            //erro de email
            if(this.data.cpf != undefined){
                if(this.data.cpf.length < 11){
                    showError("Cpf incompleto")
                    this.error.cpf = "faltado números"
                    error++
                }else{
                    this.error.cpf = ''
                }
            }else{
                showError("Campo cpf precisa ser preenchido")
                this.error.cpf = "Campo obrigatorio !"
                error++
            }
            // 3 dias para pode gera boleto
            let nowD = new Date().getTime();
            let digitada = new Date(this.data.date).getTime()
            let limit = digitada - nowD
            if(limit < 259200000){
                this.showTop = true
                showError("Pagamento boleto necessita de 3 dias!")
                this.error.date = 'Volte e selecione uma data valida para compra no boleto.'
                error++
            }
            return (error === 0)           
        },
        /**
         * Pagamento boleto
         */
        SendBillet(){
            if(this.validate()){
                this.show = true
                this.data.senderHash = PagSeguroDirectPayment.getSenderHash()
                const url =  `${baseApiUrl}/pagseguro-billet`
                const auth = JSON.parse(localStorage.getItem(userKey))
                axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
                axios.post(url,this.data)
                    .then(res =>{
                        console.log(res.data)
                        this.mode = 'billet'
                        this.data.urlBillet = res.data.paymentLink
                        this.data.code = res.data.code
                        this.show = false
                }).catch(error =>console.log(error))
            }
        }
    },
    watch:{
        selected_date(novo, antigo){
            this.$emit('setData', novo)
        },
        dataPayment(n,a){
            console.log(a, n)
        }
    },
    mounted() {
        this.dataNow()
        this.scriptPagSeguro()
    },
}
</script>
<style>
    .select{
        cursor: pointer;
    }
    .select:hover{
        border:3px solid #4855BB !important;
    }
    .selectSer{
        border:3px solid #4855BB !important;
        color:#4855BB !important;
    }
</style>
import axios from 'axios'
import { baseApiUrl, userKey } from '@/global'
import {debounce } from 'lodash'
import { mapState } from 'vuex'
export default {
    computed:mapState(['systemService']),
    data() {
        return {
            perfils:[],
            searchHard:[],
            test:[],
            list:["nome", "Reputação"],
            search:{
                rows:100,
                currentPage:3
            },
            loading: true,
            filter:{
                name:'',
                page:'',
                order:''
            }
        }
    },
    watch:{
        filter:{
            handler(){
                this.debounceUsers()
            },
            deep:true
        },
        searchHard:{
            handler(e,era){
                this.debounceSearch(e,era)
            },
            deep:true 
        }
    },
    methods: {
        numberALLLLLL(value){
            let filt = this.searchHard.includes(value.services_id) === true ? true : false
            return filt
            
        },
        filterArray(arr){
            for(let i=0;i<arr.length;i++){
                let filt = arr[i].services.filter(this.numberALLLLLL)
                if(filt.length > 0){
                    
                    this.test.push(arr[i])
              
                }
            }
        },
        debounceUsers: debounce(function(){
            this.loading = true
            if(this.searchHard.length == 0){
                this.getAll()
            }
        },300),
        debounceSearch: debounce(function(e,era){
            if(this.searchHard.length >= 1){
                this.test = []

                this.filterArray(this.perfils)
                
                this.perfils = this.test
                console.log('>>>',this.perfils)
            }else{
                this.loading = true
                this.getAll()
               
                console.log('vamos beber')
            }
        },300),
        getAll(){
            const query = Object.keys(this.filter).map((key) =>{
                return `${encodeURIComponent(key)}=${encodeURIComponent(this.filter[key])}`
            }).join('&')
            this.selectUser = query
            
            const url =  `${baseApiUrl}/freelancer?${query}`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res =>{
                if(this.filter.name){
                    this.filter.page = null
                }
                //console.log(res.data.allfreelancer.data)
                //console.log(res.data.allfreelancer)
                this.search.rows = res.data.allfreelancer.total*10
                //this.search.rows = res.data.allfreelancer.last_page
                this.perfils = res.data.allfreelancer
                
            }).finally(()=>this.loading = false)
        }
    },
    mounted() {
        this.getAll()
    },

}
<template>
    <div class="counterTime container border rounded p-5 mb-3 text-center" >
        <div class="" v-if="final === false">
            <div class="" v-if="!status">
                <p>Faltam {{temp}} para a reunião</p>
            </div>
            <div class="text-center" v-else>
                <p class="h5 font-weight-bolder">Está na hora da sua Consultoria</p>
                <b-button 
                    v-if="this.user.type === 'freelancers'"
                    bg-minfo
                    v-b-modal.modal-xl
                >
                    <p class="font-weight-bolder m-1" v-if="this.callExist != null">
                        Volta a reunião
                    </p>
                    <p class="font-weight-bolder m-1" v-else >
                       Inicar a reunião
                    </p>
                </b-button>
                <b-row align-h="center" v-else>
                    <input 
                        type="hidden"
                         v-model="call"
                    >
                    <b-col cols="6" v-if="this.call != null">
                        <b-button
                            bg-minfo
                            v-b-modal.modal-xl
                            class="font-weight-bolder m-1"
                        >
                            Ir a reunião
                        </b-button>
                    </b-col>
                    <b-col cols="8" v-else>
                        <p class="text-muted">Peça a o consultor que inicie a reunião.</p>
                    </b-col>
                </b-row>
            </div>
        </div>
        <div class="text-center" v-else>
            <b-row v-if="this.user.type === 'freelancers'">
                <b-col>
                    <p>Peça ao cliente que o avalie</p>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col>
                    <p>Serviço - Consta como prestado</p>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
    name:'CounterTime',
    props:['datetime', 'callExist', 'final'],
    computed:mapState(['user', 'callRoom']),
    data(){
        return{
            temp:'',
            call:this.callExist,
            meetTime: this.callExist == null ? new Date(this.datetime).getTime() : this.callExist,
            status: false
        }
    },watch:{
        call(antigo, novo){
            console,log(novo)
        }
    },
    methods: {
        go(){
            this.$toasted.show(res.data.success, {
                theme: "bg-mprimary",
            })
        },
        getDate(){
            //
            var dia, hora, minutos, segundos
            var current_date = new Date().getTime()

            var segundos_f = (this.meetTime - current_date) / 1000
            dia = parseInt(segundos_f /86400)
            segundos_f = segundos_f % 86400

            hora = parseInt(segundos_f /3600)
            segundos_f = segundos_f % 3600

            minutos = parseInt(segundos_f /60)
            segundos = parseInt(segundos_f %60)

            let hour = hora >= 10 ? hora : '0'+hora
            let minutes = minutos >= 10 ? minutos : '0'+minutos
            let seconds = segundos >= 10 ? segundos : '0'+segundos

            this.temp = dia+' dias - '+hour+'h:'+minutes+'m:'+seconds+'s'
            let now = Date.now()
            if(now >= this.meetTime){
                this.status = true
            }
        },
        showTime(){
            let timer = setInterval(this.getDate, 1000)
            let now = Date.now()
            console.log(now +' '+this.meetTime)
            if(now >= this.meetTime){
                this.status = true
                clearInterval(timer)
            }
        }
    },created() {
        this.showTime()
    },
}
</script>
<style>
    
</style>
<template>
     <div class="callvideo">
        <Title
            :name=sub_title
        />
        <div id="meet" class="container-fluid"></div>
    </div>
</template>
<script>
import Title from '../../containers/Title'
import {mapState} from 'vuex'
import {baseApiUrl,showError, userKey} from '@/global'
import axios from 'axios'
export default {
    name:'CallVideo',
    components:{Title},
    props:['room', 'id', 'sub_title'],
    computed:mapState(['user']),
    data(){
        return{
            SendRoom : {
                room : this.room
            }
        }
    },
    methods: {
        // Setando room no banco de dados e enviando notificação para o cliente
        Send(){
            const url = `${baseApiUrl}/shedules/${this.id}/up`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.put(url, {room: this.room}).then(res =>{
                if(res.data.success != undefined){
                    this.$toasted.show(res.data.success, {
                        className: "success",
                    })
                }else{
                    showError(res.data.error)
                }
            })
        },
        onIFrameLoad () {            
            const domain = 'meet.jit.si';
            const options = {
                roomName: this.room,
                parentNode: document.querySelector('#meet'),
                userInfo: {
                    email: this.user.email,
                    display: this.user.name,
                    displayName:this.user.name,
                    avatarURL:this.user.avatar
                },
                interfaceConfigOverwrite:{
                    // link logo carreiras.com https://sistema.carreiras.co/img/Logotipo..2b5f930a.png
                    DEFAULT_LOGO_URL : 'https://sistema.carreiras.co/img/Logotipo..2b5f930a.png',
                    //
                    DEFAULT_WELCOME_PAGE_LOGO_URL : 'https://sistema.carreiras.co/img/Logotipo..2b5f930a.png',
                    SHARING_FEATURES: [],
                    // Desativa o recurso de reações.
                    disableReactions: true,
                    // Desativa o recurso de enquetes.
                    disablePolls: false,
                    // Especifica se haverá um campo de pesquisa nas estatísticas de alto-falante ou não
                    disableSpeakerStatsSearch: false,
                    subject:this.sub_title,
                    conferenceInfo:{
                        autoHide:[
                            'roomName',
                            'subject',
                            'conference-timer'
                        ]
                    }
                }
            }
            const api = new JitsiMeetExternalAPI(domain, options);
            console.log(this.user.email,this.user.name, this.room, this.id)
        },
    },
    mounted() {
        this.onIFrameLoad()
        this.Send()
    },
}
</script>
<style>
.callvideo{
    height:90vh;
}
#meet{
    height:100%
}
</style>
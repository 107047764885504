<template>
    <div>
        <b-skeleton-img v-if="this.loading === false"></b-skeleton-img>
        <b-row align-h="between" class="pb-3 border-bottom">
            <b-col>
                <div class="d-flex">
                    <img :src="profile.dataProfile.avatar | imageURL" width="75" height="75" class="mr-2 rounded-lg"/>
                    <p class="font-weight-bolder">{{profile.dataProfile.presentation}}
                        <b-form-rating v-if="profile.dataProfile.testimonials.length > 0" :value="mediaReviews" readonly precision="1" no-border size="sm" variant="warning"></b-form-rating>
                        <b class="text-muted" v-if="profile.dataProfile.testimonials.length > 0">{{profile.dataProfile.testimonials.length}} avaliações</b>
                        <b class="text-muted" v-else>0 avaliações</b>
                    </p>
                </div>
            </b-col>
        </b-row>
        <b-row class="my-5 border-bottom">
            <b-col cols="12">
                <h1 class="h6 font-weight-bold"> RESUME</h1>
            </b-col>
            <b-col cols="12">
                <p class="text-muted">{{profile.dataProfile.about}}</p>
            </b-col>
        </b-row>
        <b-row class="py-3 my-5 border-bottom">
            <b-col cols="12">
                <h1 class="h6 font-weight-bold"> SETORES DE ENTENDIMENTO</h1>
            </b-col>
            <b-col cols="12">
                <span class="p-1 text-white rounded-pill" bg-mprimary>
                    TODOS
                </span>
            </b-col>
        </b-row>
        <b-row class="my-5 border-bottom">
            <b-col cols="12">
                <h1 class="h6 font-weight-bold"> SERVIÇOS</h1>
            </b-col>
        </b-row>
        <div class="mt-5 services">
        <b-row align-h="between" class="py-1 mt-3 border rounded" v-for="(item, index) in services" :key="index">
            <b-col align-self="start" >
                <div class="d-flex">
                    <div class="my-auto">
                        <img 
                            :src="item.icon | imageURL" 
                            class="p-2 mr-3 rounded"
                            width="50" 
                            height="50"
                            :bg-mprimary="item.id != 1" 
                            :bg-minfo="item.id == 1"
                        >
                    </div>
                    <div class="my-auto ml-3">
                        <b class="h5 font-weight-bolder"> {{item.name}}</b>
                        <p>{{item.resume}}</p>
                    </div>
                </div>
            </b-col>
            <b-col  md="3" offset-md="3" class="my-auto border-left" align-center="center">
                <s>R$ {{item.price}}, 00</s>
                <h6 class="h5 font-weight-bolder"> R$ {{item.price_now}}, 00</h6>
                <b-button
                    :class="{'d-none' : user.type != 'candidaters'}"
                    @click="getService(item,index)" 
                    size="sm" 
                    style="border-radius:0.4rem !important" 
                    bg-mprimary 
                    class="px-4" 
                    v-b-modal.modal-center
                >
                    COMPRAR
                    <svg style="display:inline-block;vertical-align: bottom" xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="ml-3 bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>
                </b-button>
            </b-col>
        </b-row>
            <b-modal 
                id="modal-center"
                centered
                hide-header
                hide-footer
                no-close-on-backdrop
            >
                <b-row align-h="between">
                    <b-col cols="2" @click="closeModal">
                        <svg bg-mprimary style="border-radius:0.4rem !important; transform:rotate(180deg);" xmlns="http://www.w3.org/2000/svg" width="2rem" height="1.5rem" fill="#eee" class="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                        </svg>
                    </b-col>
                    <b-col cols="2">
                        <span @click="closeModal" style="cursor:pointer !important;display:flex !important;justify-content:end !important">
                            <svg width="33" height="33" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11.5" fill="#FAFAFA"/>
                            <path d="M8 8L14.5 14.5" stroke="#292841" stroke-width="2"/>
                            <path d="M14.5 8L8 14.5" stroke="#292841" stroke-width="2"/>
                            </svg>
                            
                        </span>
                    </b-col>
                </b-row>
                <Purchase
                    @setData="data = $event"
                    :name=setsevice.name
                    :img="setsevice.icon  | imageBaseApi"
                    :price=setsevice.price_now
                    :consultor=profile.dataProfile.presentation
                    :descript=setsevice.description
                    :imgConsultor="profile.dataProfile.avatar | imageBaseApi"
                    :id=setsevice.id
                    :previous=setsevice.price
                />
            </b-modal>
    </div>
    </div>
</template>
<script>
import Purchase from '../containers/Purchase'
import Perfil from '../../mixins/Perfil'
export default {
    name:'PerfilHome',
    components:{Purchase},
    data() {
        return {
            
            setsevice:{},
            // availability:'',
            data:{},
            // loading:false,
        }
    },methods: {
        closeModal(){
            // tem que zera o v-model
            this.$bvModal.hide('modal-center')
        }
    },
    mixins:[Perfil]
}
</script>
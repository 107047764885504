<template>
    <div class="notifications bg-light py-3 px-4 border-bottom">
        <div class="d-flex">
            <div class="text-monospace notifica-size">
                <p v-if="status == 'accept'">Parabéns voce foi aprovado para presta o {{service}} !</p>
                <p v-else>Infelizmente você não foi aprovado.</p>
            </div>
        </div>
            <p class="text-right">{{time}}</p>
        <div class="d-flex justify-content-around">
            <router-link v-if="status == 'accept'" :to="{path: `/perfil/${id}/about`, params: {id: id}}" class="nav-mlink">
            ver
            </router-link>
            <a href="#" @click.prevent="markAsRead(notification_id)" class="nav-mlink">
                excluir
            </a>
        </div>
    </div>
</template>
<script>
import Audio from '../../../assets/notification.mp3'
export default {
    name:'Nrequest',
    props:[,'service','time', 'id', 'notification_id','status'],
    methods: {
        markAsRead(idNotification){
            this.$store.dispatch('maskAsRead', {id: idNotification})
        }
    },
}
</script>
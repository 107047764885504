<template>
    <div class="profile" dashboard>
      <Title
        name="Perfil"
      />
       <b-card title="Card Title" no-body class="mr-3 my-3">
            <b-card-header header-tag="nav" profile-card-headerr>
                <b-nav class="d-md-flex d-none">
                    <!--path: '/perfil/:id/home' -->
                    <b-nav-item 
                        :to="{path: '/Profile'}" 
                        active-class="active" 
                        exact
                    >
                        PERFIL
                    </b-nav-item>
                    <div class="profile-border-vertical"></div>
                    <b-nav-item 
                        :to="{path: '/Profile/availability'}" 
                        active-class="active" 
                        exact
                    >
                        DISPONIBILIDADE
                    </b-nav-item>
                    <div class="profile-border-vertical"></div>
                    <b-nav-item 
                        :to="{path:'/Profile/store'}" 
                        active-class="active" 
                        exact
                    >
                        HISTÓRICO
                    </b-nav-item>
                    <div class="profile-border-vertical"></div>
                    <b-nav-item 
                        :to="{path:'/Profile/certificates'}" 
                        active-class="active" 
                        exact
                    >
                        CERTIFICADOS
                    </b-nav-item>
                    <div class="profile-border-vertical"></div>
                </b-nav>
                <b-nav class="d-md-none d-flex">              
                    <b-nav-item-dropdown
                        id="profile-carreiras-nav-dropdown"
                        :text="`${$route.name}`"
                        toggle-class="nav-link-custom"
                        @click.prevent="status = !status"
                        active-class="active"
                    >
                        <b-dropdown-item>
                            <b-nav-item 
                                :to="{path: '/Profile'}"
                                exact
                            >
                                PERFIL 
                            </b-nav-item>
                        </b-dropdown-item>
                        <b-dropdown-item>
                            <b-nav-item 
                                :to="{path:'/Profile/store'}"
                                exact
                            >
                                HISTÓRICO
                            </b-nav-item>
                        </b-dropdown-item>
                        <b-dropdown-item>
                            <b-nav-item 
                                :to="{path:'/Profile/certificates'}"
                                exact
                            >
                                CERTIFICADOS
                            </b-nav-item>
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                    <i class="fa fa-chevron-up icon-dropdown" aria-hidden="true" style="margin-top:0.3rem"></i>
                </b-nav>
            </b-card-header>
            <b-card-body>
                <transition mode="out-in"
                    enter-active-class="animate__animated animate__fadeIn"
                    leave-active-class="animate__animated animate__fadeOut"
                >
                    <router-view/>
                </transition>
            </b-card-body>
        </b-card>
    </div>
</template>
<script>
import Title from '../components/containers/Title'
export default {
    name: 'Profile',
    components:{Title}

}
</script>
<style scoped>
.profile-border-vertical{
    height: 5vh;
    background: #ececec;
    width: 1px;
}
[profile-card-headerr]{
    background: #FFFFFF !important;
    border: 1px solid #ECECEC !important;
    box-sizing: border-box !important;
    border-radius: 6px 6px 0px 0px !important;
}
b-nav-item, li{
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #6978F4 !important;
}

.card-header{
    padding:0.75rem 0rem !important
}
.dropdown-menu .show{
    margin-top: 0.8rem !important
}
#profile-carreiras-nav-dropdown a.nav-link-custom{
    color:#007bff !important;
}
.nav-item a.router-link-exact-active,[active]{
    border: 0px 1px 0px 1px solid #ECECEC !important;
    color: #007bff !important;
}
.nav-item a[role="button"]{
    background: none !important;
}
.nav-item .b-nav-dropdown .dropdown .show{
    color: #007bff !important;
}
</style>
<template>
    <div class="header">
        <b-navbar toggleable="lg" type="white" class="controlsm-light">
            <b-navbar-toggle target="nav-collapse" class="ml-auto" style="z-index: 2000 !important;">
               <img src="@/assets/img/icons/menu-button.svg" alt="" id="button-mobile" class="mr-2 bg-light img-fluid" style="max-width: 1.2rem">
            </b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <div id="menu-overlay"></div>
                <b-navbar-nav class="nav-left" menu-mobile>
                    <b-navbar-brand class="ml-4">
                        <a class="navbar-brand custom-block" :href=site>
                            <img src="@/assets/img/Logotipo..png" alt="Logotipo" width="140">
                        </a>
                        <!--
                        <router-link class="custom-block" :href=site>
                            <img src="@/assets/img/Logotipo..png" alt="Logotipo" width="140">
                        </router-link>-->
                        <b-navbar-toggle target="nav-collapse" class="mobile-close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.3rem" target="nav-collapse" height="1.3rem" fill="currentColor" class="bi bi-x-lg icon" viewBox="0 0 16 16">
                                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
                            </svg>
                        </b-navbar-toggle>
                    </b-navbar-brand>
                    <b-nav-item class="pt-4 mt-3 control-border-top" v-if="user.type == 'freelancers'">
                            <router-link :to="{path:'/overview'}" active-class="active" exact class="nav-link">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1.3rem" height="1.3rem" fill="currentColor" class="bi bi-columns-gap icon" viewBox="0 0 16 16">
                                    <path d="M6 1v3H1V1h5zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm14 12v3h-5v-3h5zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5zM6 8v7H1V8h5zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H1zm14-6v7h-5V1h5zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1h-5z"/>
                                </svg>
                            Visão geral
                            </router-link>
                    </b-nav-item>
                    <b-nav-item :class="{'mt-3 border-top pt-4 control-border-top' : user.type != 'freelancers'}">
                            <router-link :to="{path:'/'}" class="nav-link" active-class="active" exact>
                            <svg version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"   width="1.3rem" height="1.3rem" fill="currentColor" class="icon" viewBox="0 0 481.291 481.291" style="enable-background:new 0 0 481.291 481.291;" xml:space="preserve">
                            <path d="M138.814,146.22c3.12,40.976,19.417,23.314,22.779,41.27c5.59,29.848,32.016,51.436,48.466,58.893
                                c9.597,4.348,19.48,6.53,29.871,6.704v0.009c0.036,0,0.072-0.004,0.108-0.004s0.072,0.004,0.108,0.004v-0.009
                                c10.39-0.174,20.272-2.355,29.871-6.704c16.45-7.457,42.876-29.045,48.466-58.893c3.362-17.955,19.659-0.294,22.779-41.27
                                c0-16.33-8.898-20.394-8.898-20.394s4.523-24.171,6.295-42.77C340.854,59.877,325.129,0,241.25,0
                                c-0.367,0-0.707,0.02-1.065,0.024c-0.013,0-0.025,0-0.038-0.001V0.019c-0.036,0.001-0.072,0.002-0.108,0.002
                                s-0.072-0.001-0.108-0.002v0.005c-0.013,0.001-0.025,0.001-0.038,0.001c-0.358-0.005-0.698-0.024-1.065-0.024
                                c-83.878,0-99.604,59.877-97.409,83.056c1.771,18.599,6.295,42.77,6.295,42.77S138.814,129.89,138.814,146.22z"/>
                            <path d="M430.313,347.371c-42.308-21.523-103.63-48.406-106.573-49.355c-0.033-0.012-0.058-0.014-0.09-0.024
                                c-1.721-0.613-3.571-0.953-5.504-0.953c-7.188,0-13.285,4.604-15.547,11.021c-0.012-0.008-0.024-0.013-0.036-0.021
                                c-10.92,26.315-30.979,73.223-43.434,95.842l-3-63.413c0,0,18.705-39.699,20.752-43.336c4.82-8.525,0.479-15.783-8.557-15.783
                                c-4.482,0-15.695,0-26.926,0c-0.013,0-0.025,0-0.038,0c-0.072,0-0.144,0-0.216,0c-0.013,0-0.025,0-0.038,0
                                c-0.154,0-0.308,0-0.462,0s-0.308,0-0.462,0c-0.013,0-0.025,0-0.038,0c-0.072,0-0.144,0-0.216,0c-0.013,0-0.025,0-0.038,0
                                c-11.23,0-22.442,0-26.926,0c-9.036,0-13.376,7.258-8.557,15.783c2.048,3.637,20.752,43.336,20.752,43.336l-2.999,63.413
                                c-12.455-22.619-32.514-69.524-43.434-95.842c-0.012,0.008-0.024,0.013-0.036,0.021c-2.262-6.414-8.359-11.021-15.547-11.021
                                c-1.933,0-3.783,0.34-5.504,0.953c-0.032,0.012-0.057,0.015-0.09,0.024c-2.942,0.949-64.265,27.832-106.573,49.355
                                c-19,9.666-30.467,21.688-30.467,34.34c0,41.826,0,99.58,0,99.58h219.227h0.191h0.105h0.006h0.107h0.191h0.309h0.309h0.191h0.105
                                h0.006h0.105h0.191H460.78c0,0,0-57.754,0-99.58C460.779,369.059,449.313,357.037,430.313,347.371z"/>
                            </svg> 
                                Consultores
                            </router-link>
                    </b-nav-item>
                    <b-nav-item v-if="user.type != 'freelancers'">
                        <router-link :to="{path:'/purchases'}" class="nav-link"  active-class="active">
                            <svg width="1.3rem" height="1.3rem" fill="currentColor" class="icon" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m406 225.5h-218.192c-5.31 0-10.224-2.807-12.92-7.381-8.228-13.952-23.472-22.619-39.782-22.619-19.396 0-35.9 12.577-41.814 30h27.708c8.284 0 15 6.716 15 15s-6.716 15-15 15h-27.47c12.846 36.218 62.243 40.668 81.443 7.487 2.681-4.634 7.629-7.487 12.983-7.487h218.044c8.271 0 15-6.729 15-15s-6.729-15-15-15z"/><path d="m91 375.5h120v30h-120z"/><path d="m467 106.5h-106v-76c0-8.284-6.716-15-15-15h-180c-8.284 0-15 6.716-15 15v76h-106c-24.813 0-45 20.187-45 45v300c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45v-300c0-24.813-20.187-45-45-45zm-286-61h150v61h-150zm269.95 360c0 6.882-4.683 12.881-11.359 14.552l-59.95 15c-9.485 2.371-18.641-4.821-18.641-14.552v-15h-120v15c0 8.284-6.716 15-15 15h-150c-8.284 0-15-6.716-15-15v-60c0-8.284 6.716-15 15-15h150c8.284 0 15 6.716 15 15v15h120v-15c0-9.748 9.169-16.917 18.641-14.552l59.95 15c6.676 1.671 11.359 7.67 11.359 14.552zm-44.95-120h-210.017c-14.064 18.696-36.317 30-59.983 30-41.405 0-75.05-33.645-75.05-75v-.845c0-40.89 33.266-74.155 74.155-74.155 23.883 0 46.396 11.307 60.657 30h210.238c24.813 0 45 20.187 45 45s-20.187 45-45 45z"/><path d="m391 401.284 29.95-7.493v-6.582l-29.95-7.493z"/></svg>
                            Minhas Compras
                        </router-link>
                    </b-nav-item>
                     <b-nav-item v-else>
                        <router-link :to="{path:'/sales'}" class="nav-link"  active-class="active">
                            <svg width="1.3rem" height="1.3rem" fill="currentColor" class="icon" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m406 225.5h-218.192c-5.31 0-10.224-2.807-12.92-7.381-8.228-13.952-23.472-22.619-39.782-22.619-19.396 0-35.9 12.577-41.814 30h27.708c8.284 0 15 6.716 15 15s-6.716 15-15 15h-27.47c12.846 36.218 62.243 40.668 81.443 7.487 2.681-4.634 7.629-7.487 12.983-7.487h218.044c8.271 0 15-6.729 15-15s-6.729-15-15-15z"/><path d="m91 375.5h120v30h-120z"/><path d="m467 106.5h-106v-76c0-8.284-6.716-15-15-15h-180c-8.284 0-15 6.716-15 15v76h-106c-24.813 0-45 20.187-45 45v300c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45v-300c0-24.813-20.187-45-45-45zm-286-61h150v61h-150zm269.95 360c0 6.882-4.683 12.881-11.359 14.552l-59.95 15c-9.485 2.371-18.641-4.821-18.641-14.552v-15h-120v15c0 8.284-6.716 15-15 15h-150c-8.284 0-15-6.716-15-15v-60c0-8.284 6.716-15 15-15h150c8.284 0 15 6.716 15 15v15h120v-15c0-9.748 9.169-16.917 18.641-14.552l59.95 15c6.676 1.671 11.359 7.67 11.359 14.552zm-44.95-120h-210.017c-14.064 18.696-36.317 30-59.983 30-41.405 0-75.05-33.645-75.05-75v-.845c0-40.89 33.266-74.155 74.155-74.155 23.883 0 46.396 11.307 60.657 30h210.238c24.813 0 45 20.187 45 45s-20.187 45-45 45z"/><path d="m391 401.284 29.95-7.493v-6.582l-29.95-7.493z"/></svg>
                            Minhas Vendas
                        </router-link>
                    </b-nav-item>
                    <b-nav-item>
                        <router-link :to="{path:'/message'}" class="nav-link"  active-class="active">
                            <svg width="1.3rem" height="1.3rem" fill="currentColor" class="icon" viewBox="-21 -47 682.66669 682" xmlns="http://www.w3.org/2000/svg"><path d="m552.011719-1.332031h-464.023438c-48.515625 0-87.988281 39.472656-87.988281 87.988281v283.972656c0 48.421875 39.300781 87.824219 87.675781 87.988282v128.871093l185.183594-128.859375h279.152344c48.515625 0 87.988281-39.472656 87.988281-88v-283.972656c0-48.515625-39.472656-87.988281-87.988281-87.988281zm-83.308594 330.011719h-297.40625v-37.5h297.40625zm0-80h-297.40625v-37.5h297.40625zm0-80h-297.40625v-37.5h297.40625zm0 0"/></svg>
                            Mensagens
                        </router-link>
                    </b-nav-item>
                    <b-nav-item>
                        <router-link :to="{path:'/NotPad'}" class="nav-link"  active-class="active">
                            <i class="fa fa-pencil-square-o icon fa-lg" aria-hidden="true"></i>
                            Editor de texto
                            <b-badge variant="dark" style="position:absolute !important">Demo</b-badge>
                        </router-link>
                    </b-nav-item>
                    <div class="items-navbar-mobile">
                        <router-link :to="{path:'/Profile'}" class="nav-link" active-class="active" v-if="user.type == 'freelancers'">
                            Meu Perfil
                        </router-link>
                        <router-link :to="{path:'/Config'}" class="nav-link" active-class="active">
                            Configuração
                        </router-link>
                        <router-link :to="{path:'/Help'}" class="nav-link" active-class="active">
                            Ajudar
                        </router-link>
                        <a href="#"  @click.prevent="logout" class="nav-link">
                            Sair
                        </a>
                    </div>
                </b-navbar-nav>
                <b-navbar-nav class="ml-auto">
                    <i class="m-auto fa fa-bell-o" aria-hidden="true" v-b-toggle.sidebar-right >
                        <b-badge 
                            v-if="items.length > 0"
                            bg-mprimary
                            pill
                        >{{items.length}}</b-badge>
                    </i>

                   <b-sidebar 
                        id="sidebar-right" 
                        title="Notificações"
                        backdrop-variant="primary"
                        backdrop
                        right 
                        shadow
                        footer="footer"
                        bg-variant="white">
                        
                        <div class="" v-if="items">
                            <div class="remove-all" @click="markAllAsRead">
                                Remove todas
                            </div>
                            <div v-for="notifications in items" :key="notifications.id">
                                <!-- NOTA: TEM QUE SE FEITO DE FORMA REUTILIZAVEL -->
                                <Notifications 
                                    v-if="notifications.type == 'App\\Notifications\\PurchaseScheduled' || notifications.type == 'App\\Notifications\\CallInitial' "
                                    :avatar="notifications.data.shedules.client != undefined ? notifications.data.shedules.client.avatar : notifications.data.shedules.freelancer.avatar"
                                    :name="notifications.data.shedules.client  != undefined ? notifications.data.shedules.client.name : notifications.data.shedules.freelancer.name"
                                    :serviceName="notifications.data.shedules.service.name "
                                    :id="notifications.data.shedules.id"
                                    :time="notifications.created_at | formathour"
                                    :notification_id=notifications.id
                                    :type=notifications.type
                                />
                                <Nrequest
                                    v-if="notifications.type == 'App\\Notifications\\RequestApproval'"
                                    :service="notifications.data.approval.service"
                                    :time="notifications.created_at | formathour"
                                    :id="notifications.data.approval.id"
                                    :notification_id="notifications.id"
                                    :status="notifications.data.approval.status"
                                />
                                <Ndisapproved
                                    v-if="notifications.type == 'App\\Notifications\\BlockService'"
                                    :service="notifications.data.reproval.name"
                                    :time="notifications.created_at | formathour"
                                    :notification_id="notifications.id"
                                />
                            </div>
                        </div>
                        <div class="px-3 py-2" v-else>
                            Não tem nofiticações
                        </div>
                    </b-sidebar>
                    <!--  Menu pessoal mobile   -->
                    <b-nav-item-dropdown left menu-end-mobile class="custom-block">
                        <template #button-content>
                            <view-avatar 
                                :imageAvatar="avatarView"
                                :name="user.name"
                            />
                        </template>
                        <div class="relative-box">
                        </div>
                        <b-dropdown-item href="#" v-if="user.type == 'freelancers'">
                            <router-link :to="{path:'/Profile'}" class="nav-link" active-class="active">
                                Meu Perfil
                            </router-link>
                        </b-dropdown-item>
                        <b-dropdown-item href="#">
                            <router-link :to="{path:'/Config'}" class="nav-link" active-class="active">
                                Configuração
                            </router-link>
                        </b-dropdown-item>
                        <b-dropdown-item href="#">
                            <router-link :to="{path:'/Help'}" class="nav-link" active-class="active">
                                Ajudar
                            </router-link>
                        </b-dropdown-item>
                        <a href="#"  @click.prevent="logout" class="pl-5 nav-link logout" active-class="active">
                            Sair
                        </a>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>
<script>
import Ndisapproved from '../containers/Notifications/Ndisapproved'
import Nrequest from '../containers/Notifications/Nrequest'
import Notifications from '../containers/Notifications'

import { mapState,mapGetters } from 'vuex'
import { baseApiUrl,showError,userKey, baseUrl,baseSite } from '@/global'
import axios from 'axios'
import ViewAvatar from '../containers/ViewAvatar.vue'
export default {
    name:'Navbar',
    computed: {
        ...mapState(['user', 'freelancer', 'candidate', 'items']),
        ...mapGetters(['usetTypeFree', 'avatarView'])
    },
    components:{ViewAvatar,Notifications, Nrequest,Ndisapproved},
    props:['site'],
    data(){
        return{
            notification:''
        }
    },
    methods:{
        logout(){
            localStorage.removeItem(userKey)
            this.$store.commit('setUser', null)
            window.location.href =  `${baseSite}`
        },
        getNotification(){
            const url = `${baseApiUrl}/user/notification`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(json =>{
                if(json.error != ""){
                    if(json.data.notifications.length > 0){
                        this.$store.commit('setNotifications', json.data.notifications)
                        this.notification = json.data.notifications
                        this.$bvToast.toast(`Você tem ${json.data.notifications.length} notificação`,{
                            title:'Fique atento',
                            autoHideDelay: 10000,
                            toaster: 'b-toaster-bottom-right',
                            appendToast:true
                        })
                    }
                }
            })
        },
        markAllAsRead(){
            this.$store.dispatch('markAllAsRead')
            this.notification = ''
        }
    },
    mounted() {
        this.getNotification()
        this.$store.dispatch('getSupportChat')
    },
}
</script>
<style>
.items-navbar-mobile{
    position:absolute;
    bottom:2rem;
}
.logout:hover{
    color:red !important
}
.items-navbar-mobile a {
    color: #312F51 !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}
#menu-overlay{
    position:fixed;
    top:0;
    left: 0;
    width: 30%;
    height: 100vh;
    background-color:#000;
    opacity: 0.4;
    z-index: 1005;
}
.remove-all{
    display: block;
    cursor: pointer;
    text-align: center;
}
.remove-all:hover{
    color:#fff;
    background-color:#6978f4;
}
.remove-all:active{
    color:#6978f4;
    background-color:#fff
}
.dropdown-toggle::after{
    display: none !important;
}
.nav-link{
    color:#312F51 !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}
.icon{
    margin-bottom: 0.2rem;
    margin-right: 0.9rem;
}

.nav-left{
    display: flex;
    flex-direction: column !important;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    transition: all ease 0.25s;
    z-index: calc(1030 + 5);
    box-shadow:-4px 2px 20px 0px rgba(88, 84, 84, .6);
    padding-top:1.2rem;
    background-color:#FFF;
}
#button-mobile{
    z-index: 2000;
}
.nav-right {
    display: flex;
    flex-direction: column !important;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #f8f9fa;
    transition: all ease 0.25s;
    z-index: 1999;
    box-shadow: -4px 2px 20px 0px rgb(88 84 84 / 60%);
    padding-top: 1.2rem;
}
.navbar-toggler{
    z-index: 1002 !important;
}
.relative-box{
    background: #fff;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 10;
    top: -5px;
    right: 20px;
    transition: rotate(45deg);
    border: inset 0.5px;
    transform: rotate(45deg);
}
@media (max-width: 991px){
    .controlsm-light{
        background-color: #fff !important;
        box-shadow: 0px 4px 16px rgba(0,0,0,0.15);
    }
}
</style>
<template>
    <div class="notifications bg-light py-3 px-4 border-bottom">
        <div class="d-flex">
            <div class="text-monospace notifica-size">
                <p>Você não prestara novos serviços de {{service}} !</p>
            </div>
        </div>
        <p class="text-right">{{time}}</p>
        <div class="d-flex justify-content-around">
            <a href="#" @click.prevent="markAsRead(notification_id)" class="nav-mlink">
                excluir
            </a>
        </div>
    </div>
</template>
<script>
import Audio from '../../../assets/notification.mp3'
export default {
    name:'Ndisapproved',
    props:['service','time', 'notification_id'],
    methods: {
        markAsRead(idNotification){
            this.$store.dispatch('maskAsRead', {id: idNotification})
        }
    }
}
</script>
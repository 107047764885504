<template>
    <div>
        <h1>Ficara os convites</h1>
    </div>
</template>
<script>
export default {
    name:'MessageInvite'
}
</script>
<style>

</style>
<template>
    <div>
        <div class=""  v-if="user.id === author">
            <div class="messageLine sent" style="justify-content:flex-end;">
                <div class="messageItem p-2 mx-3"
                    style="backgroundColor:#fff"
                >
                    <div 
                        class="messageText"
                        style="overflow:hidden;white-space: normal;"
                    >
                        {{body}}
                    </div>
                </div>
            <img :src="user.avatar | imageURL" alt="" width="50" height="50" class="border rounded-circle" srcset="">
            </div>
            <div class="messageDate">{{time}}</div>
        </div>
        <div class="" v-else>
            <div class="messageLine received"
                style="justify-content:flex-start"
            >
                <img :src="img | imageURL" alt="" width="50" height="50" class="border rounded-circle" srcset="">
                <div class="messageItem p-2 mx-3"
                    style="backgroundColor:#4855BB;"
                >
                    <div 
                        class="messageText" 
                        style="color:#fff !important;overflow:hidden;white-space: normal;"
                    >
                        {{body}}
                    </div>
                </div>
            </div>
            <div class="messageDate" style="display:flex;justify-content:flex-start">{{time}}</div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
    name:'Callitem',
    props:['author', 'body', 'time', 'img'],
    computed:mapState(['user']),
}
</script>
<style>
.sent{
    justify-content: flex-end;
}
.received{
    justify-content: flex-start;
}
.messageLine{
    margin-bottom: 10px;
    display: flex;
}
.messageItem{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 1px #ccc;
    display: flex;
    flex-direction: column;
    max-width: 90%;
}
.messageText{
    font-size: 14px;
    margin: 5px 40px 3px 5px;
    white-space: pre-wrap !important;
    word-break: break-all;
}
.messageDate{
    font-size: 11px;
    color: #999;
    margin-right: 5px;
    text-align: right;
    height: 15px;
}
</style>
<template>
  <v-card class="ma-2">
    <b-container dashboard>
      
        {{ message }}
        <div id="meet"></div>
        <!-- missing -->

    </b-container>
  </v-card>
</template>
<script>
import {mapState} from 'vuex'
export default {
  name:'Jitsi',
  computed:mapState(['user']),
  data: () => ({
    search: "",
    message: "Hello",
    api: null,
    room: "test romm",
    username: this.user.name,
  }),

  mounted() {
    this.openRoom();
  },

  methods: {
    startConference() {
      var _this = this;
      try {
        const domain = "meet.jit.si";
        const options = {
          roomName: this.room,
          height: 500,
          parentNode: document.querySelector("#meet"),
          interfaceConfigOverwrite: {
            filmStripOnly: false,
            SHOW_JITSI_WATERMARK: false,
          },
          configOverwrite: {
            disableSimulcast: false,
          },
        };

        this.api = new JitsiMeetExternalAPI(domain, options);
        this.api.addEventListener("videoConferenceJoined", () => {
          console.log("Local User Joined");

          _this.api.executeCommand("displayName", _this.username);
        });
      } catch (error) {
        console.error("Failed to load Jitsi API", error);
      }
    },
    openRoom() {
      // verify the JitsiMeetExternalAPI constructor is added to the global..
      if (window.JitsiMeetExternalAPI) {
        var person = prompt("Please enter your name:", "Rabie");
        if (person != null || person != "") this.username = person;
        var room = prompt("Please enter your room:", "Test Room");
        if (room != null || room != "") this.room = room;
        this.startConference();
      } else alert("Jitsi Meet API script not loaded");
    },
  },
};
</script>
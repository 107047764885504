<template>
    <div class="perfil-avaliation">
        <div v-if="testimonialsLength > 0">
        <b-row class="ml-4 mb-5" v-for="(testimonial, i) in testimonials" :key="i">
            <b-col cols="12">
                <div class="d-flex">
                    <!--Ainda tem que ajeita o back-end-->
                    <img :src="testimonial.avatar | imageBaseApi" class="rounded" alt="img-aleatoria" width="42rem" height="42rem"/>
                    <div class="ml-3">
                        <p class="h6">{{testimonial.name}}</p>
                        <p class="h6 text-muted">{{testimonial.service}} ({{testimonial.date_created}})</p>
                    </div>
                </div>
            </b-col>
            <b-col cols="1">
                <b-form-rating :value="testimonial.rate" readonly precision="2" no-border size="sm" variant="warning"></b-form-rating>
            </b-col>
            <b-col cols="12">
                <p class="h6 font-weight-bolder"> {{testimonial.contrast}}</p>
                <p class="text-muted">{{testimonial.body}}</p>
            </b-col>
        </b-row>
        </div>
        <div v-else class="text-center py-4">
            <NotSet
                frase="Por enquanto esse consultor ainda não possui nenhuma avaliação."
            />
        </div>
    </div>
</template>
<script>
import Perfil from '../../mixins/Perfil'
import NotSet from '../../components/containers/NotSet'
export default {
    name:'PerfilAvaliation',
    data() {
        return {
            perfil:{
                testimonials : []
            },
            service:{},
            setsevice:{},
            availability:{}
        }
    },
    components:{NotSet},
    mixins:[Perfil]
    
}
</script>
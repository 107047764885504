<template>
    <div class="shedules">
        <b-jumbotron>
            <b-row align-h="between">
                <b-col align-self="start" >
                    <div class="d-flex">
                        <div class="my-auto">
                            <img :src="icone | imageURL" class="mr-3 rounded p-2" bg-mprimary width="50" height="50">
                        </div>
                        <div class="ml-3 my-auto">
                            <b class="h5 font-weight-bolder"> {{service}}</b>
                            <p>{{description}}</p>
                        </div>
                    </div>
                </b-col>
                <b-col  md="3" offset-md="3" class="my-auto" align-center="center">
                    <router-link 
                        v-if="this.type === 'candidaters'"
                        :to="{path: `/purchases/purchase/${id}`, 
                        params: {id: id}}" 
                        style="border-radius:0.4rem !important;color:#fff !important" 
                        bg-mprimary 
                        class="btn btn-sm"
                    >
                        PÁGINA DA VENDA
                        <svg style="display:inline-block;vertical-align: bottom" xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="bi bi-arrow-right ml-3" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                        </svg>
                    </router-link>
                    <router-link 
                        v-else
                        :to="{path: `/sales/salesOne/${id}`, 
                        params: {id: id}}" 
                        style="border-radius:0.4rem !important;color:#fff !important" 
                        bg-mprimary 
                        class="btn btn-sm"
                    >
                        PÁGINA DA VENDA
                        <svg style="display:inline-block;vertical-align: bottom" xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="bi bi-arrow-right ml-3" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                        </svg>
                    </router-link>
                </b-col>
            </b-row>
            <hr>
            <b-row align-h="between">
                <b-col align-self="start" >
                    <div class="d-flex">
                        <div class="my-auto">
                            <img :src="avatar | imageURL" class="rounded" width="40" height="40">
                        </div>
                        <div class="ml-3 my-auto">
                            {{who_for}}
                        </div>
                    </div>
                </b-col>
                <b-col md="4" offset-md="4" class="my-auto" align-center="center">
                    <p class="text-muted">
                        {{data}} das {{start}} as {{end_data}}
                    </p>
                </b-col>
            </b-row>
        </b-jumbotron>
    </div>
</template>
<script>
export default {
    name:'Shedule',
    props:['icone', 'service', 'description', 'id', 'who_for','presentation','avatar', 'data', 'start', 'end_data', 'loading', 'type'],
}
</script>
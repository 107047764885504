import Vue from 'vue'
import moment from 'moment'
import {baseSocket} from '@/global'
Vue.filter('imageURL', function(value){

    const $url = value.includes('data:image/') || value.includes('http')  ? value : `${baseSocket}/${value}`

    return $url
})
Vue.filter('imageBaseApi', function (value) {
    const url = `${baseSocket}/${value}`;

    return url;
})
Vue.filter('formatDate', function(value){
    if(value){
        return moment(String(value)).format('DD/MM/YYYY')
    }
})
Vue.filter('formathour', function(value){
    if(value){
        return moment(String(value)).calendar()
    }
})
Vue.filter('formathourMinutes', function(value){
    if(value){
        return moment(String(value)).format('hh:mm')
    }
})
<template>
  <div>
    <div class="" v-if="this.clients.length > 0">

    <b-row class="py-3 my-3 border-bottom rounded-bottom"  align-v="center" v-for="(client, index) in this.clients" :key="index">
        <b-col >
            <div class="d-flex justify-content-between align-items-center">
                <img :src="client.avatar" class="rounded" width="50" height="50" :alt="client.name" />
                <h6 class="font-weigth-bolder ml-1">
                    <p>{{client.name}}</p>
                    <p class="text-muted">comprou {{client.service}}</p>
                </h6>
               
                <div class="ml-auto p-2">
                    <b-button bg-mprimary  @click="setClient(client)">
                        + add
                    </b-button>
                </div>
            </div>
        </b-col>
    </b-row>
    </div>
    <b-row class="justify-content-center py-5" v-else>
        <b-col cols="6" class="text-center">
            <p class="text-muted">
                Não há mais clientes para adicionar ao grupo
            </p> 
        </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    props:['clients'],
    methods: {
        setClient(client){
            let newClient = { ...client}
            this.$store.dispatch('putInGroup', newClient)
            
            /*const sle = document.querySelectorAll('.select')
            
            this.approval = {
                'id_user': this.user.id,
                'name': this.user.name,
                'id_service': this.newService.id,
            }
            const filt = Array.prototype.filter.call(sle, function(item){
                return item.classList.contains('selectSer')
            })
            if(filt != ''){
                filt[0].classList.remove('selectSer')
            }
            console.log(sle[index])*/
            console.log(newClient)
           
        }
    },
    created() {
        this.$store.dispatch('getClients')
    },
    /*
    data() {
      return {
        
        options: ['Apple', 'Orange', 'Banana', 'Lime', 'Peach', 'Chocolate', 'Strawberry',],
        search: '',
        value: []
      }
    },
    computed: {
      criteria() {
        // Calcule os critérios de pesquisa
        return this.search.trim().toLowerCase()
      },
      availableOptions() {
        const criteria = this.criteria
        let name = new Array()
        for(var i in this.clients){
            name.push(this.clients[i].name+' '+this.clients[i].avatar)
        }
        console.log(name);
        // Filtrar opções já selecionadas
        const options = name.filter(opt => this.value.indexOf(opt) === -1)
        if (criteria) {
          // Mostra apenas as opções que correspondem aos critérios
          return options.filter(opt => opt.toLowerCase().indexOf(criteria) > -1);
        }
        // Mostrar todas as opções disponíveis
        return options
      },
      searchDesc() {
        if (this.criteria && this.availableOptions.length === 0) {
          return 'Não há usuario com seus critérios de pesquisa'
        }
        return ''
      }
    },
    methods: {
      onOptionClick({ option, addTag }) {
        addTag(option)
        this.search = ''
      }
    }*/
  }
</script>
<template>
    <div class="content bg-marlon-light">
        <Navbar 
            v-if="isMenuVisible" 
            :site=links.site
        />
        <transition mode="out-in"
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
        >
            <router-view/>
        </transition>
        
        <Support
            v-if="isSupportVisible"
            @setStatus="start = $event"
            :status="this.start"
            class="button-support"
        />
        <terms-cookies v-if="!this.cookies.setData.status && this.$route.name != 'auth'"/>
        
    </div>
</template>
<script>
import Online from '../mixins/Online'
import {mapState} from 'vuex'
import { baseApiUrl, baseUrl,baseSite } from '@/global'
import Navbar from '@/components/template/Navbar'
import Support from '@/components/template/helper/Support'
import TermsCookies from '@/components/template/helps/TermsCookies'
export default {
    name: 'Content',
    components:{Navbar, Support,TermsCookies},
    computed:{...mapState(['isMenuVisible', 'isSupportVisible', 'cookies',['setData']])},
    data(){
        return{
            start:false,
            links: {
                api: baseApiUrl,
                site: baseSite
            },
        }
    },
    methods: {
        call(){
            if(this.start == false){
                this.start = true
            }else{
                this.start = false
            }
        }
    },
    mixins:[Online],
}
</script>

<style>
@media (min-width: 991px){
    .bg-marlon-light{
        background-color:#FAFAFA;
    }
    #menu-overlay{
        display: none;
    }
    .items-navbar-mobile{
        display: none;
    }
}
</style>
<template>
    <div class="home" dashboard>
       <Title
        name="Consultores"
       />
        <Consultants/>
    </div>
</template>
<script>

import Title from '../components/containers/Title'
import Consultants from '../components/template/Consultants'
import {mapState} from 'vuex'
export default {
    name: 'Home',
    computed:mapState(['user', 'chatUser']),
    components:{Title, Consultants},
}
</script>
<style>

</style>
<template>
    <b-row align-h="between">
        <b-col>
            <div class="d-flex">
                <div>
                    <img :src="freelancer.avatar | imageURL" alt="" srcset="" class="rounded" width="100" height="100">
                    <input type="file" class="d-none" 
                            id="image" 
                            accept="image/*" 
                            ref="avatar" 
                            name="avatar" 
                            @change="getImage($event)"
                          >
                     <a href @click.prevent="openFile()" class="edit-avatar">
                        <svg version="1.1"  xmlns="http://www.w3.org/2000/svg" fill="#4855BB" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        width="1.5rem" height="1.5rem" viewBox="0 0 528.899 528.899" style="enable-background:new 0 0 528.899 528.899;"
                        xml:space="preserve" >
                            <path d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981
                            c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611
                            C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069
                            L27.473,390.597L0.3,512.69z"/>
                        </svg><br>
                    </a>
                    <div v-if="selectFile.avatar" class="mt-2"> 
                        {{selectFile.avatar.name}}
                        <button class="btn badge-light" @click="selectFile.avatar = ''" type="button">
                            <i class="fa fa-trash text-danger"></i>
                        </button>
                    </div>
                    <div v-if="!setImage">
                        <b-button @click.prevent="upload"  bg-mprimary class="mt-2">Upload</b-button>
                    </div>
                </div>
                <div class="my-auto">
                    <div class="" v-if="freelancer.presentation == null">
                        <b  v-if="!inputName" data-toggle="tooltip" data-placement="right" title="Clique para altera nome de apresentação" class="mouse-name" @click.prevent="setPresentation">{{user.name}}</b>
                    </div>
                    <div v-if="inputName">
                        <input type="text" name="name" v-model="freelancer.presentation" placeholder="Digite uma apresentação"  id="name"><br>
                        <b-button size="sm" @click.prevent="editProfile"  bg-mprimary class="m-2">Atualizar</b-button>
                        <b-button size="sm" @click.prevent="inputName = false"  class="m-2">Cancelar</b-button>
                    </div>
                    <b data-toggle="tooltip" data-placement="right" title="Clique para altera nome de apresentação" class="mouse-name" @click.prevent="setPresentation" v-if="freelancer.presentation != null"> {{freelancer.presentation}}</b>
                    <b-form-rating 
                        v-if="freelancer.total_evaluations > 0" 
                        no-border 
                        :value="freelancer.stars_medium" 
                        variant="warning" 
                        readonly size="sm"
                    >
                    </b-form-rating>
                    <p class="text-muted" v-else>não avaliado</p>
                    <p v-if="freelancer.total_evaluations > 0" class="text-muted ml-2" > {{freelancer.total_evaluations}} - avaliações</p>
                </div>
            </div>
        </b-col>
        <b-col class="align-self-center">
            <b-button class="ml-auto" bg-mprimary v-b-modal.modal-centerr >enviar video de apresentação</b-button>
            <b-modal 
                    id="modal-centerr" 
                    centered 
                    hide-header
                    hide-footer
                    no-close-on-backdrop
                    no-close-on-esc
                >
                <b-row align-h="end">
                    <b-col cols="2">
                        <span @click="$bvModal.hide('modal-centerr')" style="cursor:pointer !important;display:flex !important;justify-content:end !important">
                            <svg width="33" height="33" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11.5" fill="#FAFAFA"/>
                            <path d="M8 8L14.5 14.5" stroke="#292841" stroke-width="2"/>
                            <path d="M14.5 8L8 14.5" stroke="#292841" stroke-width="2"/>
                            </svg>
                        </span>
                    </b-col>
                </b-row>
                    <Video/>
                </b-modal>
        </b-col>
    </b-row>
</template>
<script>
import Video from '../containers/Upload/Video'
import axios from 'axios'
import { baseApiUrl,showError, userKey } from "@/global"
import { mapState } from 'vuex'
import Profile from '../../mixins/Profile'
export default {
    name:'RowProfile',
    components:{Video},
    computed:mapState(['user', 'freelancer']),
    data() {
        return {
            inputName:false,
            state:true,
            setImage:true,
            headers:{"Accept": "form-data"},
            form:{
                name:''
            },
            selectFile:''
        }
    },
    methods: {
        openFile(){
            this.$refs.avatar.value = ''
            this.$refs.avatar.click()
        },
        getImage(e){     
            let image = e.target.files[0]
            this.selectFile = image;
            this.form.name = this.selectFile.name
            let reader = new FileReader()
            reader.readAsDataURL(image);
            reader.onload = e =>{
                this.freelancer.avatar = e.target.result
                this.setImage = false
            }
        },
        upload(){
            let formData = new FormData();
            formData.append('avatar', this.selectFile, this.selectFile.name)
    
            const url = `${baseApiUrl}/freelancers/avatar`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.defaults.headers.common['Accept'] = 'form-data'
            axios.post(url,formData).then(res => {
                if(res.data.error != ""){
                    showError(res.data.error)
                }else{
                    this.setImage = true
                    this.$toasted.show(res.data.success)
                }
            }).catch(showError)            
        },
        setPresentation(){
            this.inputName = true
        }
    },
    mixins:[Profile]
    

}
</script>
<style scoped>
.mouse-name{
    cursor:pointer;
}
.rounded:hover{
    opacity: 0.2;
}
.edit-avatar{
    cursor: pointer;
    margin-left: -57px;
    margin-right: 47px;
    opacity: 0;
}
.edit-avatar:hover{
    opacity: 1;
}
.custom-file{
    width: 70%;
    height: 2rem;
}
.custom-file-label{
    border: 1px solid #4855BB !important;
    border-radius: 0.5rem !important;
}
</style>>
<template>
    <div id="chart">
        <b class="h5 font-weight-bolder ml-2">Agendamentos</b>
        <apexchart type="area" height="250" ref="chart" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
    name:'Chart',
    computed:mapState(['freelancer']),
    data(){
        return {
            item:[],
            chartOptions:{
                chart:{
                   id:'vuechart-example'
                },
                xaxis:{
                    categories:['Jan','Fev','Mar','Abr','Mai','Jun', 'Jul', 'Ago','Set','Out','Nov','Dez' ]
                }
            },
            series:[{
                name: 'series-1',
                data:[]
            }]
        }
    },methods: {
        selecionar(array,mes){
            let qtd = 0
            for (let i=0;i<array.length;i++){
                if(array[i] === mes) qtd++
            }
            return qtd;
        },
        getPerDate(){
            let dataMonthNow = new Date().getMonth()+1
            let cont = new Array()
            let dada = this.freelancer.shedules
            let get = dada.forEach(element => {
                cont.push(new Date(element.date).getMonth()+1)
            });
            const jan = this.selecionar(cont,1)
            const fev = this.selecionar(cont,2)
            const mar = this.selecionar(cont,3)
            const abr = this.selecionar(cont,4)
            const mai = this.selecionar(cont,5)
            const jun = this.selecionar(cont,6)
            const jul = this.selecionar(cont,7)
            const ago = this.selecionar(cont,8)
            const set = this.selecionar(cont,9)
            const out = this.selecionar(cont,10)
            const nov = this.selecionar(cont,11)
            const dez = this.selecionar(cont,12)

            const EveryMonth = new Array(jan,fev, mar, abr, mai,jun,jul,ago,set,out,nov,dez)
                console.log(EveryMonth.slice(0,dataMonthNow))
            this.series[0].data = EveryMonth.slice(0,dataMonthNow)
        },
        updateSeriesLine() {
            this.$refs.real.updateSeries([{
                data: this.series[0].data,
            }], false, true);
        },
    },mounted() {
        this.getPerDate()  
    },
    
}
</script>
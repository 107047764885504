import {baseApiUrl,showError, userKey} from '@/global'
import {mapState} from 'vuex'
import axios from 'axios'
export default {
    computed:mapState(['chatSupport', 'user']),
    methods: {
        keySend(e){
            if(e.keyCode === 13 && !e.shiftKey){
                e.preventDefault()
                this.stop = true
                this.toSend()
            }
        },
        scrollToEnd(){
            var container = document.querySelector(".SupportList");
            var scrollHeight = container.scrollHeight;
            container.scrollTop = scrollHeight;
        },
        toSend(){
            this.busy = false
            // Aqui o array[0] o id do tickets vem com name [0].tickets, restante tikets_id
            this.send.tickets_id = this.chatSupport.length > 0 ? this.chatSupport[1].tickets_id : ''
            if(this.chatSupport.length > 0)
                this.send.receiver_id = this.chatSupport[1].author_id == this.user.id ? this.chatSupport[1].receiver_id : this.chatSupport[1].author_id 
            
            const url =  this.send.tickets_id != '' ? `${baseApiUrl}/chat-tickets` : `${baseApiUrl}/create-tickets`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.post(url, this.send).then(res =>{
                console.log(res.data)
                this.send.body = ''
                this.stop = false
            })
            .catch(() => showError('Você esta na fila de atendimento, aguarde !! já já atenderemos.'))
            .finally(() => this.busy = false)
            

        }
    },
    created() {
        //this.getMessage()
    },mounted() {
        this.scrollToEnd()
    },
    updated() {
        this.scrollToEnd();
        
    }
}
import 'font-awesome/css/font-awesome.css'
import Vue from 'vue'
import App from './App'
import VueMask from 'v-mask'
import VueApexCharts from 'vue-apexcharts'
//import JitsiMeetJS from '@lyno/lib-jitsi-meet'
import VueSimpleAlert from 'vue-simple-alert'
import VueQuillEditor from 'vue-quill-editor'

import './config/bootstrap'
import './config/msgs'
import './config/axios'
import './style.css'
import './config/filters'
import router from './config/router'
import store from './config/store'
import 'quill/dist/quill.snow.css'

const moment= require('moment')
require('moment/locale/pt-br')

Vue.use(require('vue-moment'),{moment})

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueMask)
Vue.use(VueSimpleAlert)

Vue.use(VueQuillEditor)


Vue.config.productionTip = false
const DEFAULT_TITLE = 'Carreiras'
router.beforeEach((to, from, next) => {
    console.log(to.name, from.name)
    document.title = to.name != undefined ? `${DEFAULT_TITLE} -  ${to.name}` : DEFAULT_TITLE
    next()
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

<template>
    <div class="config" dashboard>
       <Title
            name="Configuração"
        />
        <b-card title="Card Title" no-body class="mr-3 my-3">
            <b-card-header header-tag="nav">
                <b-nav card-header tabs>
                    <b-nav-item :to="{path: '/Config'}" active-class="active" exact >Dados</b-nav-item>
                    <b-nav-item :to="{path:'/Config/bank'}" active-class="active" exact>CONTA BANCÁRIAS</b-nav-item>           
                </b-nav>
            </b-card-header>
            <b-card-body>
                <transition mode="out-in"
                    enter-active-class="animate__animated animate__fadeIn"
                    leave-active-class="animate__animated animate__fadeOut"
                >
                    <router-view/>
                </transition>
            </b-card-body>
        </b-card>
    </div>
</template>
<script>
import Title from '../components/containers/Title'
export default {
    name: 'Config',
    components:{Title}
}
</script>
<style>

</style>
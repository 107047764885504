<template>
    <div class="loading mt-5 pt-5">
        <img src="@/assets/img/loading.gif" alt="loading">
    </div>
</template>
<script>
export default {
    name:'Loading'
}
</script>
<style>
    .loading{
        display:flex;
        justify-content: center;
        align-items: center;
    }
</style>
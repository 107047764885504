<template>
    <div class="purchases" dashboard>
        <Title
            name="Suas Compras"
        />
        <b-card title="Card Title" no-body class="mr-3 my-3">
            <b-card-header header-tag="nav">
                <b-nav card-header tabs>
                    <b-nav-item :to="{path: '/purchases'}" active-class="active" exact >DETALHES PAGAMENTOS</b-nav-item>
                    <b-nav-item :to="{path: '/purchases/open'}" active-class="active" exact >EM ABERTO</b-nav-item>
                    <b-nav-item :to="{path:'/purchases/historic'}" active-class="active" exact>HISTÓRICO</b-nav-item>                     
                    <!--¹<b-nav-item :to="{path:'/purchases/completed'}" active-class="active" exact>HISTÓRICO</b-nav-item>-->
                </b-nav>
            </b-card-header>
            <b-card-body>
                <transition mode="out-in"
                    enter-active-class="animate__animated animate__fadeIn"
                    leave-active-class="animate__animated animate__fadeOut"
                >
                    <router-view/>
                </transition>
            </b-card-body>
        </b-card>
    </div>
</template>
<script>
import Title from '../components/containers/Title'
export default {
    name:'Purchases',
    components:{Title}
}
</script>
<template>
    <div>
        <b-navbar-brand :href=links.site class="ml-5 pl-5 d-none d-md-block" style="position:fixed; z-index:1001">
            <img src="@/assets/img/Logotipo..png" alt="Logotipo" width="140">
        </b-navbar-brand>
         <transition-group mode="out-in"
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
        >
            <LoginClient
                key
                class="bg-white"
                v-if="!consultant && !pick && !remember && !token" 
                :status="registre"
                :rememberAction="remenberSend"
                :who="consultantOrClient"
                :link="links.site"
            />
            <LoginConsult
                key
                class="bg-white"
                v-if="consultant && !remember && !token && !pick" 
                :status="registre"
                :rememberAction="remenberSend"
                :who="consultantOrClient"
                :link="links.site"
            />
            <b-container class="selected" v-if="pick" key>
                <b-row align-v="center">
                    <b-col sm="12" md="6" class="text-center">
                        <p class="h4 font-weight-bolder my-3">Você que um consultoria ?</p>
                        <b-button bg-mprimary @click="registerClient">Contrate um Consultor</b-button>
                    </b-col>
                    <b-col sm="12" md="6" class="text-center">
                        <p class="h4 font-weight-bolder my-3">Você que ser cadastra como um consultor ?</p>
                        <b-button bg-mprimary @click="registerConsultant">Trabalhe conosco</b-button>
                    </b-col>
                    <b-col cols="12">
                        <div class="text-center mt-5">
                            <img src="@/assets/img/Logotipo..png" alt="Logotipo" width="240">
                        </div>
                    </b-col>
                </b-row>
            </b-container>
            <b-container v-if="remember" key="remenber">
                <Remember
                    :cancelled="cancelRemenber"
                />
            </b-container>
            <b-container v-if="token" key="token">
                <Reset
                    :token="this.$route.query.token"
                />
            </b-container>
        </transition-group>
    </div>
</template>
<script>
import LoginClient from '../template/LoginClient'
import LoginConsult from '../template/LoginConsult'
import Remember from '../auth/RememberEmail'
import Reset from '../auth/ResetPassword'
import {mapState} from 'vuex'
import {baseSite, profilePurchase} from '@/global'
export default {
    name: 'Auth',
    components:{LoginConsult, LoginClient, Remember, Reset},
    computed:mapState(['isMenuVisible']),
    data(){
        return{
            consultant:false,
            pick:false,
            registre:false,
            remember:false,
            token:false,
            links: {
                site:baseSite
            }
        }
    },
    methods: {
        cancelRemenber(){
            this.remember = false
        },
        remenberSend(){
            this.remember = true
        },
        consultantOrClient(){
            this.consultant = this.consultant == false ? true : false
        },
        status(){
            if(this.$route.query.q == 'consultant'){
                this.consultant = true
            }else if(this.$route.query.q == 'pick'){
                this.pick = true
                this.registre = true
            }else if(this.$route.query.q == 'remember'){
                this.remember = true
            }else if(this.$route.query.q == 'user'){
                this.pick = false
                this.registre = true
                localStorage.setItem(profilePurchase, this.$route.query.perfil)
            }else if(this.$route.query.perfil){
                localStorage.setItem(profilePurchase, this.$route.query.perfil)
            }
            if(this.$route.query.token){
                this.token = true
                console.log(this.$route.query.token)
            }
        },
        registerConsultant(){
            this.consultant = true,
            this.pick = false
        },
        registerClient(){
            this.pick = false
        }
    },
    created() {
        this.status()
    },
}
</script>
<style>
#alterarLogin{
    z-index: 2000;
    position:absolute;
}
.selected{
    height: 100vh;
    display: flex;
    background-color: #cececea3;
}
</style>
<template >
    <div class="list-consultants">
    <div class="controlsm-card">
        <b-card>
            <b-row align-h="between">
                <b-col cols="12" md="6" class="input-presentation">
                    <b-form-input
                        placeholder="Busca consultor"
                        v-model="filter.presentation"
                        type="text"
                        id="name"
                    >
                    </b-form-input>
                    <i class="fa fa-search text-muted" aria-hidden="true" ></i>
                </b-col>
                <b-col cols="12" sm="6" class="d-md-flex d-none">
                    <p class="m-auto col-md-3 col-sm-6"> Ordenar por</p>
                    <b-form-select
                        v-model="filter.order"
                        :options="list"
                    >
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row class="mt-4 custom-block">
                <b-form-checkbox-group
                    v-model="searchHard"
                    :options="systemService"
                    class="mb-3"
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                >
                </b-form-checkbox-group>                
            </b-row>
            <b-row class="mt-4 d-md-none d-flex">
                <b-col 
                    cols="6" md="3" 
                    class="custom-col" 
                    v-for="(service, index) in systemService" 
                    :key="index"
                >
                    <b-form-checkbox >{{service.name}}</b-form-checkbox>
                </b-col>
              
                <b-col cols="12" sm="6" class="mt-4">
                    <p class="m-auto col-md-3 col-sm-6"> Ordenar por</p>
                    <b-form-select
                    
                        v-model="filter.order"
                        :options="list"
                    >
                    </b-form-select>
                </b-col>
            </b-row>
        </b-card>        
    </div>
    <b-row class="control-sm-row">
        <div class="container" v-if="loading">
            <b-row class="text-center over-content">
                <b-col align-self="center">
                    <strong>Loading ...</strong>
                    <b-spinner></b-spinner>
                </b-col>
            </b-row>
        </div>            
        <b-col v-else class="mt-5" sm="6" md="3" v-for="(perfil,index ) in perfils" :key="index">
            <div class="card-marlonn" :to="{path:`/perfil/${perfil.id_user}/about`, params:{id:perfil.id_user} }">
                <div class="card-header card-headerr container bg-transparent">
                    <div class="row">
                        <div class="col-2 text-center" style="display: grid;align-content: center;">
                            <b-img :src="perfil.avatar | imageURL" rounded="circle" width="55rem" height="55rem" alt="Circle image"></b-img>
                        </div>
                        <div class="col ml-3">
                            <p class="h600-presentation ml-3" :class="{'mb-negative' : perfil.total_evaluations > 0 }">{{perfil.presentation}}</p>
                            <b-form-rating 
                                v-if="perfil.total_evaluations > 0" 
                                :value=perfil.stars_medium 
                                precision="1" 
                                no-border 
                                size="sm" 
                                variant="warning"
                                class="bg-transparent"
                                readonly
                                inline
                            ></b-form-rating>
                            <p 
                                v-if="perfil.total_evaluations > 0" 
                                class="ml-3 text-avaliation"
                            >
                            {{perfil.total_evaluations}} avaliações
                            </p>
                            <p class="ml-3 text-avaliation" v-else> sem avaliação</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="card-mid">
                        <p class="card-text consultant-h500-text">{{perfil.about}}</p>
                    </div>
                    <div class="card-items-service">
                        <div 
                            v-for="(item, index) in perfil.services" 
                            :key="index" 
                            class="badge rounded-pill" 
                            badge-consultant
                        > 
                            <p>
                                {{item.name}}
                            </p>
                        </div>
                    </div>
                    <div class="link-consultant">
                        <router-link 
                            class="h600-link" 
                            :to="{path:`/perfil/${perfil.id_user}/about`, params:{id:perfil.id_user} }"
                        >
                            Ver Perfil <i class="fa fa-angle-right ml-3" aria-hidden="true"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </b-col>
    </b-row>
        <!--
        <b-pagination v-model="filter.page" :total-rows="search.rows" align="center" class="my-3"></b-pagination>
        -->
    </div>
</template>
<script>
import ConsultantsAll from '../../mixins/ConsultantsAll'
export default {
    name:'ListConsultants',
    mixins:[ConsultantsAll],
    created() {
        this.$store.dispatch('getSystemServices')
    },
}
</script>
<style>
.link-consultant{
    position: absolute;
    width: 210px;
    bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.card-items-service{
    display: flex;
    justify-items: start;
    align-content: space-evenly;
    justify-content: space-evenly;
    align-items: center;
    flex-flow: wrap;
    padding-bottom:30px;
}
.input-presentation input[type=text]{
    padding-left:3rem;
}
.input-presentation i{
    position: absolute;
    top: 0.8rem;
    padding-left: 1rem;
}
#avaliation::before{
    content:" Ordernar por";
    color:red;
    display: inline-flex;
}
.ml-6{
    margin-left: 11rem !important;
}
.card-headerr{
    height: 103px !important;
}
.card-mid{
    width:239px;
    height: 79px;
}
.card-marlonn{
    /*
    width:282px;
    width: auto;
    */
    height: 336px;
    background: #fff;
    border: 1px solid #ECECEC;
    box-sizing:border-box;
    box-shadow:0px 1px 2px rgba(0,0,0,0.2);
    border-radius:6px
}
.text-avaliation{
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color:#312f51;
    opacity:0.5
}
.h600-presentation{
    height: 16px;
    font-weight: 600;
    font-size:14px;
    line-height: 16px;
    color:#000;
}
.consultant-h500-text{
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #312f51;
    opacity: 0.5;
    width:208px
}
.h600-link{
    font-weight: 600;
    font-size: 14px;
    line-height:16px;
    color:#312F51;
}
.badge{
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 12px !important;
    margin-bottom: 0.3rem !important;
}
.badge p{
    padding:0.2rem;
}
.custom-control{
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
}
.custom-col{
    padding-left: 20px !important;
    padding-right: 0px !important;
}
#avaliation::before{
    content:" Ordernar por";
    color:red;
    display: inline-flex;
}
.control-sm-row{
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.controlsm-card{
    border: 1px solid #ECECEC;
    border-radius: 6px;
}
.custom-control-label{
    padding-top:0.3rem
}
@media (max-width: 991px){
    .control-sm-row{
        margin-left: 0px !important;
        margin-right: 20px !important;
    }
    .controlsm-card{
        margin-right: 1.2rem;
    }
    .custom-block{
        display: none;    
    }
    .consultant-h500-text{
        width:auto !important;
    }
    .link-consultant{
         width: 310px;
    }
}
</style>
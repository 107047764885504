<template>
    <div 
        class="support-start"
        :class="{'support border-rounded' : status == false}"
    >   
        <div :class="{'d-none' : status == true}" style="transition: all  5s;">
             <span 
                v-if="chatSupport.length < 1"
                @click="closediconSupport" 
                style="cursor:pointer !important;display:flex !important;justify-content:end !important">
                <svg width="33" height="33" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 8L14.5 14.5" stroke="#292841" stroke-width="2"/>
                <path d="M14.5 8L8 14.5" stroke="#292841" stroke-width="2"/>
                </svg>
            </span>
            <i 
                @click="open"
                class="fa fa-comment-o fa-3x" 
                aria-hidden="true"
                
            ></i>
        <!---->
        </div>
        <div v-if="status == true" style="height:100%">
            <div class="top">
                <b-row>
                    <!--
                    <b-button @click="teste()">
                        ooo
                        
                    </b-button>-->
                    <b-col cols="10" v-if="chatSupport.length < 1">
                        <p class="font-weight-bolder text-light ml-3">Dúvidas? Fale conosco</p>
                        <p class="text-light ml-3" style="font-size: 0.8rem;">Em até 15 minutos responderemos</p>
                    </b-col>
                    <b-col  class="text-center" cols="10" v-else>
                        <p class="font-weight-bolder text-light ml-3"> identificador {{idTicket}}</p>
                    </b-col>
                    <b-col cols="2">
                        <span @click="closed" style="cursor:pointer !important;display:flex !important;justify-content:end !important">
                            <svg width="33" height="33" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 8L14.5 14.5" stroke="#292841" stroke-width="2"/>
                            <path d="M14.5 8L8 14.5" stroke="#292841" stroke-width="2"/>
                            </svg>
                            
                        </span>
                    </b-col>
                </b-row>
                <div class="d-flex" v-if="chatSupport.length < 1">
                    <div v-for="(adm, i) in userAdm" :key="i">
                        <img :src=adm.avatar  height="26rem" width="26rem" class="rounded-circle ml-2"/>
                    </div>...
                </div>
            </div>
            <Window
                class="bodyChat"
                :itemMsg="chatSupport"
            />
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
import Window from './Window'
export default {
    name:'Support',
    props:['status'],
    computed:mapState(['userAdm','chatSupport', 'idTicket']),
    components:{Window},
    methods: {
        teste(){
            this.$store.dispatch('finallyTickets')
        },
        closed(){
            this.$emit('setStatus',false)
        },
        open(){
            this.$emit('setStatus',true)
        },
        closediconSupport(){
            this.$store.commit('setVisible', false)
        }
    },
}
</script>
<style>
.support{
    position: fixed;
    height: auto !important;
    z-index: 2001 !important;
    bottom: 10px !important;
    background: #4855BB !important;
    right: 20px !important;
    width: auto !important;
    border-radius:50% !important;
    margin-bottom: 0.2rem !important;
}
.support-start{
    position: fixed;
    z-index: 2001 !important;
    bottom: 10px !important;
    background:  #efefef !important;
    right: 20px !important;
    height: 70vh;
    border-radius:1rem;
    animation-delay: 10ms;
    margin-bottom: 2rem;
    max-width:40%
}
.top{
    height: 15%;
    background-color: #4855BB;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
}
.bodyChat{
    height: 100%;
    background-color: #dddddd;
}
.button-support:hover{
    cursor: pointer;
}

@media (max-width: 768px){
    .support-start{
        max-width:90%
    }
}
</style>
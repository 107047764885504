<template>
    <div class="support-window">
        <b-container class="SupportList" ref="list">
            <div v-if="chatSupport.length > 0">
                <div v-for="(chats, index) in chatSupport" :key="index" class="mt-2">
                    <CallItem
                        :author="chats.author_id"
                        :body="chats.body"
                        :time="chats.created_at | formathour"
                        :img="chats.image | imageURL"
                    />
                </div>
            </div>
            <div v-else class="mt-2">
                <CallItem
                    :author=0
                    :body="'ola, faça sua pergunta !'"
                    :time=" new Date() | formathour"
                    :img="'https://api-carreiras.carreiras.co/storage/images/favicon.ico'"
                />
            </div>
            
        </b-container>
        <div class="SupportChatWindow">
            <div class="SuportChat-footer">
                <b-overlay :show="busy" rounded="lg" opacity="0.6">
                    <template #overlay>
                        <div class="d-flex align-items-center">
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                            <b-spinner type="grow" variant="dark"></b-spinner>
                            <b-spinner small type="grow" variant="secondary"></b-spinner>
                            <!-- We add an SR only text for screen readers -->
                            <span class="sr-only">Please wait...</span>
                        </div>
                    </template>
                    <div class="SupportChatWindow--inputarea" style="flex:1">
                        <textarea 
                            @keydown="keySend"
                            class="SupportChatWindow--input"
                            type="text"
                            placeholder="Digite uma mensagem"
                            v-model="send.body"
                            :disabled="this.stop"
                        ></textarea>
                    </div>
                <!--
                <div class="SupportChatWindow--pos mx-3">
                    <button class="btn btn-sm px-4 text-light font-weight-bolder" @click.prevent="toSend" bg-mprimary>
                        <b v-if="!loading">
                            ENVIAR
                            <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                            </svg>
                        </b>
                        <b-spinner v-else variant="success" type="grow" label="Spinning">
                        </b-spinner>
                    </button>
                </div>-->
                </b-overlay>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
import CallItem from '../../containers/CallItem'
import SupportChat from '../../../mixins/Call/SupportChat'
export default {
    name:'Window',
    props:['itemMsg'],
    components:{CallItem},
    data(){
        return{
            messages:{},
            send:{},
            loading:false,
            show: false,
            busy:false,
            stop:false
        }
    },
    mixins:[SupportChat]
}
</script>
<style>
.SupportList{
    flex:1;
    overflow:scroll;
    height: 75%;
}
.SupportList::-webkit-scrollbar{
    width: 10px;
    height: 6px;
}
.SupportList::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0, 0.2);
}
.SupportChatWindow{
    display: flex;
    flex-direction: column;
    height: 15%;
}
.SupportChatWindow--body{
    flex: 1;
    overflow-y: auto;
    height: 15rem;
    padding: 20px 30px;
}
.SuportChat-footer{
    align-items: center;
    padding: 1rem;
}
.SupportChatWindow--pre{
    display: flex;
    margin: 0 15px;
}
.SupportChatWindow--input{
    width: 100%;
    height: 40px;
    border: 0;
    outline: 0;
    background-color: #fff;
    border-radius: 0.5rem;
    font-size: 15px;
    color:#4a4a4a;
    padding-left: 15px;
}
.SupportChatWindow--pos{
    display: flex;
    margin: 0 15px;
}
</style>
<template>
    <div class="sale-one">
        <b-skeleton-img v-if="this.loading === false"></b-skeleton-img>
        Unica
    </div>
</template>
<script>
import Title from '../containers/Title'
import SheduleOne from '../containers/SheduleOne'
import {baseApiUrl,showError, userKey} from '@/global'
import {mapState} from 'vuex'
import axios from 'axios'
export default {
    name:'PaymentOne',
    components:{SheduleOne, Title},
    computed:mapState(['user', 'callRoom']),
    data(){
        return{
            dados:null,
            reference:'',
            message:{},
            retornApi:1,
            send:false,
            loading:false
        }
    },
    methods: {
        get(){
            const url =  `${baseApiUrl}/order/${this.reference}`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res =>{
                this.dados = res.data
            }).finally(this.loading = true)
        },
    },
    created() {
        this.reference = this.$route.params.reference
        this.get()
    }
}
</script>
<style>

</style>
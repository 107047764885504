//import {baseApiUrl,showError, userKey} from '@/global'
//import axios from 'axios'
export default {
    state:{
        callVideo: null
    },
    mutations:{
        //inseriando Call
        setCall(state, callVideo){
            state.callVideo = callVideo
        }
    },
    actions:{
       
    }
}
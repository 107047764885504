<template>
    <b-row>
        <b-col md="auto">
            <b-calendar 
                v-model="selected_date" 
                @context="onContext" 
                locale="pt-BR"
                nav-button-variant="primarym"
                today-variant="primarym"
                selected-variant="success"
            ></b-calendar>
        </b-col>
        <b-col class="border-left">
            <p class="font-weight-bold"><b text-mprimary>{{data.to_realize}}</b> Consultorias agendadas </p>
            <p class="font-weight-bold"><b text-mprimary>{{data.realized}}</b> Consultorias realizadas </p>
            <b-container v-if="selected_date == '' && shedules == ''" class="mt-3 py-1 border rounded" >
                <b-row class="text-center" style="height:50vh">
                    <b-col align-self="center" class="text-muted">
                        <p> Selecione um data para </p>
                        <p> visualizar as consultoria agendadas</p>
                    </b-col>
                </b-row>
            </b-container>
            <b-container class="notset py-1 border rounded" v-if="selected_date !== '' && mode">
                <b-col align-self="center" class="text-muted text-center">
                    <NotSet
                        frase="Você não tem nenhuma agendamento para essa data."
                    />
                </b-col>
            </b-container>
            <b-container class="list" v-if="selected_date && mode == false">
                <div v-for="shedule in shedules" :key="shedule.index">
                <CalendarDate 
                    :service="shedule.service.name"
                    :candidate="shedule.candidate.name"
                    :date="shedule.datetime"
                    :start="shedule.start | moment('HH:mm')"
                    :end="shedule.end | moment('HH:mm')"
                    :image="shedule.candidate.avatar"
                    :id="shedule.id"
                />
                </div>
            </b-container>
        </b-col>
    </b-row>
</template>

<script>

import CalendarDate from './CalendarDate'
import NotSet from './NotSet'

import {baseApiUrl,showError, userKey} from '@/global'
import {mapState} from 'vuex'
import axios from 'axios'
export default {
    name:'Calendar',
    computed:mapState(['user', 'freelancer']),
    components:{CalendarDate, NotSet},
    data() {
        return {
            selected_date: '',
            context: null,
            mode: false,
            shedules:null,
            data:{
                'total' : '',
                'realized': '',
                'to_realize': ''
            }
        }
    },
    methods: {
        onContext(ctx) {
            this.context = ctx
            const url = `${baseApiUrl}/shedules/${this.user.id}/free/${this.context.activeYMD}`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res => {
                if(res.data.error == "" ){
                    this.shedules = res.data.list
                    if(this.shedules.length == 0 && this.selected_date != ""){
                        this.mode = true
                    }else{
                        this.mode = false
                    }
                }else{
                    showError(res.data.error)
                }
            }).catch(showError) 
        },
        getShedules(){
            const url = `${baseApiUrl}/shedules/${this.user.id}/free`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res => {
                if(res.data.error == "" ){
                    this.data.total = res.data.count_total
                    this.data.realized = res.data.realized
                    this.data.to_realize = res.data.to_realize
                    
                }
            }).catch(showError) 
        }
    },
    created() {
        this.getShedules()
    },
}
</script>
<style>
.list{
    flex:1;
    overflow-y:auto;
    height: 15rem;
}
.list::-webkit-scrollbar{
    width: 10px;
    height: 6px;
}
.list::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0, 0.2);
}
</style>
<template>
    <div class="testimonials">
        <b-row class="ml-4 mb-5">
            <b-col cols="1">
                <b-form-rating :value="testimonials.rate" readonly precision="2" no-border size="sm" variant="warning"></b-form-rating>
            </b-col>
            <b-col cols="12">
                <p class="h6 font-weight-bolder"> {{testimonials.contrast}}</p>
                <p class="text-muted">{{testimonials.body}}</p>
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    name:'Testimonials',
    props:['testimonials']
}
</script>
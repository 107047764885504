//import {baseApiUrl,showError, userKey} from '@/global'
//import axios from 'axios'
export default {
    namespaced:true,
    state:{
        dataProfile: null,
    },
    mutations:{
        
        setProfile(state, payload){
            state.dataProfile = payload
        },
    },
    getters:{
        profileId(state){
            return state.dataProfile ? state.dataProfile.id_user :  0
        },
        services(state){
            return state.dataProfile.services
        },
        availabilityId: (state) => (dateNow) => {
            const getWeekday = new Date(dateNow.replace('-', '/')).getDay()
            console.log('aqui', getWeekday)
            
            return state.dataProfile.free_availability.find(todo => todo.weekday == getWeekday )
        },
        hoursAvailability:(state) =>(hours, weekday) =>{
            console.log('estou aqui ',hours)
            console.log('estou weekday ',weekday)
            let splitHour = hours.split(',')
            console.log('Valor -> ',splitHour)
            let arrayNew = []
            for(let i=0;i<splitHour.length;i++){
                let obj = {}
                // let valoresObj = {}
                obj.value = splitHour[i]
                obj.text = splitHour[i]
                arrayNew.push(obj)
                // Object.assign(obj, valoresObj)
            }
            // console.log('object', obj)
            return arrayNew .length > 0 ? arrayNew : []
        },
        testimonials(state){
            return state.dataProfile.testimonials
        },
        testimonialsLength(state){
            return state.dataProfile.testimonials.length
        },
        mediaReviews(state){
            let empty = []
            let soma = 0
            let rates = Object.values(state.dataProfile.testimonials)
            rates.map(rate => { empty.push(rate.rate) })

            for(var i=0;i<empty.length;i++){
                soma+=empty[i]
            }
            return soma / state.dataProfile.testimonials.length
        },
        certificates(state){
            return state.dataProfile.certificates
        },
        checkRequest: (state, getters) => (id) =>{
            return  getters.profileId != id
        }
    },
    actions:{
        setDataProfile(context, payload){
            console.log('AMIGO ESTOU AQUI DENTRO, ', payload)
            context.commit('setProfile',payload)
        }
       
    }
}
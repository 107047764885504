<template>
    <div class="createEdit" @keyup.ctrl.enter.prevent="keySaveNotPad">
        <b-row align-h="between">
            <b-col cols="6">
                <label for="title">Título</label>
                <b-form-input maxlength=18 id="title" size="sm" v-model="contentHtml.title"></b-form-input>
                <b-form-text id="input-live-help">Identificador do seu bloco.</b-form-text>
            </b-col>
            <b-col cols="2" >
                <button 
                    v-if="this.contentHtml.id != ''"
                    type="submit" 
                    class="btn text-light" 
                    bg-mprimary
                    @click="saveNotPad"
                >
                    Salvar
                </button>
            </b-col>
        </b-row>
        <b-overlay
            :show="loading"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-lg
            rounded="lg"
        >
            <div class="quillEditor">
                <quill-editor
                    v-model="contentHtml.content"
                    ref="refQuillEditor"
                    :options="quillOptions"
                >
                </quill-editor>
            </div>
            <b-row align-h="end" class="mt-3" >
                <b-col cols="2" >
                    <button 
                        v-if="this.contentHtml.id == ''"
                        type="submit" 
                        class="btn btn-success text-light" 
                        @click="saveNotPad"
                    >
                        Criar
                    </button>
                </b-col>
            </b-row>
        </b-overlay>
    </div>
</template>
<script>
import {baseApiUrl,showError, userKey} from '@/global'
import axios from 'axios'
export default {
    name:"CreateEdit",
    data(){
        return {
            loading:false,
            contentHtml:{
                id:'',
                title: '',
                content:'',
            },
            quillOptions: {
                placeholder: 'Digite uma frase para personalizar',
                readonly: false,
            }
        }
    },
    methods: {
        get(){
            if(this.$route.params.id){
                this.loading = true
                const url = `${baseApiUrl}/notepad/${this.$route.params.id}`
                const auth = JSON.parse(localStorage.getItem(userKey))
                axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
    
                axios.get(url).then(res=>{
                    this.contentHtml.id = res.data.id
                    this.contentHtml.title = res.data.title
                    this.contentHtml.content = res.data.content
                    
                }).finally(()=>this.loading = false)
            }else{
                this.$bvToast.toast('Cria seu novo bloco de Notas', {
                    title: `Criando bloco de notas`,
                    solid: true
                })
            }
        },
        keySaveNotPad(e){
           // e.preventDefault()
            this.saveNotPad()            
        },
        verifield(){
            if(this.contentHtml.title == ''){
                this.$toasted.info("Título é um campo obrigatorio, pois é oque identifica seu bloco", { 
                    icon:{
                        name:'fa fa-info'
                    },
                    position: "top-right", 
                    duration : 5000
                })
                this.loading = false
                return false
            }else if(this.contentHtml.title != '' && this.contentHtml.content == ''){
                this.$toasted.error("Conteúdo do bloco obrigatorio, pois o conteúdo é essencial para um bloco de notas!", { 
                    icon:{
                        name:'fa fa-exclamation-triangle'
                    },
                    position: "top-right", 
                    duration : 5000
                })
                this.loading = false
                return false
            }
            return true
        },
        saveNotPad(){
            
            if(this.verifield()){
                this.loading = true
                const method = this.contentHtml.id ? 'put' : 'post'
                const id = this.contentHtml.id ? `/${this.contentHtml.id}` : ''
                const msg = method == 'post' ? 'Criado com sucesso !' : 'Salvo com sucesso !'
                const auth = JSON.parse(localStorage.getItem(userKey))
                axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`

                axios[method](`${baseApiUrl}/notepad${id}`, this.contentHtml)
                .then(res=>{
                    this.$toasted.success(msg,{
                        icon:{
                            name:'fa fa-check'
                        },
                        position: "top-right", 
                        duration : 5000
                    })
                    this.contentHtml.id = res.data[0].id
                    this.contentHtml.title = res.data[0].title
                    this.contentHtml.content = res.data[0].content

                })
                .finally(()=>this.loading = false)
                .catch(showError)
            }
        }
    },
    created() {
        this.get()
    }
}
</script>
<style>

</style>
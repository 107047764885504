<template>
    <div>
        <b-card no-body class="overflow-hidden m-3" bg-variant="light">
            <b-row align-v="center">
                <b-col md="8" align-self="center" class="d-flex">
                    <img :src="img | imageURL" width="75" height="75"  alt="" srcset="" class="rounded border m-3"/>
                    <span  v-if="status" variant="success" width="50" height="50" class="text-success online">.</span>
                    <div class="my-3">
                    <p class="cm font-weight-bolder">{{name}}</p>
                    <p class=" h7 text-muted">{{msg}}</p>
                    <p class="text-muted" style="font-size: small !important">{{dateLast_msg}} as {{hour_msg}}</p>
                    </div>
                </b-col>
                <b-col md="2" v-if="this.group === true">
                   <router-link :to="{path: `/message/group/${id}`, params: {id: id}}"  class="nav-link" variant="light">Ver chat</router-link>
                </b-col> 
                <b-col md="2" v-else>
                   <router-link :to="{path: `/message/chat/${id}`, params: {id: id}}"  class="nav-link" variant="light">Ver chat</router-link>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
export default {
    name:'CallList',
    props:['name', 'img', 'msg', 'dateLast_msg', 'hour_msg', 'id', 'group', 'status']
}
</script>
<style >
.cm,p{
    margin-bottom:auto !important;
}
.online{
    width: 0.8rem;
    height: 0.8rem;
    background: green;
    border-radius: 50%;
    position: absolute;
    bottom: 0.9rem;
    left: 6.2rem;
}
</style>
<template>
  <div id="nav" class="row align-items-center">
    <b-alert
        v-model="showTop"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 2000;"
        variant="success"
        dismissible
    >
      Cadastro finalizado - Efetue primeiro login e faça autentificação.
    </b-alert>
    <div v-if="showLoading" class="bg-mprimary over"> 
        <b-row class="text-center over-content">
            <b-col align-self="center">
                <strong>Loading ...</strong>
                <b-spinner></b-spinner>
            </b-col>
        </b-row>
    </div>
    <div class="col-6 login-user-img d-sm"></div>
    <div class="col">
        <form action="" class="mx-md-5 px-md-5 px-2">
            <b-nav class="link-open">
                <b-nav-item  @click="who" >Login Cliente</b-nav-item>
            </b-nav>
            <Title
                v-if="!showSignup"
                name="Login de Consultor"
                style="margin-top: 0.8rem !important;"
            />
            <Title 
                v-else
                name="Cadastro de Consultor"
                style="margin-top: 0.8rem !important;"
            />
            <div class="form-group" v-if="!showSignup || !showSignin">
                <label for="email">Dígite seu e-mail <b v-if="showSignup">*</b></label>
                <input :class="{'border border-danger' : error.email != 0}" type="email" class="form-control form-control-lg" v-model="user.email" id="email" aria-describedby="email">
                <span class="text-danger">
                {{error.email}}
                </span>

            </div>
            <div class="form-group" v-if="!showSignup || !showSignin">
                <label for="password">Dígite sua senha <b v-if="showSignup">*</b></label>
                <input :class="{'border border-danger' : error.password != 0 }" type="password" class="form-control form-control-lg" v-model="user.password" id="password">
                <span class="text-danger">
                {{error.password}}
                </span>
                <div class="" v-if="showSignup">
                    <p v-if="passwordStrength.score == 0">
                       Nível de senha.
                    </p>
                    <p v-if="passwordStrength.score == 1">
                       Senha não aceitavél.
                    </p>
                    <p v-if="passwordStrength.score == 2">
                       Senha não aceitavél, quase lá !
                    </p>
                    <p v-if="passwordStrength.score == 3">
                       Senha aceitavél, mas você pode melhora !
                    </p>
                    <p v-if="passwordStrength.score == 4">
                       Senha ótima !
                    </p>                    
                    <div class="d-flex flex-row bd-highlight my-1" >
                        <span 
                        class="progress_password mr-2 rounded-pill bd-highlight" 
                        :class="{
                            'bg-danger' : passwordStrength.score <= 1,
                            'bg-warning' : passwordStrength.score == 2,
                            'bg-info' : passwordStrength.score == 3,
                            'bg-success' : passwordStrength.score == 4
                        }"></span>
                        <span 
                        class="progress_password mr-2 rounded-pill  bd-highlight" 
                        :class="{
                            'bg-secondary' : passwordStrength.score <= 1,
                            'bg-warning' : passwordStrength.score == 2,
                            'bg-info' : passwordStrength.score == 3,
                            'bg-success' : passwordStrength.score == 4
                        }"></span>
                        <span 
                        class="progress_password mr-2 rounded-pill bd-highlight" 
                        :class="{
                            'bg-secondary' : passwordStrength.score <= 2,
                            'bg-info' : passwordStrength.score == 3,
                            'bg-success' : passwordStrength.score == 4
                        }"></span>
                        <span 
                        class="progress_password rounded-pill bd-highlight"
                        :class="{
                            'bg-secondary' : passwordStrength.score <= 3,
                            'bg-success' : passwordStrength.score == 4
                        }"></span>
                    </div>
                </div>
            </div>
            <div :class="{'d-none form-group' : !showSignup || showSignin}">
                <label for="password_confirm">Confirmar a senha*</label>
                <input type="password" :class="{'border border-danger' : error.password_confirm != 0 }" class="form-control form-control-lg" v-model="user.password_confirm" id="password_confirm">
                <span class="text-danger">
                {{error.password_confirm}}
                </span>
            </div>
            <!--Segundo passo do Cadastro-->
            <div v-if="showSignin && showSignup">
                <div class="form-group">
                    <label for="name">Nome Completo *</label>
                    <input type="name" :class="{'border border-danger' : error.name != 0 }" class="form-control form-control-lg" v-model="user.name" id="name">
                    <span class="text-danger">
                    {{error.name}}
                    </span>
                </div>
                <div class="form-group">
                    <label for="birthdate">Data de nascimento *</label>
                    <input 
                        class="form-control form-control-lg" 
                        :class="{'border border-danger' : error.birthdate != 0 }" 
                        v-model="user.birthdate" 
                        id="birthdate" 
                        placeholder="00/00/0000"
                        v-mask="'##/##/####'"
                    >
                    <span class="text-danger">
                    {{error.birthdate}}
                    </span>
                </div>
                <!--
                <div class="form-group">
                    <label for="linkedin">Perfil do linkedin (opcional)</label>
                    <input type="text" class="form-control form-control-lg" v-model="user.linkedin" id="linkedin">
                </div>-->
                <b-form-checkbox
                    id="terms"
                    v-model="user.terms"
                    name="terms"
                    value="accepted"
                    unchecked-value="not_accepted"
                >
                Aceitar os termos de uso*
                </b-form-checkbox>
                <br>
                <span class="text-danger">
                {{error.terms}}
                </span>
                 <br>
                <b-form-checkbox
                    id="terms_email"
                    v-model="user.terms_email"
                    name="termos_email"
                    value="accepted"
                    unchecked-value="not_accepted"
                >
                Aceitar recebe promoções por e-mail
                </b-form-checkbox>
            </div>
            <!--Fim do Segundo passo do Cadastro-->
            <div class="button-auth mt-3 mb-4" v-if="!showSignup">
                <b-overlay
                    :show="show"
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                >
                    <button class="btn btn-primary btn-md px-4" @click.prevent="signin">
                        <b> Entrar </b>
                        <svg width="1.5rem" height="1.5rem" fill="#eee" class="bi bi-arrow-right" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.6724 6.7727L11.1866 0.286765C11.0015 0.101623 10.7547 0 10.4916 0C10.2282 0 9.98158 0.101769 9.79644 0.286765L9.20758 0.875772C9.02258 1.06062 8.92067 1.30753 8.92067 1.57078C8.92067 1.83389 9.02258 2.08912 9.20758 2.27397L12.9913 6.06601H0.970241C0.428249 6.06601 0 6.49032 0 7.03246V7.86515C0 8.40729 0.428249 8.87438 0.970241 8.87438H13.0342L9.20772 12.6876C9.02273 12.8727 8.92081 13.1129 8.92081 13.3762C8.92081 13.6391 9.02273 13.8828 9.20772 14.0678L9.79658 14.6549C9.98173 14.8401 10.2283 14.941 10.4917 14.941C10.7549 14.941 11.0016 14.8388 11.1868 14.6536L17.6725 8.16783C17.8581 7.98211 17.9602 7.73418 17.9595 7.47063C17.96 7.20621 17.8581 6.95814 17.6724 6.7727Z" fill="#FAFAFA"/>
                        </svg>
                    </button>
                </b-overlay>
            </div>
            <div class="button-auth-signin" v-else>
                <b-overlay
                    v-if="!showSignin && showSignup"
                    :show="show"
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                >
                    <button class="btn btn-primary btn-md px-4" v-if="!showSignin && showSignup"  @click.prevent="validateRegisterOne">
                        <b> Continuar </b>
                        <svg width="1.5rem" height="1.5rem" fill="#eee" class="bi bi-arrow-right" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.6724 6.7727L11.1866 0.286765C11.0015 0.101623 10.7547 0 10.4916 0C10.2282 0 9.98158 0.101769 9.79644 0.286765L9.20758 0.875772C9.02258 1.06062 8.92067 1.30753 8.92067 1.57078C8.92067 1.83389 9.02258 2.08912 9.20758 2.27397L12.9913 6.06601H0.970241C0.428249 6.06601 0 6.49032 0 7.03246V7.86515C0 8.40729 0.428249 8.87438 0.970241 8.87438H13.0342L9.20772 12.6876C9.02273 12.8727 8.92081 13.1129 8.92081 13.3762C8.92081 13.6391 9.02273 13.8828 9.20772 14.0678L9.79658 14.6549C9.98173 14.8401 10.2283 14.941 10.4917 14.941C10.7549 14.941 11.0016 14.8388 11.1868 14.6536L17.6725 8.16783C17.8581 7.98211 17.9602 7.73418 17.9595 7.47063C17.96 7.20621 17.8581 6.95814 17.6724 6.7727Z" fill="#FAFAFA"/>
                        </svg>
                    </button>
                </b-overlay>
                <b-overlay
                    v-if="showSignin"
                    :show="show"
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                >
                    <button class="btn btn-primary btn-md px-4" v-if="showSignin" @click.prevent="signup">
                        <b> Cadastrar </b>
                        <svg width="1.5rem" height="1.5rem" fill="#eee" class="bi bi-arrow-right" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.6724 6.7727L11.1866 0.286765C11.0015 0.101623 10.7547 0 10.4916 0C10.2282 0 9.98158 0.101769 9.79644 0.286765L9.20758 0.875772C9.02258 1.06062 8.92067 1.30753 8.92067 1.57078C8.92067 1.83389 9.02258 2.08912 9.20758 2.27397L12.9913 6.06601H0.970241C0.428249 6.06601 0 6.49032 0 7.03246V7.86515C0 8.40729 0.428249 8.87438 0.970241 8.87438H13.0342L9.20772 12.6876C9.02273 12.8727 8.92081 13.1129 8.92081 13.3762C8.92081 13.6391 9.02273 13.8828 9.20772 14.0678L9.79658 14.6549C9.98173 14.8401 10.2283 14.941 10.4917 14.941C10.7549 14.941 11.0016 14.8388 11.1868 14.6536L17.6725 8.16783C17.8581 7.98211 17.9602 7.73418 17.9595 7.47063C17.96 7.20621 17.8581 6.95814 17.6724 6.7727Z" fill="#FAFAFA"/>
                        </svg>
                    </button>
                </b-overlay>
            </div>
            <div class="text-bottom-auth my-lg-2" v-if="!showSignup">
                <p>
                    Não possui conta? 
                    <a class="text-decoration-none font-weight-bolder" primary-color href @click.prevent="showSignup = !showSignup">
                        Cadastre-se
                    </a>
                </p>
                <br/>
            </div>
            <div class="text-bottom-auth my-lg-2" v-else>
                <p>
                    Já tem cadastro? 
                    <a class="text-decoration-none" href @click.prevent="showSignup = !showSignup">
                        Acesse o Login!
                    </a>
                </p><br/>                
            </div>
            <div class="text-bottom-auth" v-if="!showSignup">
                <p>
                    <a class="text-decoration-none color-none-black" href @click.prevent="rememberAction">
                        Esquece minha senha.
                    </a> 
                </p>            
            </div>
            <b-row align-h="between" class="my-4" v-if="!showSignup">
                <b-col md="5" sm="8">
                    <div class="button-google">
                        <div class="m-auto">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13px" height="13px" fill="currentColor" class="bi bi-google" viewBox="0 0 16 16">
                                <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
                            </svg>    
                        </div>
                        <div class="share-google" height="100"></div>
                        <div class="m-auto">
                            <p class="text-button-login"> Entra com o google</p>
                        </div>
                    </div>
                </b-col>
                <b-col md="5" sm="8">
                    <div class="button-facebook sm-control">
                        <div class="m-auto">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                            </svg>
                        </div>
                        <div class="share-facebook" height="100"></div>
                        <div class="m-auto">
                            <p class="text-button-login"> Entra com o facebook</p>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </form>
        <div class="text-center">
            <b-navbar-brand :href=link class="mt-5" >
                <img src="@/assets/img/Logotipo..png" alt="Logotipo" width="140">
            </b-navbar-brand>
        </div>
    </div>
  </div>
</template>
<script>
import Login from '../../mixins/Login'
import Title from '../containers/Title'
export default {
    name: 'LoginConsult',
    props:['status', 'rememberAction', 'who', 'link'],
    components:{Title},
    data: function(){
        return {
            user:{
                type: 'freelancers',
                name:'',
                email:'',
                password:'',
                password_confirm:'',
                terms:'',
                terms_email: ''
            },
            showTop:false,
            showLoading:false,
            show: false,
            showSignup:this.status,
            showSignin:false,
            activeStrength:false,
            strengthPassword: null,
            headers:{"Accept": "application/x-www-form-urlencoded"},
            error : {name:"", email:"", password: "", password_confirm: "", birthdate: "", },
            regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
       }
    },
    mixins:[Login]
}
</script>
<style >
.login-user-img{
    height: 120vh;
    background: url(../../assets/img/a4.png), no-repeat;
    background-size:cover;
}
.over{
    position: fixed !important;
    width: 100% !important;
    z-index: 2000 !important;
    top: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    opacity: 0.9 !important;
    background-color: #4855bb !important;
}
.over-content{
    margin: 10rem auto !important;
}
</style>
<template>
    <div dashboard>
        <b-alert
            v-model="showTop"
            class="position-fixed fixed-top m-0 rounded-0"
            style="z-index: 2000;"
            variant="success"
            dismissible
        >
            Agora você esta hábio para finalizar sua compra.
        </b-alert>
        <Title
            name="Perfil"
        />
        <div class="container">
            <b-card title="Card Title" no-body class="mr-3 my-3">
                <b-card-header header-tag="nav" card-headerr>
                    <b-nav class="d-md-flex d-none">
                        <!--path: '/perfil/:id/home' -->
                        <b-nav-item 
                            :to="{path: `/perfil/${this.perfil.id}/about`, params: {id: perfil.id}}" 
                            active-class="active" 
                            exact
                        >
                            PERFIL E SERVIÇOS
                        </b-nav-item>
                        <div class="border-vertical"></div>
                        <b-nav-item 
                            :to="{path:`/perfil/${this.perfil.id}/avaliations`, params: {id: perfil.id}}" 
                            active-class="active" 
                            exact
                        >
                            AVALIAÇÕES
                        </b-nav-item>
                        <div class="border-vertical"></div>
                        <b-nav-item 
                            :to="{path:`/perfil/${this.perfil.id}/certificates`, params: {id: perfil.id} }" 
                            active-class="active" 
                            exact
                        >
                            CERTIFICADOS
                        </b-nav-item>
                        <div class="border-vertical"></div>
                    </b-nav>
                    <b-nav class="d-md-none d-flex">              
                        <b-nav-item-dropdown
                            id="carreiras-nav-dropdown"
                            :text="`${$route.name}`"
                            toggle-class="nav-link-custom"
                            @click.prevent="status = !status"
                            active-class="active"
                        >
                            <b-dropdown-item>
                                <b-nav-item 
                                    :to="{path: `/perfil/${this.perfil.id}/about`, params: {id: perfil.id}}"
                                    exact
                                >
                                    PERFIL E SERVIÇOS
                                </b-nav-item>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <b-nav-item 
                                    :to="{path:`/perfil/${this.perfil.id}/avaliations`, params: {id: perfil.id}}"
                                    exact
                                >
                                    AVALIAÇÕES
                                </b-nav-item>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <b-nav-item 
                                    :to="{path:`/perfil/${this.perfil.id}/certificates`, params: {id: perfil.id} }"
                                    exact
                                >
                                    CERTIFICADOS
                                </b-nav-item>
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                        <i class="fa fa-chevron-up icon-dropdown" aria-hidden="true" style="margin-top:0.3rem"></i>
                    </b-nav>
                </b-card-header>

                <b-card-body>
                    <transition mode="out-in"
                        enter-active-class="animate__animated animate__fadeIn"
                        leave-active-class="animate__animated animate__fadeOut"
                    >
                        <router-view/>
                    </transition>
                </b-card-body>
            </b-card>
        </div>
    </div>
</template>
<script>
import Title from '../components/containers/Title'
import {profilePurchase} from '@/global'
export default {
    name:'Perfil',
    components:{Title},
    data() {
        return {
            perfil:{},
            showTop:false,
        }
    },
    methods: {
        status(){
            if(this.$route.query.buy){
                this.showTop = true
                localStorage.removeItem(profilePurchase)
            }
        }
    },
    created() {
        this.status()
        this.perfil.id = this.$route.params.id
    }
}
</script>
<style scoped>
.border-vertical{
    height: 5vh;
    background: #ececec;
    width: 1px;
}
[card-headerr]{
    background: #FFFFFF !important;
    border: 1px solid #ECECEC !important;
    box-sizing: border-box !important;
    border-radius: 6px 6px 0px 0px !important;
}
b-nav-item, li{
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #6978F4 !important;
}
.card-header{
    padding:0.75rem 0rem !important
}
.dropdown-menu .show{
    margin-top: 0.8rem !important
}
#carreiras-nav-dropdown a.nav-link-custom{
    color:#007bff !important;
}
.nav-item a.router-link-exact-active,[active]{
    border: 0px 1px 0px 1px solid #ECECEC !important;
    color: #007bff !important;
}
.nav-item a[role="button"]{
    background: none !important;
}
.nav-item .b-nav-dropdown .dropdown .show{
    color: #007bff !important;
}
</style>
import {baseApiUrl,showError,showSuccess,showInfo, userKey} from '@/global'
import {mapState} from 'vuex'
import axios from 'axios'
export default {
    computed:mapState(['user', 'freelancer']),
    data(){
        return{
            mode: 'view',
            required:false,
            newService:{},
            service:{},
            showView: 'processing',
            approval:{},
            files:'',
            fileTwo:'',
            processing:{},
            loading:true,
            show:false,
            sendProgress: 0,
            numberDoc: 0,
            errorDocOne:'',
            dismissible:'',
            showDismissibleAlert:false,
            opcionalStatus:true,
            opcionalMsg:''
        }
    },
    methods:{
        proceed(){
            this.showView = 'info'
        },
        overlay(){
            this.show = true
            let self = this
            setTimeout(function(){
                self.show = false
            }, 2000)
        },
        returnProcced(){
            this.required = false
            this.showView = 'processing'
            this.newService = {}
            this.files = ''
            this.fileTwo = ''
            this.mode = 'view'
        },
        loadService(){
            const url = `${baseApiUrl}/services/${this.user.id}/free`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res => {
                if(res.data.error == "" ){
                    this.loading = false
                    this.service = res.data.services
                }else{
                    this.loading = false
                    this.mode = res.data.approval[0].certificates_one != null ? 'processing' : 'lackdoc'
                    this.processing = res.data.approval[0]
                    this.newService = res.data.approval[0].services[0]
                    if(this.mode == 'lackdoc'){
                        this.$toasted.show(
                            `Você já iniciou envio de uma avaliação para ${this.newService.name} desaja continua?,
                            basta envia os documentos`,
                            { 
                                theme: "toasted-primary", 
                                position: "top-center", 
                                class:'font-weight-bolder',
                                duration : null,
                                //icon:'check_circle'
                                action:[
                                    {
                                        text : 'Fecha',
                                        onClick : (e, toastObject) => {
                                            toastObject.goAway(0);
                                        }
                                    },
                                    // Permanecer
                                    /*
                                    {
                                        text : 'Não sei',
                                        // router navigation
                                        push : { 
                                            name : 'somewhere',
                                            // this will prevent toast from closing
                                            dontClose : true
                                        }
                                    }*/
                                ]
                            }
                        );
                    }
                }
            }).catch(showError)            
        },
        add(){
            console.log(this.newService)
            // :class="{'selectSer' : mode === 'select'}"
        },
        setService(services,index){
            this.newService = { ...services}
            const sle = document.querySelectorAll('.select')
            
            this.approval = {
                'id_user': this.user.id,
                'name': this.user.name,
                'id_service': this.newService.id,
            }
            const filt = Array.prototype.filter.call(sle, function(item){
                return item.classList.contains('selectSer')
            })
            if(filt != ''){
                filt[0].classList.remove('selectSer')
            }
            console.log(sle[index])
            
           
            sle[index].classList.add('selectSer')
            this.mode = 'select'
        },
        /*
        getImage(e){     
            let image = e.target.files[0]
            this.selectFile = image;
            this.form.name = this.selectFile.name
            let reader = new FileReader()
            reader.readAsDataURL(image);
            reader.onload = e =>{
                this.freelancer.avatar = e.target.result
                this.setImage = false
            }
        },*/
        getDocument(e){
            let doc = e.target.files[0]
            this.files = doc            
        },
        getDocumentTwo(e){
            let doc = e.target.files[0]
            this.fileTwo = doc
        },
        uploadDoctwo(){
            console.log(this.uploadDoctwo.name)
            this.show = true
            if(this.fileTwo){
                this.loading  = true
                const urlDocTwo = `${baseApiUrl}/approval/opcionalDoc`
                
                let formDataTwo = new FormData();
                formDataTwo.append('certificates_two', this.fileTwo, this.fileTwo.name)
                
                axios.post(urlDocTwo, formDataTwo, {
                    onUploadProgress:uplaodEvent => {
                        console.log('progresso docTwo >'+Math.round(uplaodEvent.loaded/uplaodEvent.total *100)+'%' )
                        this.sendProgress = Math.round(uplaodEvent.loaded/uplaodEvent.total *100)
                        this.numberDoc = 1
                    }
                }).then(res=>{
                    if(res.data.status === 200){
                        showSuccess(res)
                    }
                    this.loading  = false
                    this.showView = 'avaliation'
                    this.mode = 'avaliation'
                }).catch(e=>{
                    showError(e)
                    this.opcionalStatus = false

                    this.opcionalMsg = 'Selecione o arquivo, PDF ou JPEG ou PNG.'

                    this.show = false

                    this.loading = false
                })
            }else{
                this.opcionalStatus = false
                this.opcionalMsg = 'Selecione o arquivo, PDF ou JPEG ou PNG.'
                showInfo('Selecioner o arquivo!')
                this.show = false
            }
        },
        
        // TRANTANDO PRIMEIRA REQUISIÇÃO A API COM DOCUMENTO
        uploadDocOne(doc){
            console.log(this.uploadDocOne.name, 'estou no uploadDocOne')
            const urlDocOne = `${baseApiUrl}/approval/document`
            let formData = new FormData();
            formData.append('certificates_one', doc, doc.name)

            axios.defaults.headers.common['accept'] = 'form-data'
            console.log('DocOne>', formData)
            
            axios.post(urlDocOne, formData, {
                onUploadProgress:uplaodEvent => {
                    console.log('progresso DocOne >'+Math.round(uplaodEvent.loaded/uplaodEvent.total *100)+'%' )
                    this.sendProgress = Math.round(uplaodEvent.loaded/uplaodEvent.total *100)
                    this.numberDoc = 1
                }
            }).then(res=>{
                if(this.files && !this.fileTwo){
                    showSuccess('Primeiro arquivo, recebido ...!')
                    this.showView = (this.files && !this.fileTwo) ? 'avaliation' : 'info'
                    this.loading  = (this.files && !this.fileTwo) ? false : true
                    console.log('estou aquii')
                }else{
                    console.log(res)
                    this.errorDocOne = res.error
                    return res.data.success
                }
            }).catch( e=>{
                showError(e)
                this.loading = false
                this.mode = 'select'
                //this.showView = 'lackdoc'

            }).finally(e=>{this.sendProgress = 0})
        },
        async upload(){ 
            console.log(this.upload.name)
            
            this.overlay()
       
            this.loading = true
            //enviando somente dados do usuario          
            const url = `${baseApiUrl}/approval`
           
            const auth = JSON.parse(localStorage.getItem(userKey))
            
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            // Proteção do front para não envia sem documentos
            if(!this.files && !this.fileTwo){
                this.required = true
                showInfo('Selecione um documento ou foto do seu certificado!')
                this.loading = false
            }else{
                //let sendData = await (this.approval) ? this.approval : this.computeData(this.newService)
                if(Object.values(this.approval).length === 0){
                    this.approval = {
                        'id_user': this.user.id,
                        'name': this.user.name,
                        'id_service': this.newService.id,
                    }
                    const requisitionOne = await axios.post(url, this.approval).catch(e=>{
                        showError(e)
                        this.loading = false
                    })
                    
                    console.log('passo 1 ', requisitionOne)
                }else{
                    
                    const requisitionOne = await axios.post(url, this.approval).catch(e=>{
                        showError(e)
                        this.loading = false
                    })
                    console.log('passo 1 ', requisitionOne)
                }
                /**
                 * Segunda requisição enviando arquivo 1
                 */
                const urlDocOne = `${baseApiUrl}/approval/document`
                let formData = new FormData();
                formData.append('certificates_one', this.files, this.files.name)

                axios.defaults.headers.common['accept'] = 'form-data'

                const requisitionTwo = await axios.post(urlDocOne, formData, {
                    onUploadProgress:uplaodEvent => {
                        console.log('progresso DocOne >'+Math.round(uplaodEvent.loaded/uplaodEvent.total *100)+'%' )
                        this.sendProgress = Math.round(uplaodEvent.loaded/uplaodEvent.total *100)
                        this.numberDoc = 1
                        this.mode = 'avaliation'
                        this.showView = (this.files && !this.fileTwo) ? 'avaliation' : 'info'
                        this.loading  = (this.files && !this.fileTwo) ? false : true
                    }
                }).catch(e=>{
                    showError(e)
                   
                    this.showView = 'info'
                    this.mode = 'select'
                    this.loading = false
                })
                
                console.log('passo 2 ', requisitionTwo)

                /**
                 * Terceira requisição enviando arquivo 2
                 */
                if(this.fileTwo){
                    if(requisitionTwo.data.success){
                    const urlDocTwo = `${baseApiUrl}/approval/opcionalDoc`
                    
                        let formDataTwo = new FormData();
                        formDataTwo.append('certificates_two', this.fileTwo, this.fileTwo.name)
    
                        const requisitionThree = await axios.post(urlDocTwo, formDataTwo, {
                            onUploadProgress:uplaodEvent => {
                                console.log('progresso DocOne >'+Math.round(uplaodEvent.loaded/uplaodEvent.total *100)+'%' )
                                this.sendProgress = Math.round(uplaodEvent.loaded/uplaodEvent.total *100)
                                this.numberDoc = 2
                            }
                        }).catch(e=>{
                            showError(e)
                            this.showDismissibleAlert = true
                            this.dismissible = 'O documento opcional esta com erro, não tem problema iremos usar o seu 1, se quiser reenviar, clicando novamente no ADICIONAR SERVIÇOS'
                            this.showView = 'avaliation'
                            this.loading = false
                        })    
                        console.log('passo 3 ', requisitionThree)
                    }else{
                        console.log('ESTOU NO ERRRO da requisitionOne')
                        showInfo('ESTOU No else da requisitionOne')
                    }
                }
                
            }
        },
        finalyProcess(){
            console.log(this.finalyProcess.name)
            this.loading = true
            console.log('DELETE ',this.processing)
            const url = `${baseApiUrl}/approval/${this.processing.id}`
            axios.delete(url, {
                onUploadProgress:uplaodEvent => {
                    console.log('progresso em deleta >'+Math.round(uplaodEvent.loaded/uplaodEvent.total *100)+'%' )
                    this.sendProgress = Math.round(uplaodEvent.loaded/uplaodEvent.total *100)
                }
            }).then(res=>{
                if(res.data.status === 200){
                    showSuccess(res)
                }
                this.returnProcced()
                this.loadService()
            }).catch(e=>{
                showError(e)

                this.show = false

                this.loading = false
            })
        }
    },
    mounted() {
        this.loadService()
    }
}
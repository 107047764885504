import {baseApiUrl,showError,showSuccess,showInfo, userKey} from '@/global'
import {mapState, mapActions} from 'vuex'

export default {
    
    data() {
        return {
           
        }
    },
    methods: {
        ...mapActions('cookies', ['verifyStatus', 'configStatus'])
    },
    created() {
        this.verifyStatus()
    },
}
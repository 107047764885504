<template>
    <div class="SalesOpen">
        <div v-if="this.sales != ''">
            <b-skeleton-img v-if="this.loading === false"></b-skeleton-img>
            <div v-for="sale in sales" :key="sale.index" v-else>
                <Shedule
                    :icone=sale.service.icon
                    :service=sale.service.name
                    :description=sale.service.description
                    :id=sale.id
                    :who_for=sale.who_for.name
                    :avatar=sale.who_for.avatar
                    :start=sale.start
                    :data="sale.datetime | formatDate"
                    :end_data=sale.end_time
                    :loading="loading"
                />
            </div>
        </div>
        <div v-else class="notset text-center">
            <NotSet 
                frase="Você não tem nenhuma compra em aberto."
            />
        </div>
    </div>
</template>
<script>
import NotSet from '../containers/NotSet'
import Shedule from '../containers/Shedule'
import {baseApiUrl,showError, userKey} from '@/global'
import {mapState} from 'vuex'
import axios from 'axios'
export default {
    name:'SalesOpen',
    components:{Shedule, NotSet},
    data (){
        return{
            sales:null,
            loading:false
        }
    },
    methods: {
        get(){
            const url =  `${baseApiUrl}/sales`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res =>{
                if(res.data.sales){
                    this.sales = res.data.sales
                    this.loading = true
                }
            }).catch(showError)
        }
    },
    created() {
        this.get()
    }
    
}
</script>
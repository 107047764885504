<template>
    <div class="notSet">
        <b-row align-h="center">
        <b-col lg="10" sm="12">
            <svg enable-background="new 0 0 512 512" fill="#4855BB" height="3rem" viewBox="0 0 512 512" width="3rem" xmlns="http://www.w3.org/2000/svg"><path d="m375 220c-11.046 0-20-8.954-20-20 0-5.514-4.486-10-10-10s-10 4.486-10 10c0 11.046-8.954 20-20 20s-20-8.954-20-20c0-27.57 22.43-50 50-50s50 22.43 50 50c0 11.046-8.954 20-20 20zm-157-20c0-27.57-22.43-50-50-50s-50 22.43-50 50c0 11.046 8.954 20 20 20s20-8.954 20-20c0-5.514 4.486-10 10-10s10 4.486 10 10c0 11.046 8.954 20 20 20s20-8.954 20-20zm170.461 275.107c9.448-5.723 12.467-18.021 6.745-27.468-5.723-9.448-18.022-12.468-27.468-6.745-33.595 20.35-72.233 31.106-111.738 31.106-119.103 0-216-96.897-216-216s96.897-216 216-216 216 96.897 216 216c0 42.589-12.665 84.044-36.626 119.885-6.139 9.183-3.672 21.603 5.511 27.742 9.183 6.14 21.604 3.671 27.742-5.511 28.374-42.442 43.373-91.585 43.373-142.116 0-68.38-26.629-132.667-74.98-181.02-48.353-48.351-112.64-74.98-181.02-74.98s-132.667 26.629-181.02 74.98c-48.351 48.353-74.98 112.64-74.98 181.02s26.629 132.667 74.98 181.02c48.353 48.351 112.64 74.98 181.02 74.98 46.813 0 92.617-12.757 132.461-36.893zm-46.461-184.107c-6.512 0-12.28 3.127-15.932 7.945 0 0-26.018 28.055-70.068 28.055s-70.068-28.055-70.068-28.055c-3.652-4.818-9.42-7.945-15.932-7.945-11.046 0-20 8.954-20 20 0 5.691 2.388 10.814 6.204 14.456 3.617 4.012 39.099 41.544 99.796 41.544s96.179-37.532 99.796-41.544c3.816-3.642 6.204-8.765 6.204-14.456 0-11.046-8.954-20-20-20z"/></svg>
        </b-col>
        <b-col lg="10" sm="12">
            <h5>Nada aqui...</h5>
            <p class="text-muted">{{frase}} </p>
        </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    name:'NotSet',
    props:['frase']
}
</script>
<style>
    
</style>
<template>
    <div class="overview" dashboard>
       <Title
        name="Visão Geral"
       />
        <b-container class="border rounded p-4 bg-white m-0">
            <Calendar/>
        </b-container>
        <b-container class="border rounded p-4 bg-white mt-4">
            <Chart
            />
        </b-container>
        <Payment
            :toreceiver=freelancer.toreceiver
            :receiver=freelancer.receiver
            :countToReceiver=freelancer.countToReceiver
            :countReceiver=freelancer.countReceiver
        />
        <!--<Transactions/>-->
    </div>
</template>

<script>
import Title from '../components/containers/Title'
import Calendar from '@/components/containers/Calendar'
import Chart from '../components/containers/Chart'
import Payment from '../components/containers/Payments'
import Transactions from '../components/containers/Transactions'
import {mapState} from 'vuex'
export default {
    name: 'Overview',
    computed:mapState(['freelancer']),
    components:{Calendar, Title, Chart, Payment, Transactions}
}
</script>
<style>

</style>
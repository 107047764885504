<template>
    <div class="shedules">
        <CounterTime
            v-if="total.final !== true"
            :final=total.final
            :datetime=total.datetime
            :callExist=room_chat
            @runCall="room = $event"
        />
        <b-row v-if="total.final !== false && this.user.type === 'freelancers' && total.evaluation === null" class="text-center">
            <b-col>
                <p class="text-muted">Peça a(o) {{who_for}} que o avalie.</p>
            </b-col>
        </b-row>
        <Avaliation 
            v-if="total.final !== false && this.user.type === 'candidaters' && total.evaluation === null"
            :shedules=id
            :freelancer=who_for
        />
        <Testimonials
            v-if="total.evaluation !== null"
            :testimonials=total.evaluation
        />
        <b-row align-h="between">
            <b-col align-self="start" md="7" >
                <div class="d-flex">
                    <div class="my-auto">
                        <img :src="icone | imageURL" class="p-2 mr-3 rounded" bg-mprimary width="50" height="50">
                    </div>
                    <b-badge class="p-2 my-auto  text-dark" badge-minfo>
                        <p class="h6 font-weight-bolder"> {{status}}</p>
                        <p class="font-weight-bolder"> {{date_pay}}</p>
                    </b-badge >
                </div>
                <p class="my-2 h5 font-weight-bolder">{{service}}</p>
                <p class="my-2 h6 font-weight-bolder text-muted">{{resume}}</p>
                <p class="my-2 text-muted">{{description}}</p>
            </b-col>
            <b-col  md="4" align-center="end">
                <b-button :disabled="room_chat !== ''" @click="setCalendar" style="border-radius:0.4rem !important;color:#fff !important" bg-mprimary >
                <div  class="d-flex">
                    <p class="pr-1 border-right">{{data}} <br>
                    {{start}} - {{end_date}}
                    </p>
                    <svg fill="gray" version="1.1" class="p-2" width="2.5rem" height="2.5rem" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 477.873 477.873" style="enable-background:new 0 0 477.873 477.873;" xml:space="preserve">
                            <path d="M392.533,238.937c-9.426,0-17.067,7.641-17.067,17.067V426.67c0,9.426-7.641,17.067-17.067,17.067H51.2
                                c-9.426,0-17.067-7.641-17.067-17.067V85.337c0-9.426,7.641-17.067,17.067-17.067H256c9.426,0,17.067-7.641,17.067-17.067
                                S265.426,34.137,256,34.137H51.2C22.923,34.137,0,57.06,0,85.337V426.67c0,28.277,22.923,51.2,51.2,51.2h307.2
                                c28.277,0,51.2-22.923,51.2-51.2V256.003C409.6,246.578,401.959,238.937,392.533,238.937z"/>
                        
                            <path d="M458.742,19.142c-12.254-12.256-28.875-19.14-46.206-19.138c-17.341-0.05-33.979,6.846-46.199,19.149L141.534,243.937
                                c-1.865,1.879-3.272,4.163-4.113,6.673l-34.133,102.4c-2.979,8.943,1.856,18.607,10.799,21.585
                                c1.735,0.578,3.552,0.873,5.38,0.875c1.832-0.003,3.653-0.297,5.393-0.87l102.4-34.133c2.515-0.84,4.8-2.254,6.673-4.13
                                l224.802-224.802C484.25,86.023,484.253,44.657,458.742,19.142z"/>
                        </svg>
                </div>
                </b-button>
                <br>
                <div v-if="this.user.type === 'freelancers'">
                <router-link v-if="chat_id != ''" :to="{path: `/message/chat/${chat_id}`, params: {id: chat_id}}" style="border-radius:0.4rem !important;color:#fff !important" bg-mprimary class="my-3 btn btn-sm">
                    <div class="d-flex ">
                        <svg height="1rem" class="mx-2 my-auto " viewBox="-21 -47 682.66669 682" width="1rem" xmlns="http://www.w3.org/2000/svg"><path d="m552.011719-1.332031h-464.023438c-48.515625 0-87.988281 39.472656-87.988281 87.988281v283.972656c0 48.421875 39.300781 87.824219 87.675781 87.988282v128.871093l185.183594-128.859375h279.152344c48.515625 0 87.988281-39.472656 87.988281-88v-283.972656c0-48.515625-39.472656-87.988281-87.988281-87.988281zm-83.308594 330.011719h-297.40625v-37.5h297.40625zm0-80h-297.40625v-37.5h297.40625zm0-80h-297.40625v-37.5h297.40625zm0 0"/></svg>
                            Mensagem
                    </div>
                </router-link>
                <b-button v-b-modal.modal-center @click="setMessage" v-else style="border-radius:0.4rem !important;color:#fff !important" bg-mprimary class="my-3 btn btn-sm">
                    <div class="d-flex ">
                        <svg height="1rem" fill="CurrentColor" class="mx-2 my-auto " viewBox="-21 -47 682.66669 682" width="1rem" xmlns="http://www.w3.org/2000/svg"><path d="m552.011719-1.332031h-464.023438c-48.515625 0-87.988281 39.472656-87.988281 87.988281v283.972656c0 48.421875 39.300781 87.824219 87.675781 87.988282v128.871093l185.183594-128.859375h279.152344c48.515625 0 87.988281-39.472656 87.988281-88v-283.972656c0-48.515625-39.472656-87.988281-87.988281-87.988281zm-83.308594 330.011719h-297.40625v-37.5h297.40625zm0-80h-297.40625v-37.5h297.40625zm0-80h-297.40625v-37.5h297.40625zm0 0"/></svg>
                            Mensagem
                    </div>
                </b-button>
                </div>
            </b-col>
        </b-row>
        <hr>
        <b-row align-h="between">
            <b-col align-self="start" >
                <div class="d-flex">
                    <div class="my-auto">
                        <img :src="avatar | imageURL" class="rounded" width="40" height="40">
                    </div>
                    <div class="my-auto ml-3">
                        {{who_for}} 
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-modal 
            id="modal-xl" 
            size="xl"
            hide-header 
            hide-footer
            no-close-on-backdrop
            no-close-on-esc
        >
        <b-row align-h="end">
            <b-col cols="2">
                <span @click="$bvModal.hide('modal-xl')" style="cursor:pointer !important;display:flex !important;justify-content:end !important">
                    <svg width="33" height="33" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11.5" cy="11.5" r="11.5" fill="#FAFAFA"/>
                    <path d="M8 8L14.5 14.5" stroke="#292841" stroke-width="2"/>
                    <path d="M14.5 8L8 14.5" stroke="#292841" stroke-width="2"/>
                    </svg>
                </span>
            </b-col>
        </b-row>
            <CallVideo
                :room="this.room_chat != null ? this.room_chat : this.room"
                :id=id
                :sub_title="this.service"
            />
            <div class="call-msg" >
                <div class="px-5 py-2 d-flex justify-content-between" @click.prevent="call" >
                    <h1 class="h4 font-weight-bolder" text-mprimary>Mensagens</h1>
                    <svg 
                        :class="{'closed' : sta == false, 'open' : sta == true}"
                        xmlns="http://www.w3.org/2000/svg" 
                        width="2rem" height="1.5rem" fill="#4855BB" class="bi bi-arrow-right" viewBox="0 0 16 16"
                    >
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>
                </div>
                <ChatWindow
                    :class="{'d-none' : sta == false}"
                    :chat_id=chat_id
                />
            </div>
        </b-modal>
    </div>
</template>
<script>
import CounterTime from './shedules/CounterTime'
import Avaliation from './shedules/Avaliation'
import Testimonials from './shedules/Testimonials'
import CallVideo from '../template/Call/CallVideo'
import ChatWindow from '../template/Call/ChatWindow'
import {mapState} from 'vuex'
export default {
    name:'SheduleOne',
    props:['icone', 'service', 'description','total','resume','chat_id', 'date_pay','status','id', 'who_for','avatar', 'data', 'start', 'end_date', 'room_chat'],
    components:{CounterTime, Avaliation, Testimonials, CallVideo, ChatWindow},
    computed:mapState(['user']),
    data(){
        return{
            sta:false,
            room: this.total.who_for.presentation != undefined ? '' : new Date().getTime()+this.id,
            surpassed:''
            //surpassed:(new Date().getHours(this.total.datetime)+3) > new Date().getHours(Date.now()) ? false : true
        }
    },
    watch:{
        room(novo,antigo){
            console.log(novo,antigo)
        }
    },
    methods: {
        call(){
            if(this.sta == false){
                this.sta = true
            }else{
                this.sta = false
            }
        },
        setMessage(){
            this.$emit('getItem', this.total)
        },
        setCalendar(){
            this.$emit('getItem', this.total)
            this.$bvModal.show('modal-scoped')
        }
    },
}
</script>
<style>
.call-msg{
    position: fixed;
    height: auto;
    z-index: 2001;
    bottom: 0;
    background: #fff;
    right: 20px;
    border-radius:12px;
}
.closed{
    transform:rotate(90deg)
}
.open{
    transform:rotate(270deg);
    
}
.call-msg,.closed{
    height:auto
}
</style>
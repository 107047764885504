<template>
    <div class="services mt-5">
        <b-row align-h="between" class="mt-3 py-1 border rounded" v-for="(services, index) in service" :key="index">
            <b-col align-self="start" >
                <div class="d-flex">
                    <div class="my-auto">
                        <img 
                            :src="services.icon | imageURL" 
                            class="mr-3 rounded p-2" 
                            width="50" 
                            height="50"
                            :bg-mprimary="services.id != 1" 
                            :bg-minfo="services.id == 1"
                        >
                    </div>
                    <div class="ml-3 my-auto">
                        <b class="h5 font-weight-bolder" > {{services.name}}</b>
                        <p>{{services.resume}}</p>
                    </div>
                </div>
            </b-col>
            <b-col  md="3" offset-md="3" class="border-left my-auto" align-center="center">
                <s>R$ {{services.price}}, 00</s>
                <h6 class="h5 font-weight-bolder"> R$ {{services.price_now}}, 00</h6>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import {baseApiUrl,showError, userKey} from '@/global'
import {mapState} from 'vuex'
import axios from 'axios'
export default {
    name:'Services',
    computed:mapState(['user']),
    data(){
        return{
            service:{}
        }
    },
    methods:{
        loadPerfil(){
            const url = `${baseApiUrl}/services/${this.user.id}`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res => {
                this.service = res.data.services
            }).catch(showError)            
        },
    },
    mounted() {
        this.loadPerfil()
    }
}
</script>
<template>
    <div class="listNotPad">
        <b-overlay
            :show="loading"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-lg
            rounded="lg"
        >
        <router-link  
            bg-mprimary
            :to="{path: `NotPad/created`}" 
            class="btn text-light"
        >
            <i class="fa fa-plus-square-o" aria-hidden="true"></i>
            Cria novo
        </router-link>
        <h1 class="h4 mt-3" v-if="this.listNotPad != ''">Lista de bloco de notas criado por você !</h1>
        <h1 class="h4 mt-3" v-else>Crie bloco de notas !</h1>

        <b-container class="mt-3">
            <b-row class="justify-content-md-center">
                <b-col cols="3" class="my-2"  v-for="(item, index) in listNotPad" :key="index">
                    <div class="card select back">
                        <div class="card-body" style="padding:0.55rem !important">
                            <div class="d-flex  justify-content-between">
                                {{item.title}}
                                <div>
                                    <i class="fa fa-eye mr-2" aria-hidden="true" :id="'popover-target-previa-'+index"></i>
                                    <b-tooltip :target="'popover-target-previa-'+index" triggers="hover" >
                                        Prévia
                                    </b-tooltip>
                                    <router-link 
                                        class="router-color"
                                        :to="{path: `NotPad/edit/${item.id}`, params: {id: item.id}}" 
                                    >
                                        <i 
                                            class="fa fa-pencil-square-o mr-2" 
                                            aria-hidden="true" 
                                            :id="'tooltip-target-'+index"
                                        >
                                        </i>
                                    </router-link>
                                    <b-tooltip :target="'tooltip-target-'+index" triggers="hover" >
                                        Editar  <b>{{item.title}}</b> bloco de notas!
                                    </b-tooltip>
                                    <i @click="showMsgBoxTwo(item)" class="fa fa-trash-o" aria-hidden="true" :id="'tooltip-target-danger-'+index"></i>
                                    <b-tooltip :target="'tooltip-target-danger-'+index" triggers="hover">
                                        <b>Excluir</b>
                                    </b-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-popover 
                        :target="'popover-target-previa-'+index" 
                        triggers="click" 
                    >
                        <template #title>Prévia</template>
                        <div class="previa-content">
                            <span v-html="item.content">
                            
                            </span>
                        </div>
                    </b-popover>
                </b-col>
            </b-row>
        </b-container>
        </b-overlay>
    </div>
</template>
<script>
import {baseApiUrl,showError, userKey} from '@/global'
import {mapState} from 'vuex'
import axios from 'axios'
export default {
    name:"ListNotPad",
    data(){
        return{
            listNotPad:'',
            loading:false
        }
    },
    methods: {
        getListNotPad(){
            this.loading = true
           
            const url =  `${baseApiUrl}/notpad`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res =>{
                console.log(res.data)
                this.listNotPad = res.data
            }).finally(() => this.loading = false)
        },
        delete(e){
            let remove = this.listNotPad 
    
            const url =  `${baseApiUrl}/notepad-delete/${e.id}`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.delete(url).then(res=>{
                if(res.data.sucess){
                    console.log(e)
                   this.listNotPad = remove.filter((item) => item.id != e.id)
                    this.$toasted.info(res.data.sucess, { 
                        icon:{
                            name:'fa fa-info'
                        },
                        position: "top-right", 
                        duration : 5000
                    })    
                }
            }).finally(()=> this.loading = false).catch(showError)
        },
        showMsgBoxTwo(e){
            this.$bvModal.msgBoxConfirm(`Voce realmente deseja deleta o bloco com o titulo: ${e.title}`, {
                title: 'Tem certeza ?',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'SIM',
                cancelTitle: 'NÃO',
                footerClass: 'p-2 text-weight-bolder',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if(value){
                    this.delete(e)
                }
        
            }).finally(()=> this.loading = false)
            .catch(err => {
                // An error occurred
                console.log(err)
                this.loading = false
            })
        }
    },
    created() {
        this.getListNotPad()
    },
}
</script>
<style>
.router-color{
    color:#000;
}
.listNotPad{
    height:100vh
}
.previa-content{
    width:auto !important;
    height: 15rem !important;
    overflow-y:scroll;
}
.fa-eye:hover, router-link.fa-pencil-square-o:hover{
    color:#6978F4 !important
}
.fa-trash-o:hover{
    color:red;
}
</style>
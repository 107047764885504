<template>
    <div id="terms-cookies" bg-mprimary>
        <b-container fluid>
            <b-row class="my-4 text-center justify-content-center" align-self="center" align-h="between">
                <b-col cols="6 text-white">
                    Aceitar os termos de uso dos cookies?
                </b-col>
                <b-col cols="4">
                   <b-btn class="mx-3" @click.prevent="configStatus(false)"> Só essencial</b-btn>
                   <b-button variant="success" @click.prevent="configStatus(true)">Aceitar All</b-button>
                </b-col>
                <!-- <b-col cols="2">
                </b-col> -->
            </b-row>
        </b-container>
    </div>
</template>

<script>
import CookiesConfig from '../../../mixins/Config/CookiesConfig';
export default {
    name: "TermsCokoies",
    mixins:[CookiesConfig]
}
</script>

<style>
#terms-cookies{
    /* background: black; */
    position: fixed;
    z-index: 2001;
    left: 0px;
    bottom: 0px;
    right: 0px;
    height: 75px;
}
</style>
<template>
    <div class="m-3">
        <div class="container" v-if="loading">
            <b-row class="text-center over-content">
                <b-col align-self="center">
                    <strong>Loading ...</strong>
                    <b-spinner></b-spinner>
                    <div>
                        <strong>Enviando documento {{numberDoc}}</strong>
                        <b-progress :value="sendProgress" max="100" class="mb-3"></b-progress>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="" v-else>
            <b-alert v-model="showDismissibleAlert" variant="warning" dismissible>
                {{dismissible}}
            </b-alert>
            <div class="row mt-3 mb-4"  v-if="showView === 'avaliation'">
                <b-row align-h="center" class="text-center">
                    <b-col cols="6" class="text-success ">
                        <i class="fa fa-check fa-5x" aria-hidden="true"></i>
                    </b-col>
                    <b-col cols="8">
                        <h5 text-mprimary class="font-weight-bold">Sucesso !!!!</h5>
                        <p>Estamos preparando tudo por aqui</p>
                        <p>Logo em breve retonaremos com sua avaliação!!</p>
                    </b-col>
                </b-row>
            </div> 
            <div class="" v-if="showView === 'processing' && mode != 'processing'">
                <h3 v-if="mode != 'lackdoc'" class="font-weight-bolder h3 mb-3"> Novo serviço</h3>
                <h3 v-else class="font-weight-bolder h3 mb-3">Continua envio de documentos</h3>
                <b-row class="rounded border py-3 my-3 select"  align-v="center" v-for="(services, index) in service" :key="index">
                    <b-col v-model="newService" @click.prevent="setService(services,index, 'add')">
                        <h6 class="font-weight-bolder h5" :class="{'text-muted' : services.id == 1}">
                            <img 
                                :src="services.icon | imageURL" 
                                class="mr-3 rounded p-2" 
                                width="50" 
                                height="50" 
                                :alt="services.name" 
                                :bg-mprimary="services.id != 1" 
                                :bg-minfo="services.id == 1"
                            />
                            {{services.name}}
                        </h6>
                    </b-col>
                </b-row>
                <b-button @click.prevent="proceed" v-if="mode === 'select'" bg-mprimary>Continuar -></b-button>
            </div>
            <div v-if="mode === 'processing'">
                <h3 class="font-weight-bolder h3 mb-3"> 
                    Esta em avaliação  
                    <i class="fa fa-check fa-3x text-success" aria-hidden="true"></i>
                </h3>
                <p>Você já esta sendo avaliado para presta <b>{{processing.services[0].name}}</b></p>
                <p class="text-muted">Dentre no maximo 6h, você  terá um retorno !</p>
                Data do envio:
                <span>{{ processing.created_at | moment("DD/MM/YY") }}</span>

                <div class="mt-3" text-mprimary v-if="this.processing.certificates_two == null">
                    <p>Você envio somente um documentos, deseja envia o opcional ?</p>
                    <button v-b-toggle.collapse-1 class="btn align-self-center" bg-minfo>Clique aqui</button>
                </div>
                <b-collapse 
                   
                    id="collapse-1" 
                    class="mt-2"
                >
                    <b-card :class="{'border border-danger' : this.opcionalStatus == false}">
                        <b class="text-danger">{{opcionalMsg}}</b>
                        <input
                            type="file"
                            accept="application/pdf"
                            name="certificates_two"
                            ref="certificates_two"
                            @change="getDocumentTwo($event)"
                        >
                        <div class="d-flex flex-row-reverse">
                            <div class="p-2">
                                <b-overlay
                                    :show="show"
                                    opacity="0.6"
                                    spinner-small
                                    spinner-variant="primary"
                                    class="d-inline-block"
                                >
                                    <button class="btn btn-primary btn-md px-4" @click.prevent="uploadDoctwo">
                                        <b> Enviar </b>
                                        <svg 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            width="2rem" height="2rem" fill="currentColor" class="bi bi-arrow-right ml-2" viewBox="0 0 16 16"
                                        >
                                            <path 
                                                fill-rule="evenodd" 
                                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                            />
                                        </svg>
                                    </button>
                                </b-overlay>
                            </div>
                        </div>        
                    </b-card>
                </b-collapse>
            </div>
            <div class="" v-if="showView === 'info' || mode === 'lackdoc'  ">
                <h3 class="h6 mb-3"> Informação do serviço</h3>
                <h3 class="font-weight-bolder h6 mb-3" text-msecondary> {{newService.name}}</h3>
                <p class="mr-5" text-msecondary>{{newService.resume}}</p>
                <p class="mt-4 font-weight-bolder text-muted">PREÇO</p>
                <i class="text-muted">Porque estabelecemos o preço ?</i><br>
                <s >{{newService.price}}, 00</s>
                <p class="font-weight-bolder h3 mb-5" text-mprimary>R$ {{newService.price_now}},00</p>
                <p class="mt-4 font-weight-bolder text-muted">CERTIFICAÇÃO</p>
                <p text-msecondary>Faça upload de até 2 arquivos (Pdf/scan) que justifiquem sua qualificação para fornecer este serviço.</p>
                <div class="py-3 px-2 rounded" :class="{'no-input' : required === true}" bg-mlight>
                <!-- <b-form-file v-model="approval.certificates_one" plain></b-form-file> -->
                <input
                    type="file"
                    accept="application/pdf"
                    name="certificates_one"
                    ref="certificates_one"
                    @change="getDocument($event)" 
                >
                </div>
                <div class="py-3 px-2 my-3 rounded" :class="{'no-input-opcional' : required === true}" bg-mlight>
                    <!--<b-form-file v-model="approval.certificates_two" plain></b-form-file>-->
                    <input
                        type="file"
                        accept="application/pdf"
                        name="certificates_two"
                        ref="certificates_two"
                        @change="getDocumentTwo($event)"
                       
                    >
                    
                </div>
                <div class="row mt-3 mb-4"  v-if="mode === 'select'">
                    <b-button @click.prevent="returnProcced" bg-mlight class="col">Apagar serviço</b-button>
                    <b-overlay
                        :show="show"
                        opacity="0.6"
                        spinner-small
                        spinner-variant="primary"
                        class="d-inline-block"
                    >
                        <button class="btn btn-primary btn-md px-4 col" @click.prevent="upload">
                            <b> Enviar </b>
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" width="2rem" 
                                height="2rem" 
                                fill="currentColor" 
                                class="bi bi-arrow-right ml-2" 
                                viewBox="0 0 16 16"
                            >
                                <path 
                                    fill-rule="evenodd" 
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                />
                            </svg>
                        </button>
                    </b-overlay>
                </div>
                <div class="row mt-3 mb-4"  v-if="mode === 'lackdoc'">
                    <b-button @click.prevent="finalyProcess" bg-mlight class="btn btn-primary btn-md px-4 col">Selecionar outro</b-button>
                    <b-overlay
                        :show="show"
                        opacity="0.6"
                        spinner-small
                        spinner-variant="primary"
                        class="d-inline-block"
                    >
                        <button class="btn btn-primary btn-md px-4 col" @click.prevent="upload">
                            <b> Enviar </b>
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="2rem" height="2rem" fill="currentColor" class="bi bi-arrow-right ml-2" viewBox="0 0 16 16"
                            >
                                <path 
                                    fill-rule="evenodd" 
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                />
                            </svg>
                        </button>
                    </b-overlay>
                </div>        
            </div>
        </div>
    </div>
</template>
<script>
import UploadService from '../../mixins/Config/UploadService'
export default {
    name:'SelectService',
    mixins:[UploadService],
}
</script>
<style>
    .select{
        cursor: pointer;
    }
    .select:hover{
        border:3px solid #4855BB !important;
    }
    .selectSer{
        border:3px solid #4855BB !important;
    }
    .no-input{
        border:2px solid red;
    }
    .no-input::after{
        content: 'obrigatorio';
        color:red;
        display: flex;
        justify-content: center;
    }
    .no-input-opcional{
        border:2px solid #4855BB;
    }
    .no-input-opcional::after{
        content: 'opcional';
        color:#4855BB;
        display: flex;
        justify-content: center;
    }
</style>
<template>
    <div class="messsage-call">
        <div v-if="this.loading === false">
            <b-skeleton-img v-if="this.loading === false"></b-skeleton-img>
        </div>
        <div v-if="this.messages != null">
            <CallList v-for="(message, index) in messages" :key="index" 
                :name=message.title
                :img="message.image | imageURL"
                :msg=message.lastMessage
                :dateLast_msg="message.lastMessageData | formatDate"
                :hour_msg="message.lastMessageData | formathourMinutes"
                :id="message.chatId"
                :status="onlineItems.indexOf(message.title) > -1 ? true : false"
            />
        </div>
        <div v-else class="notset text-center">
            <NotSet
                frase="Por enquanto você ainda não possui nenhuma conversa."
            />
        </div>
    </div>
</template>
<script>
import NotSet from '../containers/NotSet'
import CallList from '../containers/CallList'
import {baseApiUrl,showError, userKey} from '@/global'
import {mapState} from 'vuex'
import axios from 'axios'
export default {
    name:'MessageCall',
    components:{CallList,NotSet},
    computed:mapState(['user', 'chatUser', 'onlineItems']),
    data(){
        return {
            loading:false,
            messages:null,
            online:''

        }
    },methods: {
        getCall(){
            const url =  `${baseApiUrl}/chat`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.get(url).then(res =>{
            
                this.messages = res.data
                console.log(this.messages)
               
                
            }).catch(e=>showError(e)).finally(()=>this.loading = true)
        }
    },
    mounted() {
        this.getCall()
    },
}
</script>
<style>
.card-img{
    width: 50 !important;
}
</style>
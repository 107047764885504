<template>
    <div class="created-notPad">
        <b-container>
            <CreateEdit/>
        </b-container>
    </div>
</template>
<script>
import CreateEdit from '../../containers/notPad/CreateEdit'
export default {
    name:"Created",
    components:{CreateEdit}
}
</script>
<style>

</style>
<template>
    <div class="auth-email">
        <b-alert
            v-model="showTop"
            class="position-fixed fixed-top m-0 rounded-0"
            style="z-index: 2000;"
            variant="success"
            dismissible
        >
            {{res}}
        </b-alert>
        <b-overlay
            :show="show"
            variant="transparent"
            rounded="lg"
        >
        <b-container>
            <b-row align-v="center" class="text-center mb-3">
                <b-col cols="12">
                    <h5 class="font-weight-bolder">Olá, seja bem vindo <br/>{{name}}</h5>
                    <p>Falta um passo para voce está utilizando nossa plataforma e agrecar valor a sua vida profissional!</p>
                </b-col>
                <b-col cols="12">
                    <p> Enviamos um email, para {{email}}. Digite o codigo que contem nele para fazer a autentificação!</p>
                </b-col>
                <b-col cols="12">
                    <p class="text-muted">Não esquece de verifica sua caixa de spam.</p>
                </b-col>
                <b-col cols="12">
                    <p>
                        Não recebeu o email ?
                        <a href="#" @click.prevent="resend">reenviar</a>
                    </p>
                </b-col>
            </b-row>
            <b-row class="text-center" align-v="center" align-h="center">
                <b-col cols="1">
                    <input type="text" name="code"  v-model="AuthCode.code" maxlength='5' autofocus  class="code">
                    <!-- <input name="code" autocomplete="off" :class="{'border border-danger' : this.failed === true && this.one === ''}" id="one" class="code mr-2" v-model="one" v-mask="vcode">
                    <input name="code" autocomplete="off" :class="{'border border-danger' : this.failed === true && this.two === ''}" id="two" class="code mr-2" v-model="two" v-mask="vcode">
                    <input name="code" autocomplete="off" :class="{'border border-danger' : this.failed === true && this.tree === ''}" id="tree" class="code mr-2" v-model="tree" v-mask="vcode">
                    <input name="code" autocomplete="off" :class="{'border border-danger' : this.failed === true && this.four === ''}" id="four" class="code mr-2" v-model="four" v-mask="vcode">
                    <input name="code" autocomplete="off" :class="{'border border-danger' : this.failed === true && this.five === ''}" id="five" class="code" v-model="five"  v-mask="vcode"> -->
                </b-col>
                <b-col cols="12 mt-3">
                    <b-button bg-mprimary @click="SendCode">Enviar</b-button>
                </b-col>
                <b-col cols="12 mt-3">
                    <a href="#" @click.prevent="cancel">
                        Cancelar
                    </a>
                </b-col>
            </b-row>
        </b-container>
        </b-overlay>
    </div>
</template>
<script>
import axios from "axios"
import { baseApiUrl,showSuccess,showError, userKey, baseUrl } from "@/global"
import {mapState} from 'vuex'
export default {
    name:'AuthEmail',
    props:['email', 'name'],
    data(){
        return{
            vcode:'#',
            one:'',
            two:'',
            tree:'',
            four:'',
            five:'',
            AuthCode:{
                code:''
            },
            res:'',
            showTop:false,
            failed: false,
            show:false
        }
    },
    methods: {
        overlay(){
            this.show = true
            let self = this
            setTimeout(function(){
                self.show = false
            }, 2500)
        },
        resend(){
            this.overlay()
            const url = `${baseApiUrl}/user/authEmail`
            const autentification = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${autentification.token}`
            axios.put(url).then(json=>{
                showSuccess(json.data.success)
            })
            .catch(e => showError(e.data.error))
            .finally()
        },
        SendCode(){
            this.overlay()
            if(this.AuthCode.code.length > 4){
                const url = `${baseApiUrl}/user/auth/authCodeEmail`
                const autentification = JSON.parse(localStorage.getItem(userKey))
                axios.defaults.headers.common['Authorization'] = `bearer ${autentification.token}`
                axios.put(url, this.AuthCode).then(json=>{
                    showSuccess(json.data.success)
                    this.showTop =true
                    this.res = json.data.success
                    window.location.href = `${baseUrl}`
                })
                .catch(e => {
                    showError(e)
                    this.AuthCode.code = ''
                   
                })
                .finally()
            }else{
                showError('Incompleto - falta campo')
                this.failed = true
            }
        },
        cancel(){
            this.show = true
            localStorage.removeItem(userKey)
            this.$store.commit('setUser', null)
            window.location.href =  `${baseUrl}`
        }
    },
    watch:{
        one(a,d){
            if(a.length > 0){
                document.getElementById('two').focus()
            }
        },
        two(a,d){
            if(a.length > 0){
                document.getElementById('tree').focus()
            }
        },
        tree(a,d){
            if(a.length > 0){
                document.getElementById('four').focus()
            }
        },
        four(a,d){
            if(a.length > 0){
                document.getElementById('five').focus()
            }
        }
    }
}
</script>
<style>
 .auth-email{
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
background:rgba(255, 146, 146, 0.2);
}

.code, input[name=code]{
    display: block;
    margin: 2em auto;
    padding: 1px;
    border: none;
    width: calc(5 * (1ch + 0.5ch)); /* Calculate width using CSS calc() */
    background: repeating-linear-gradient(90deg,
        dimgrey 0, dimgrey 1ch,
        transparent 0, transparent 1.5ch)
        0 100%/ calc(5 * (1ch + 0.5ch) - 0.5ch) 2px no-repeat; /* Adjust background sizing for calc() */
    font: 5ch;
    letter-spacing: 0.5ch;
}
input[name=code]:focus{
    outline: none;
    color: dodgerblue;
}
</style>
<template>
    <div class="account-bank">
        <b-overlay :show="show" rounded="sm">
        <div class="" v-if="mode != 'favorite'">
            <h5 class="text-weight-bolder border-bottom my-3 h4">Nova conta bancária</h5>
            <div class="form-group">
                <label for="title">Nome do titular da conta</label>
                <input v-model="account.full_name" type="text" class="form-control" id="title">
            </div>
            <div class="form-group">
                <label for="person">CPF do titular</label>
                <input type="text" class="form-control" id="person" v-model="account.person">
            </div>
            <select class="form-control" id="banks" v-model="account.bank">
                <option class="" v-for="(bank, index) in banks" :key="index">
                    {{bank.code}} - {{bank.fullName}}
                </option>
            </select>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="inputEmail4">Agencia (sem dig.)</label>
                    <input type="text" class="form-control" id="inputEmail4" placeholder="0000" v-model="account.agency">
                </div>
                <div class="form-group col-md-6">
                    <label for="inputPassword4">Nº da conta (com dig.)</label>
                    <input type="text" class="form-control" id="inputPassword4" placeholder="00000000" v-model="account.account">
                </div>
            </div>
            <select class="form-control" id="typeAccount" v-model="account.type_account">
                <option>
                    Conta Corrente
                </option>
                <option>
                    Poupança
                </option>
            </select>
            <b-row class="my-4 border-top" align-h="end" v-if="mode == 'create' ">
                <b-col cols="6">
                    <button class="btn btn-primary btn-md px-4 my-3 col" @click.prevent="sendBank">
                        <b> Salvar </b>
                        <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                        </svg>
                    </button>
                </b-col>
            </b-row>
        </div>
        <div class="" v-else>
            <b-row align-h="center" class="text-center">
                <b-col>
                    <h5 class="text-weight-bolder my-3 h2">Tem Certeza ?</h5>
                    <p class="mx-5"> Ao Selecionar esta conta como conta de saque, todos os valores recebidos irão automáticamente para ela.</p>
                </b-col>
            </b-row>
            <b-row class="my-4 border-top" align-h="center">
                <b-col cols="6">
                    <button class="btn btn-outline-dark btn-md px-4 my-3 col" @click="cancel">
                        <b> Cancelar</b>
                    </button>
                </b-col>
                <b-col cols="6">
                    <button class="btn btn-md px-4 my-3 col text-light" bg-mprimary @click="confirmFavorite">
                        <b> Confirmar </b>
                    </button>
                </b-col>
            </b-row>
        </div>
        </b-overlay>
    </div>
</template>
<script>
import {baseApiUrl,showError, userKey} from '@/global'
import axios from 'axios'
import {mapState} from 'vuex'
export default {
    name:'AccountBank',
    computed:mapState(['user']),
    props:['select', 'mode', 'favorite'],
    data(){
        return{
            show:false,
            account:{
                account: this.select.account != '' ? this.select.account : '',
                agency: this.select.agency != '' ? this.select.agency : '',
                bank: this.select.bank != '' ? this.select.bank : '',
                full_name: this.select.full_name != '' ? this.select.full_name : '',
                id: this.select.id != '' ? this.select.id : '',
                person: this.select.person != '' ? this.select.person : '',
                type_account: this.select.type_account != '' ? this.select.type_account : ''
            },
            teste:{},
            banks:'',
            options: [
                { value:'a',text: 'fullName' },
            ]
        }
    },
    methods: {
        getBanks(){
            if(this.mode != 'favorite'){
                this.show = true
                axios.get('https://brasilapi.com.br/api/banks/v1').then(res =>{
                    this.banks = res.data
                }).finally(this.show = false)
            }
            this.show = false
        },
        sendBank(){
            this.account.id_user = this.user.id
            this.show = true
            ///bank
            const url = `${baseApiUrl}/bank`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.post(url, this.account).then(res => {
                if(!res.data.success){
                    showError(res.data.error)
                    this.show = false
                }else{
                    this.$toasted.success(res.data.success, {
                        theme: "toasted-primary",
                        position: "top-center", 
                        duration : 5000,
                        icon:'check_circle'
                    })
                    this.show = false
                    this.$router.go()
                }
            }).catch(showError)
            console.log(this.account)
        },
        cancel(){
            this.$bvModal.hide('modal-center')
        },
        confirmFavorite(){
            const url = `${baseApiUrl}/bank/withdrawal`
            const auth = JSON.parse(localStorage.getItem(userKey))
            axios.defaults.headers.common['Authorization'] = `bearer ${auth.token}`
            axios.put(url, this.favorite).then(res => {
                if(!res.data.success){
                    showError(res.data.error)
                    this.show = false
                }else{
                    this.$toasted.success(res.data.success, {
                        theme: "toasted-primary",
                        position: "top-center", 
                        duration : 5000,
                        icon:'check_circle'
                    })
                    this.show = false
                    this.$router.go()
                }
            }).catch(showError)
        }
    },
    mounted() {
        this.getBanks()
    },
}
</script>
<template>
    <div>
        <CallList v-for="users in userOnline" :key="users.id" 
            :name=users.name
            :img="users.avatar | imageURL"
            :msg=users.msg
            :dateLast_msg=users.last
            :hour_msg=users.hour
            :id="users.id"
        />
    </div>
</template>
<script>
import {mapState} from 'vuex'
import CallList from '../containers/CallList'
export default {
    name:'MessageFriends',
    components:{CallList},
    computed:{
        userOnline(){
            return this.$store.state.chatUser
        }
    },
}
</script>
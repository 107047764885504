<template>
    <div class="message-group">
        <div v-if="this.chatGroup.list != null">
            <CallList v-for="(message, index) in chatGroup.list" :key="index" 
                :name=message.title
                :img="message.icone | imageURL"
                :msg=message.lastMessage
                :dateLast_msg="message.lastMessageData | formatDate"
                :hour_msg="message.lastMessageData | formathour"
                :id="message.chatId"
                :group=group
            />
        </div>
        <div class="d-flex justify-content-end m-3" v-if="this.user.type === 'freelancers'">
            <b-button v-b-modal.modal-center style="border-radius:0.4rem !important" bg-mprimary class="px-5 font-weight-bolder d-flex justify-content-end">
                Novo grupo
            </b-button>
        </div>
        <div v-if="chatGroup.list == null" class="notset text-center">
            <NotSet
                frase="Por enquanto você ainda não participa de nenhum grupo."
            />
        </div>
        <!-- MODAL -->
        <b-modal 
            id="modal-center" 
            centered 
            hide-header 
            hide-footer
            no-close-on-backdrop
        >
            <b-row align-h="between">
                <b-col cols="2" @click="closeModal">
                    <svg bg-mprimary style="border-radius:0.4rem !important; transform:rotate(180deg);" xmlns="http://www.w3.org/2000/svg" width="2rem" height="1.5rem" fill="#eee" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>
                </b-col>
                <b-col cols="2">
                    <span @click="closeModal" style="cursor:pointer !important;display:flex !important;justify-content:end !important">
                        <svg width="33" height="33" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="11.5" cy="11.5" r="11.5" fill="#FAFAFA"/>
                        <path d="M8 8L14.5 14.5" stroke="#292841" stroke-width="2"/>
                        <path d="M14.5 8L8 14.5" stroke="#292841" stroke-width="2"/>
                        </svg>
                        
                    </span>
                </b-col>
            </b-row>
            <div v-if="loading === false">
            <h5 class="font-weight-bolder h4">Criar novo grupo</h5>
            <b-form-group
                id="input-group-1"
                label="Nome do grupo"
                label-for="input-1"
                description="*obrigatorio, DICA: selecioner clientes que você prestou o mesmo serviço"
            >
                <b-form-input
                id="input-1"
                v-model="title"
                type="email"
                placeholder="Enter email"
                required
                ></b-form-input>
            </b-form-group>
            <b-row class="justify-content-center">
                <b-col v-for="tags in chatGroup.tags" :key="tags.id" cols="4">
                    <b-badge badge-consultant class="my-2">
                        <img :src="tags.avatar" class="rounded-circle" width="25" height="25" :alt="tags.name" />
                            {{tags.name}}                    
                        <span @click="removeClient(tags)" style="cursor:pointer !important;">
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11.5" fill="#FAFAFA"/>
                            <path d="M8 8L14.5 14.5" stroke="#292841" stroke-width="2"/>
                            <path d="M14.5 8L8 14.5" stroke="#292841" stroke-width="2"/>
                            </svg>
                            
                        </span>
                    </b-badge>
                </b-col>
            </b-row>
            <b-container class="list mt-4 pt-2 border rounded">
                <Clients
                    :clients=chatGroup.clients
                    @getOneClient="client = $event"
                />
            </b-container>
            </div>
            <div v-else class="text-center my-5 d-flex justify-content-center">
                <b-spinner
                    
                    variant="info"
                    
                ></b-spinner>
            </div>
            <b-row align-h="end">
                <b-col cols="6" class="text-left"> 
                    <button class="btn btn-sm px-4 text-light font-weight-bolder" @click.prevent="toSend" bg-mprimary>
                        Criar grupo
                        <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                        </svg>
                    </button>
                </b-col>
            </b-row >
        </b-modal>
    </div>
</template>
<script>
import Clients from '../containers/Message/Clients'
import Title from '../containers/Title'
import NotSet from '../containers/NotSet'
import CallList from '../containers/CallList'
import {mapState} from 'vuex'
export default {
    name:'MessageGroup',
    components:{CallList, NotSet, Title, Clients},
    computed:mapState(['chatGroup', 'user']),
    data(){
        return {
            group:false,
            //pegando usuario clicado
            client:null,
            title:'',
            loading: false
        }
    },
    methods: {
        toSend(){
            let item = {
                author_id: this.user.id,
                title_group: this.title,
                clients: this.chatGroup.member.toString(),
                body:'Grupo - '+this.title
            }
            this.$toasted.success('Grupo criado com sucesso!', {
                theme: "toasted-primary",
                position: "top-center", 
                duration : 5000,
                icon:'check_circle'
            })
            this.$router.go()
            this.$store.dispatch('createGroup', item)
            this.loading = true
        },
        removeClient(tags){
            this.$store.dispatch('removePut', tags)
        },
        closeModal(){
            this.$store.commit('reload')
            this.$bvModal.hide('modal-center')
        }
       
    },
    mounted() {
        this.$store.dispatch('getChatGroup')
    },

}
</script>
<style>
.list{
    flex:1;
    overflow-y:auto;
    height: 10rem;
}
.list::-webkit-scrollbar{
    width: 10px;
    height: 6px;
}
.list::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0, 0.2);
}
</style>
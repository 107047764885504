import Vue from 'vue'
import enviroment from './env'

console.log('ambiente de -> ', process.env.NODE_ENV)


const setNODE = enviroment.environment(process.env.NODE_ENV)

export const env                = setNODE.ENVIRONMENT
export const userKey            = setNODE.USER_KEY
export const profilePurchase    = setNODE.PROFILE_PURCHASE
export const baseApiUrl         = setNODE.BASE_API_URL
export const baseSocket         = setNODE.BASE_SOCKET
export const baseUrl            = setNODE.BASE_URL
export const baseSite           = setNODE.BASE_SITE
export const version            = setNODE.VERSION
console.log('Onde Estou env -> ', version)
// som de notificação
export const notification =  `${baseSocket}/img/notification.mp3`
export const mensage =  `${baseSocket}/img/mensage.mp3`
export function showError(e){
    if(e && e.request && e.request.response ){
        const setJson = JSON.parse(e.request.response)
        setJson.errors ?  Vue.toasted.global.defaultError({msg :  setJson.errors}) : Vue.toasted.global.defaultError({msg :  setJson.error})
       
    }else if(typeof e === 'string'){
        Vue.toasted.global.defaultError({msg: e})
    }else{
        Vue.toasted.global.defaultError()
    }
}
export function showSuccess(e){
    if(e && e.data && e.data.success ){
        Vue.toasted.global.defaultSuccess({msg :  e.data.success})
    }else if(typeof e === 'string'){
        Vue.toasted.global.defaultSuccess({msg: e})
    }else{
        Vue.toasted.global.defaultSuccess()
    }
}
export function showInfo(e){
    if(e && e.data && e.data.success ){
        Vue.toasted.global.defaultInfo({msg :  e.data.success})
    }else if(typeof e === 'string'){
        Vue.toasted.global.defaultInfo({msg: e})
    }else{
        Vue.toasted.global.defaultInfo()
    }
}
export function pagSeguroErro(e){
    const valores = Object.values(e.errors)
    Vue.toasted.global.defaultError({msg:valores[0]})
}
export default {
    showError,
    showSuccess,
    showInfo,
    pagSeguroErro,
    baseApiUrl, 
    userKey,
    profilePurchase,
    baseSocket,
    notification,
    mensage,
    baseUrl,
    baseSite,
    version
}
import '../config/pusher'

import { baseApiUrl, userKey, mensage } from "@/global"
import { mapState } from 'vuex'
//import Echo from 'laravel-echo'
export default {
    computed:mapState(['user']),
    methods: {
        getOnline(){
            const auth = JSON.parse(localStorage.getItem(userKey))
            if(auth != undefined){
                Echo.join('chat')
                .here(users => {
                    let userAdm = []
                    users.forEach(function(e){
                        if(e.type === 'admin'){
                            userAdm.push(e)
                        }
                    })
                    this.$store.commit('setUserAdm', userAdm)
                    console.log(userAdm)
                    this.$store.commit('setChatUser', users)
                    console.log('here')
                    console.log(users)
                }).joining(user =>{
                    this.$store.commit('JoiningUserAdm', user)
                    this.$store.commit('JoiningUser', user)
                    this.$store.commit('JoiningOnline',user)
                    
                }).leaving(user=>{
                    console.log('leaving')
                    console.log(user)
                    this.$store.commit('leavingUserAdm',user)
                    
                    this.$store.commit('leavingUser', user)
                    this.$store.commit('leavingUserOnline', user.name)
                })
                .listen('MessageCreated', e => {
                    this.$store.commit('addMessage', e.message)
                    if(e.message.author_id != this.user.id){
                        if(e.message.receiver_id == this.user.id){
                            
                            this.$bvToast.toast(`${e.message.body}`,{
                                title:`Mensage de: ${e.message.name}`,
                                autoHideDelay: 10000,
                                toaster: 'b-toaster-bottom-right',
                                appendToast:true
                            })
                        }
                    }
                })
                .listen('TicketInitial', e =>{
                    this.$store.commit('addSupportMessage', e.message)
                    console.log(e)
                    if(e.message.author_id != this.user.id){
                        this.$bvToast.toast(`${e.tickets.body}`,{
                            title:`Mensagem de: ${e.tickets.name}`,
                            autoHideDelay: 10000,
                            toaster: 'b-toaster-top-right',
                            appendToast:true,
                            variant:'warning'
                    
                        })
                    }
                })
                .listen('TicketCreated', e =>{
                    this.$store.commit('addSupportMessage', e.message)
                    console.log(e)
                    if(e.message.author_id != this.user.id){
                        if(e.message.receiver_id == this.user.id){
                            this.$store.commit('updateTicket', e.message.tickets_id)
                            this.$bvToast.toast(`${e.message.body}`,{
                                title:`Mensagem de: ${e.message.name}`,
                                autoHideDelay: 10000,
                                toaster: 'b-toaster-top-right',
                                appendToast:true,
                                variant:'warning'
                            })
                        }
                    }
                })
                .listen('TicketFinally', e =>{
                    if(e.message.user_id == this.user.id){
                        this.$fire({
                            title:`Atendimento Finalizado!`,
                            text:`Foi atendido por ${e.message.name}, obrigado!`,
                            type:"success"
                        });
                        this.$store.dispatch('finallyTickets')
                        
                    }
                        console.log(e)
                })
                var id = this.user.id
                // vendo tipo de notification addNotification
                const typesNotifications = {
                    shedule: 'App\\Notifications\\PurchaseScheduled',
                    room: 'App\\Notifications\\CallInitial',
                    approval: 'App\\Notifications\\RequestApproval',
                    block: 'App\\Notifications\\BlockService',
                    reschedule: 'App\\Notifications\\SaleStatus',
                }
                Echo.private(`App.Models.User.${id}`)
                .notification(notification =>{
                    console.log(notification)
                    this.$store.commit('addNotification', notification)
                    if(notification.type == typesNotifications.shedule){
                        this.$bvToast.toast(`Agendamento de ${notification.data.shedules.service.name}`,{
                            title:`Agendado por de: ${notification.data.shedules.client.name}`,
                            autoHideDelay: 10000,
                            toaster: 'b-toaster-bottom-right',
                            appendToast:true
                        })
                        this.$fire({title:`Agendamento de ${notification.data.shedules.service.name}`,text:`Agendado por de: ${notification.data.shedules.client.name}`,type:"success"});
                    }else if(notification.type == typesNotifications.room){
                        this.$store.commit('setCall', notification.data.shedules.room)
                        this.$bvToast.toast(`Sala de reunião para ${notification.data.shedules.service.name}`,{
                            title:`Iniciada por: ${notification.data.shedules.freelancer.name}`,
                            autoHideDelay: 10000,
                            toaster: 'b-toaster-bottom-right',
                            appendToast:true
                        })
                        this.$fire({title:`Sala de reunião para ${notification.data.shedules.service.name}`,text:`Iniciada por: ${notification.data.shedules.freelancer.name}`,type:"success"});
                    }else if(notification.type == typesNotifications.approval){
                        if(notification.data.approval.status == "accept"){
                            this.$fire({
                                title:`Parabéns !`,
                                text:`Já esta disponivel a venda ${notification.data.approval.service}`,
                                type:"success"
                            });
                        }else{
                            this.$fire({
                                title:`Infelizmente não foi aprovado.`,
                                text:`Ainda não poderá presta o ${notification.data.approval.service}, tente novamente, com outro arquivo de comprovação.`,
                                type:"warning"
                            });
                        }
                    }else if(notification.type == typesNotifications.block){
                        this.$fire({
                            title:`Prestação de serviço bloqueado.`,
                            text:`Voce não terá novos agendamentos ${notification.data.reproval.name}, converse com suporte para sabe o motivo.`,
                            type:"warning"
                        });
                    }
                    else if(notification.type ==  typesNotifications.reschedule){
                        this.$fire({
                            title:`Reagende a consultoria.`,
                            text:`Converse com o consultor ${notification.data.order.freelancer} para reagendar.`,
                            type:"warning"
                        });
                    }
                })
            }
        }
    },
    created() {
        this.getOnline()
    }
}
<template>
    <div class="notifications bg-light py-3 px-4 border-bottom">
        <div class="d-flex">
            <div>
                <img class="rounded-circle" width="50" height="50" :src=avatar alt="" srcset="">
            </div>
            <div class="text-monospace notifica-size">
                <p v-if="this.type == 'App\\Notifications\\PurchaseScheduled' ">{{name}} iniciou compra de {{serviceName}}</p>
                <p v-if="type == 'App\\Notifications\\CallInitial' ">{{name}} iniciou a reunião {{serviceName}}</p>
            </div>
        </div>
            <p class="text-right">{{time}}</p>
        <div class="d-flex justify-content-around">
            <router-link :to="{path: `/sales/salesOne/${id}`, params: {id: id}}" class="nav-mlink">
            ver
            </router-link>
            <a href="#" @click.prevent="markAsRead(notification_id)" class="nav-mlink">
                excluir
            </a>
        </div>
    </div>
</template>
<script>
import Audio from '../../assets/notification.mp3'
export default {
    name:'Notifications',
    props:['avatar','name', 'serviceName','time', 'id', 'notification_id', 'type', ],
    methods: {
        markAsRead(idNotification){
            //this.executeAudio(Audio)
            this.$store.dispatch('maskAsRead', {id: idNotification})
        },
        executeAudio(som){
            console.log('oi')
           
            if(som){
                let audio = new Audio(som)
                audio.play()
                 console.log('dentro')
            }
        }
    },
}
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'

import Auth from '@/components/auth/Auth'

import Home from '@/views/Home'
import Overview from '@/views/Overview'
import Sales from '@/views/Sales'
import Message from '@/views/Message'
import Purchases from '@/views/Purchases'
import Profile from '@/views/Profile'
import Config from '@/views/Config'
import Perfil from '@/views/Perfil'
import Help from '@/views/Help'
import Jitsi from '@/views/Jitsi'
import NotPad from '@/views/NotPad'

/**
 * Template de Perfil
 */
import PerfilHome from '@/components/template/PerfilHome'
import PerfilAvaliation from '@/components/template/PerfilAvaliation'
import PerfilCertificate from '@/components/template/PerfilCertificate'
/**
 * Template de Config
 */
import Account from '@/components/template/Account'
import Bank from '@/components/template/Bank'

/**
 * Template de Profile
 */
import ConfProfile from '@/components/template/Profile/ConfProfile'
import ConfigAvailability from '@/components/template/Profile/ConfigAvailability'
import ConfStore from '@/components/template/Profile/ConfStore'
import Certificates from '@/components/template/Profile/Certificates'

/**
 * Template de Message
 */
import MessageCall from '@/components/template/MessageCall'
import MessageGroup from '@/components/template/MessageGroup'
import MessageInvite from '@/components/template/MessageInvite'
import MessageWindow from '@/components/template/MessageWindow'
import MessageFriends from '@/components/template/MessageFriends'
import ChatGroup from '@/components/template/ChatGroup'

/**
 * Template de Sales
 */
import SalesOpen from '@/components/template/SalesOpen'
import SalesHistoric from '@/components/template/SalesHistoric'
import SaleOne from '@/components/template/SaleOne'
import Teste from '@/components/template/teste'

/**
 * Template de Compras
 */
import PaymentOpen from '@/components/template/PaymentOpen'
import PaymentCompleted from '@/components/template/PaymentCompleted'
import PaymentOne from '@/components/template/PaymentOne'

/**
 * Templata do NotePad
 */
import ListNotPad from '@/components/template/notPad/ListNotPad'
import CreatedNotPad from '@/components/template/notPad/CreatedNotPad'
import EditNotPad from '@/components/template/notPad/EditNotPad'

Vue.use(VueRouter)

const routes = [
    {
        name: 'Home',
        path: '/',
        component: Home,
        meta:{
            titulo: 'vendo'
        },
        titulo:'vendo2'
        
    },
    {
        name: 'auth',
        path: '/auth',
        component: Auth,
        props:true,
    },
    {
        name:'overview',
        path:'/overview',
        component: Overview,
        meta:{
            title:'Consultores'
        }
    },
    {
        path:'/sales',
        component: Sales,
        props:true,
        children:[
            {path: '', component: SalesOpen, name:'Vendas', props:true},
            {path: 'historic', component: SalesHistoric, name:'historico de vendas', props:true},
            {path: 'salesOne/:id', name:'Venda', component:SaleOne, props: true},
            //{path: 'teste', Teste, props: true},
        ],
        meta:{
            title:'Consultores'
        }
    },
    {
        path: '/purchases',
        component:Purchases,
        props:true,
        children:[
            {path: '', component: PaymentOpen, name:'Compras', props:true},
            {path: 'completed', component: PaymentCompleted, name:'Pagamentos concluidos', props:true},
            {path: 'open', component: SalesOpen, name:'Compra em aberto', props:true},
            {path: 'historic', component: SalesHistoric, name:'Historico de Compra', props:true},
            {path: 'purchase/:id', name:'Compra', component:SaleOne, props: true},
            //{path: 'PaymentOne/:reference', name:'Compra', component:PaymentOne, props: true},
            //{path: 'teste', Teste, props: true},
        ],
        meta:{
            title:'Consultores'
        }
    },
    {
        path:'/message',
        component:Message,
        props:true,
        children:[
            {path: '', component: MessageCall, name:'CONVERSAS',props:true},
            {path: 'groups', component: MessageGroup, name:'GROUPOS',props:true },
            {path: 'invite', component: MessageInvite, name:'CONVITE',props:true },
            {path: 'friends', component: MessageFriends, name:'AMIGOS',props:true},
            {path: 'chat/:id',component: MessageWindow, name:'chat',props:true },
            {path: 'group/:id', component: ChatGroup, name:'group',props:true}
            
        ]
    },
    {
        path: '/perfil',
        name:'perfil',
        component: Perfil,
        props:true,
        children:[
            {path: ':id/about', component: PerfilHome, name:'PERFIL E SERVIÇOS' ,props:true},
            {path: ':id/avaliations', component: PerfilAvaliation, name:'AVALIAÇÕES', props:true },
            {path: ':id/certificates', component: PerfilCertificate,  name:'CERTIFICADOS', props:true }
        ]
    },
    {
        path: '/Profile',
        component: Profile,
        children:[
            {path: '', component: ConfProfile, name:'Perfil'},
            {path: 'availability', component: ConfigAvailability, name:'disponibilidade'},
            {path: 'store', component:ConfStore, name:'Histórico'},
            {path: 'certificates', component:Certificates, name:'Certificados'}
        ]
    },
    {
        path: '/Config',
        component: Config,
        children:[
            {path: '', component: Account},
            {path: 'bank', component: Bank}
        ]
    },
    {
        name: 'help',
        path: '/Help',
        component: Help
    },
    {
        name:'Jitsi',
        path:'/Jitsi',
        component:Jitsi
    },
    {
        path: '/NotPad',
        component:NotPad,
        props:true,
        children:[
            {path: '', component: ListNotPad, name:'Bloco de Notas', props:true},
            {path: 'created', component: CreatedNotPad, name:'Criar Bloco de Notas', props:true},
            {path: 'edit/:id', component: EditNotPad, name:'Editar bloco', props:true },
        ]
    }
]
const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
<template>
    <div>
        <b-container class="list" ref="list">
            <div id="loading" v-if="show">
                <b-spinner  variant="primary" label="Spinning" ></b-spinner>
            </div>
            <div v-else v-for="(chats, index) in chat" :key="index">
                <CallItem
                    :author="chats.author_id"
                    :body="chats.body"
                    :time="chats.created_at | formathour"
                    :img=chats.image
                />
            </div>
        </b-container>
        <div class="chatWindow">
            <div class="chatWindow--footer">
                <div class="chatWindow--inputarea" style="flex:1">
                    <textarea 
                        @keydown="keySend"
                        class="chatWindow--input"
                        type="text"
                        placeholder="Digite uma mensagem"
                        v-model="send.body"
                    ></textarea>
                </div>
                <div class="chatWindow--pos mx-3">
                    <button class="btn btn-sm px-4 text-light font-weight-bolder" @click.prevent="toSend" bg-mprimary>
                        <b v-if="!loading">
                        ENVIAR
                        <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="currentColor" class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                        </svg>
                        </b>
                        <b-spinner v-else variant="success" type="grow" label="Spinning">
                        </b-spinner>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
import {baseApiUrl,showError, userKey} from '@/global'
import axios from 'axios'
import CallItem from '../containers/CallItem'
import ChatGroup from '../../mixins/ChatGroup'
export default {
    name:'MessageWindow',
    components:{CallItem},
    computed:mapState(['chat']),
    data(){
        return {
            messages:{},
            send:{},
            loading:false,
            show: false
        }
    },
    mixins:[ChatGroup]
}
</script>
<style>
/*.card-body{
    background: beige !important;
    flex: 1;
    padding: 0 !important;
}*/
#loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}
.list{
    flex:1;
    overflow:scroll;
    height: 25rem;
}
.list::-webkit-scrollbar{
    width: 10px;
    height: 6px;
}
.list::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0, 0.2);
}
.chatWindow{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.chatWindow--body{
    flex: 1;
    overflow-y: auto;
    height: 15rem;
    padding: 20px 30px;
}
.chatWindow--footer{
    height: 62px;
    display: flex;
    align-items: center;
    background: #000;
    padding: 2rem;
}
.chatWindow--pre{
    display: flex;
    margin: 0 15px;
}
.chatWindow--input{
    width: 100%;
    height: 40px;
    border: 0;
    outline: 0;
    background-color: #fff;
    border-radius: 0.5rem;
    font-size: 15px;
    color:#4a4a4a;
    padding-left: 15px;
}
.chatWindow--pos{
    display: flex;
    margin: 0 15px;
}
</style>